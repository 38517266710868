import { DatePicker } from 'antd';
import React, { memo } from 'react';

import CustomRadioGroup from '@marketreach/components/radio';
import CustomTabs from '@marketreach/components/tabs';

import SalesTrend from './sales';
import './styles.scss';
import VisitsTrend from './visits';

const { RangePicker } = DatePicker;

const radioButtons = [
  { value: 'all_day', label: 'All day' },
  { value: 'all_week', label: 'All Week' },
  { value: 'all_month', label: 'All Month' },
  { value: 'all_year', label: 'All Year' },
];

const Trends = () => {
  const tabs = ['Sales', 'Visits'];
  const tabContents = [<SalesTrend />, <VisitsTrend />];
  const extraContent = (
    <>
      <CustomRadioGroup
        className="date-range-select-buttons"
        items={radioButtons}
      />
      <RangePicker
        dateRender={(current) => {
          const style = {};
          if (current.date() === 1) {
            style.border = '1px solid #1890ff';
            style.borderRadius = '50%';
          }
          return (
            <div className="ant-picker-cell-inner" style={style}>
              {current.date()}
            </div>
          );
        }}
      />
    </>
  );

  return (
    <div className="trends-content">
      <CustomTabs
        tabs={tabs}
        tabContents={tabContents}
        extraContent={extraContent}
      />
    </div>
  );
};

export default memo(Trends);
