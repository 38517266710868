import { Form, Tag } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';

import { buildRule } from '../../../utils/validation/index';

import renderItem from './items/index';

import './styles.scss';

export const PROPERTY_TYPE_TEXT = 'Text';
export const PROPERTY_TYPE_INTEGER = 'Integer';
export const PROPERTY_TYPE_DECIMAL = 'Decimal';
export const PROPERTY_TYPE_TOGGLE = 'Toggle';
export const PROPERTY_TYPE_LIST = 'List';
export const PROPERTY_TYPE_COLOR = 'Color';
export const PROPERTY_TYPE_DATE = 'Date and Time';
export const PROPERTY_TYPE_UPLOAD = 'File Upload';
export const PROPERTY_TYPE_CODE_EDIT = 'Code Editor';
export const PROPERTY_TYPE_RICH_TEXT = 'RichText';
export const PROPERTY_TYPE_RELATION = 'Relation';
export const PROPERTY_TYPE_RADIOBUTTON = 'Radio button';

const PropertyItem = (props) => {
  const {
    key,
    showDescription,
    showLabel,
    readOnly,
    type,
    entity,
    data,
    usedValues,
    defaultValue,
    onChange,
    disabled,
    handleChangeValue,
  } = props;

  const { selected: client } = useClientsState();

  let existingValue;
  if (entity?.properties && entity?.properties[data?.section?.key]) {
    existingValue = entity?.properties[data?.section?.key][data?.settings?.key];
  }

  // backward compatibility
  if (
    !existingValue &&
    entity?.properties &&
    entity?.properties[data?.settings?.key]
  ) {
    existingValue = entity?.properties[data?.settings?.key];
  }

  const getPredefinedVal = () => {
    if (existingValue !== null && typeof existingValue !== 'undefined') {
      return existingValue;
    }

    return defaultValue || data?.settings?.defaultValue;
  };

  const preDefinedVal = getPredefinedVal();

  const label = () => (
    <div className="labels">
      <h4>{data?.settings?.label}</h4>
      <h5>{data?.settings?.description}</h5>
    </div>
  );

  const rules = () => {
    if (data?.validation && !readOnly && !data.settings.template) {
      return Object.keys(data.validation)
        .map((validationKey) => {
          return buildRule(
            validationKey,
            data.validation[validationKey],
            usedValues[data?.settings?.key] ?? []
          );
        })
        .flat()
        .filter((item) => item !== undefined);
    }
    return [];
  };

  const getInput = () => {
    return renderItem(type, {
      key,
      settings: data?.settings ?? {},
      validation: data?.validation || {},
      rules,
      preDefinedVal,
      hide: data?.advanced?.hideField,
      readOnly,
      defaultValue,
      client,
      onChange,
      disabled,
      entitiesList: data?.entitiesList || {},
      handleChangeValue,
    });
  };

  const validationChips = (validations) => (
    <>
      {validations.length > 0
        ? validations.map((c) => (
            <Tag color="#198be2" key={c.replace(' ', '_')}>
              {c}
            </Tag>
          ))
        : []}
    </>
  );

  const validation = data?.validation
    ? Object.keys(data?.validation).filter((key) => data?.validation[key])
    : [];

  const hiddenField = data?.advanced?.hideField ?? false;

  if (hiddenField) return getInput();

  const getFieldStatus = (form, name) => {
    if (form && name) {
      if (form.getFieldError(name)?.length > 0) return 'error';
      if (form.isFieldTouched(name) && form.getFieldError(name)?.length === 0)
        return 'success';
    }

    return '';
  };

  return (
    <Form.Item noStyle shouldUpdate>
      {(form) => {
        const wrapperClass = 'properties-fields-item';

        return (
          <div className={wrapperClass}>
            <div className="header">
              {showLabel && label()}
              {showDescription && (
                <div className="tags">
                  <div>{validationChips(validation)}</div>
                </div>
              )}
            </div>
            <div
              className={`input ${getFieldStatus(form, data?.settings?.key)} ${
                readOnly ? 'readonly' : ''
              }`}
            >
              {getInput()}
            </div>
          </div>
        );
      }}
    </Form.Item>
  );
};

PropertyItem.propTypes = {
  key: PropTypes.string,
  readOnly: PropTypes.bool,
  showDescription: PropTypes.bool,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  entity: PropTypes.object,
  data: PropTypes.object,
  usedValues: PropTypes.object,
  defaultValue: PropTypes.any,
  handleChangeValue: PropTypes.func,
  onChange: PropTypes.func,
};

PropertyItem.defaultProps = {
  key: null,
  readOnly: false,
  showDescription: true,
  showLabel: true,
  disabled: false,
  type: null,
  entity: null,
  data: null,
  usedValues: null,
  defaultValue: null,
  handleChangeValue: () => {},
  onChange: () => {},
};

export default memo(PropertyItem);
