import { SearchOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { arrayMove } from 'react-sortable-hoc';

import { DraggableBodyRow } from '@marketreach/components/protable/components/DraggableBodyRow';
import FilterDropDown from '@marketreach/components/protable/components/FilterDropDown';

import { buildDataSource, DragColumns, SortableContainer } from './helpers';

import './styles.scss';

/**
 * Wrapper for Antd pro-table
 * @param props
 * @param props.className - table class name
 * @param props.title - Label before table
 * @param props.rowKey - field that would be used for get row key value
 * @param props.columns - collection of object with table columns description
 * @param props.data - array with data to display
 * @param props.toolbar - ProTable toolbar configuration
 * @param props.location - loading flag
 * @param props.selectable - flag that rows can be selected
 * @param props.expandable - flag that row can be expanded with table
 * @param props.draggable - flag that rows can be dragged
 * @param props.searchable - enable search in table
 * @param props.pagination - enable pagination
 * @param props.handleSortedData - sorting data handler. Called after sorting
 * @param props.handleExpandRow - expand data handler
 * @param props.handleRowClick - handler for row click
 * @param props.handleSearch - data search handler
 * @param props.handleSortChange - handler sort request
 *
 * @return {JSX.Element}
 * @constructor
 */
const CustomProTable = (props) => {
  const {
    className,
    title,
    rowKey,
    columns,
    data,
    toolbar,
    loading,
    selectable,
    expandable,
    draggable,
    searchable,
    pagination,
    handleSortedData,
    handleExpandRow,
    handleRowClick,
    handleSearch,
    handleSortChange,
  } = props;

  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    if (draggable) {
      setSortedData(data);
    }
  }, [data, draggable]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter(
            (key, index) => index % 2 === 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter(
            (key, index) => index % 2 !== 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(sortedData),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setSortedData(newData);
      handleSortedData(oldIndex, newIndex);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const onExpand = (expanded, record) => {
    handleExpandRow(expanded, record);
    setExpandedRowKeys(expanded ? [record?.key] : []);
  };

  const [multiColumnSearch, setMultiColumnSearch] = useState({});
  const onColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const selectedColumn = columns.find((col) => col.dataIndex === dataIndex);
    const propertyType = selectedColumn?.propertyType;

    const newFilters = {
      ...multiColumnSearch,
      [dataIndex]:
        propertyType === 'Toggle'
          ? selectedKeys[0] !== 'false'
          : selectedKeys[0],
    };
    setMultiColumnSearch(newFilters);
    handleSearch(newFilters);
  };

  const onColumnSearchReset = (clearFilters, dataIndex) => {
    clearFilters();
    const newFilters = { ...multiColumnSearch };
    delete newFilters[dataIndex];

    setMultiColumnSearch(newFilters);
    handleSearch(newFilters);
  };

  const searchInput = useRef(null);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      // eslint-disable-next-line react/prop-types
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <FilterDropDown
        searchInput={searchInput}
        dataIndex={dataIndex}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        onColumnSearch={onColumnSearch}
        onColumnSearchReset={onColumnSearchReset}
        confirm={confirm}
        clearFilters={clearFilters}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const tableColumns = draggable ? DragColumns(columns) : columns;
  const searchableColumns = tableColumns.map((column) => ({
    ...column,
    ...getColumnSearchProps(column.dataIndex),
  }));

  return (
    <div className="marketReachTable">
      <div className="toolbar">
        <div className="left">
          <h3>{title}</h3>
        </div>
        <div className="right">{toolbar ? toolbar() : ''}</div>
      </div>

      <Table
        className={classnames(
          'custom-pro-table',
          selectedRowKeys.length > 0 ? '' : 'custom-pro-table-no-selected',
          className
        )}
        headerTitle={title}
        options={{
          density: true,
          reload: false,
        }}
        columns={searchable ? searchableColumns : tableColumns}
        dataSource={buildDataSource(draggable, sortedData, data)}
        loading={loading}
        rowKey={(row) => (row[rowKey] ? row[rowKey] : rowKey)}
        beforeSearchSubmit={handleSearch}
        scroll={{ x: 'max-content' }}
        rowSelection={selectable ? rowSelection : false}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={pagination}
        expandedRowKeys={expandedRowKeys}
        expandable={expandable}
        onExpand={onExpand}
        components={
          draggable && {
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }
        }
        search={false}
        onChange={(pagination, filters, sorter, extra) => {
          handleSortChange(
            sorter.field,
            sorter.order === 'ascend' ? 'ASC' : 'DESC'
          );
        }}
      />
    </div>
  );
};

CustomProTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  rowKey: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  selectable: PropTypes.bool,
  draggable: PropTypes.bool,
  searchable: PropTypes.bool,
  toolbar: PropTypes.any,
  expandable: PropTypes.object,
  pagination: PropTypes.any,
  handleSortedData: PropTypes.func,
  handleExpandRow: PropTypes.func,
  handleRowClick: PropTypes.func,
  handleSearch: PropTypes.func,
  handleSortChange: PropTypes.func,
};

CustomProTable.defaultProps = {
  className: '',
  title: '',
  rowKey: 'key',
  columns: [],
  data: [],
  loading: false,
  selectable: true,
  draggable: false,
  searchable: false,
  toolbar: () => [],
  expandable: null,
  pagination: false,
  handleSortedData: () => {},
  handleExpandRow: () => {},
  handleRowClick: () => {},
  handleSearch: () => {},
  handleSortChange: () => {},
};

export default CustomProTable;
