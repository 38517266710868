import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import CustomProTable from '@marketreach/components/protable';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { RULES } from '@marketreach/services/apollo/rules';
import {
  basisCell,
  criteriaChips,
  prepareRulesColumns,
} from '@marketreach/utils/rules';

/**
 * Table do display list of rules
 *
 * @param props.rulesIds - if scpecified - display only those rules
 * @param props.setLoading - function to show loading process
 * @param props.onAddRuleClick - add rule button handler
 * @param props.removeRule - remove rule handler
 * @param props.allowEdit - allow rule edit flag
 * @return {JSX.Element}
 * @constructor
 */
const RulesTable = (props) => {
  const { rulesIds, setLoading, onAddRuleClick, removeRule, allowEdit } = props;
  const { selected: client } = useClientsState();

  // States
  const [rules, setRules] = useState([]);

  //Queries
  const { data: rulesData, loading: rulesLoading } = useQuery(RULES, {
    variables: {
      clientCode: client.apiId,
      ruleIds: rulesIds,
    },
  });

  //Effects
  useEffect(() => {
    setLoading(rulesLoading);
  }, [setLoading, rulesLoading]);

  useEffect(() => {
    if (rulesData && rulesData?.rules?.data) {
      setRules(rulesData?.rules?.data);
    }
  }, [rulesData, setRules]);

  // Consts
  const toolbar = () => [
    <Button type="primary" onClick={onAddRuleClick}>
      <PlusOutlined /> Add New
    </Button>,
  ];

  const handleRuleDelete = (row) => {
    removeRule(row._id);
  };

  const handleRuleEdit = () => null;

  return (
    <CustomProTable
      className="rules-content-main-table"
      data={rules}
      rowKey="_id"
      columns={prepareRulesColumns(
        basisCell,
        criteriaChips,
        handleRuleDelete,
        handleRuleEdit,
        allowEdit
      )}
      toolbar={toolbar}
      pagination={{ defaultPageSize: 20 }}
      selectable={false}
    />
  );
};

RulesTable.propTypes = {
  rulesIds: PropTypes.arrayOf(PropTypes.string),
  setLoading: PropTypes.func,
  onAddRuleClick: PropTypes.func,
  removeRule: PropTypes.func,
  allowEdit: PropTypes.bool,
};

RulesTable.defaultProps = {
  rulesIds: null,
  setLoading: () => null,
  onAddRuleClick: () => null,
  removeRule: () => null,
  allowEdit: true,
};

export default RulesTable;
