import { useMutation, useQuery } from '@apollo/client';
import { Spin, Empty, Modal, Tabs } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';

import TableView from '@marketreach/pages/entities/tabs/TableView';
import TreeView from '@marketreach/pages/entities/tabs/TreeView';
import PropertiesManager from '@marketreach/pages/taxonomy/properties/PropertiesManager';
import RulesManager from '@marketreach/pages/taxonomy/rules/RulesManager';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';
import {
  ENTITIES_LIST,
  ENTITY_DATA,
  ENTITY_DATA_QUERY,
  REMOVE_ENTITY_DATA,
  SAVE_ENTITY_DATA,
} from '@marketreach/services/apollo/entity';
import {
  ENTITY_TYPE_ATTRIBUTE,
  ENTITY_TYPE_CATEGORY,
  ENTITY_TYPE_PRODUCT,
  normalizeEntity,
  sortByOrder,
} from '@marketreach/utils/common';

import './styles.scss';
import { getSectionColumns } from '@marketreach/utils/products';

const { TabPane } = Tabs;

export const ENTITY_PAGE_SIMPLE_VIEW = 'simple';
export const ENTITY_PAGE_TREE_VIEW = 'tree';

const EntitiesPage = () => {
  const { type } = useParams();

  // States and queries start
  const { selected: client } = useClientsState();

  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );

  const sections = ('sections' in client ? client.sections[type] || [] : [])
    .slice()
    .sort(sortByOrder);

  // List of entities
  const [entitiesList, setEntitiesList] = useState([]);

  const entityInfo = client?.entities.find((item) => item.slug === type) || {
    label: type,
  };

  // Current mode SIMPLE or TREE
  const [view, setView] = useState(ENTITY_PAGE_SIMPLE_VIEW);

  const [showManageProperties, setShowManageProperties] = useState(false);
  const [showManageRules, setShowManageRules] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [entityToEdit, setEntityToEdit] = useState({});

  // Pagination
  const [pageNumber] = useState(1);
  const [, setTotalCount] = useState(0);
  const [pageSize] = useState(500);

  // filtering and sorting
  const [searchParams, setSearchParams] = useState({});
  const [sortParams, setSortParams] = useState({});

  // Entity to editing in tree mode
  const [selectedEntityId, setSelectedEntityId] = useState();
  // Entity to edit in simple mode
  const [selectedEntity, setSelectedEntity] = useState();

  const [entityDataList, setEntityDataList] = useState([]);
  const [showInvite, setShowInvite] = useState(false);

  // Queries

  // Load entities
  const { loading, data } = useQuery(ENTITY_DATA, {
    variables: {
      clientCode: client?.apiId,
      type,
      page: pageNumber,
      pageSize,
      searchParams,
      sortParams,
    },
  });

  // Load list of possible entities
  const { data: entitiesListData } = useQuery(ENTITIES_LIST, {
    variables: {
      clientCode: client?.apiId,
    },
  });

  const [saveEntityData, { loading: saveEntityLoading }] =
    useMutation(SAVE_ENTITY_DATA);
  const [removeEntityData, { loading: removeEntityLoading }] =
    useMutation(REMOVE_ENTITY_DATA);

  // Effects

  useEffect(() => {
    if (!showManageProperties) {
      const baseColumns = getSectionColumns(sections);
      if (baseColumns.length === 0) {
        setShowInvite(true);
      } else {
        setShowInvite(false);
      }
    }
  }, [sections, setShowInvite, showManageProperties]);

  useEffect(() => {
    if (
      data?.entityData?.data &&
      data?.entityData?.data.length > 0 &&
      data?.entityData?.data.filter((item) => Object.keys(item).length > 0)
        .length > 0
    ) {
      setEntityDataList(
        normalizeEntity(
          data?.entityData?.data,
          view === ENTITY_PAGE_SIMPLE_VIEW
        )
      );
    }
  }, [data, showEditor]);

  useEffect(() => {
    if (
      entitiesListData?.listOfEntities?.data &&
      entitiesListData?.listOfEntities?.data !== entitiesList
    ) {
      setEntitiesList(entitiesListData.listOfEntities.data);
    }
  }, [entitiesListData]);

  useEffect(() => {
    setTotalCount(data?.entityData?.totalCount || 0);
  }, [data, setTotalCount]);

  useEffect(() => {
    setSelectedEntity(
      entityDataList?.find((item) => item._id === selectedEntityId)
    );
  }, [selectedEntityId]);

  useEffect(() => {
    if (showEditor) {
      setEntityToEdit(
        entityDataList.find((item) => item._id === entityToEdit._id)
      );
    }
  }, [entityDataList]);

  /**
   * Show drawer for adding new item in simple mode
   *
   * @param value
   */
  const addItem = (value) => {
    setSelectedEntityId(null);
    setEntityToEdit({});
    setShowEditor(value);
  };

  /**
   * Save entity
   * - entityToEdit - filled if you edit entity in simple mode
   * - selectedEntity - filled if you edit entity in tree mode
   * Otherwise - we should create new item
   *
   * After creation - cleanup entityToEditState (selectedEntity we don't
   * cleanup to do not loose selected tree item)
   *
   * @param values
   */
  const handleSaveClick = (values: any) => {
    saveEntityData({
      variables: {
        id: entityToEdit?._id || selectedEntity?._id,
        clientCode: client?.apiId,
        type,
        data: values,
      },
      refetchQueries: [ENTITY_DATA_QUERY],
    })
      .then(() => entityToEdit && setEntityToEdit(null))
      .catch((e) => {
        Modal.error({
          title: 'Saving error',
          content: 'Error on saving entity. Please try againe later',
        });
      });

    setShowEditor(false);
  };

  const handleShow = (value) => {
    setShowManageProperties(value);
  };

  /**
   * Get field that used as API ID for current entity
   *
   * @return {*}
   */
  const getApiIdField = () => {
    if (client.sections[type]) {
      for (const section of client.sections[type]) {
        if (section?.properties) {
          for (const property of section.properties) {
            if (property?.settings?.fieldOptions?.isApiId === true) {
              return property?.settings?.key;
            }
          }
        }
      }
    }

    return undefined;
  };

  /**
   * Show Manage properties
   *
   * @param value
   */
  const handleShowDrawer = (value) => {
    if (value) {
      setPropertyManager({
        ...propertyManager,
        type,
      });
    }
    setShowManageProperties(value);
    setShowInvite(false);
  };

  /**
   * Show rules manager for entity
   * @param value
   */
  const handleShowRules = (value) => {
    setShowManageRules(value);
  };

  /**
   * Check if tab enabled to current entity type
   *
   * @param {string} tabName
   * @return {boolean|boolean}
   */
  const allowTab = (tabName) => {
    const preferredTypes = [
      ENTITY_TYPE_ATTRIBUTE,
      ENTITY_TYPE_CATEGORY,
      ENTITY_TYPE_PRODUCT,
    ];
    if (preferredTypes.includes(type)) {
      return true;
    }
    switch (tabName) {
      case ENTITY_PAGE_SIMPLE_VIEW:
        return entityInfo?.allowTableView || false;
      case ENTITY_PAGE_TREE_VIEW:
        return entityInfo?.allowTreeView || false;
      default:
        return false;
    }
  };

  return (
    <div className="ant-pro-grid-content entity-content">
      <Spin spinning={saveEntityLoading || removeEntityLoading || loading}>
        <h1>
          {entityInfo?.label_plural || entityInfo?.label || 'Entity editor'}
        </h1>

        <Tabs type="card" onChage={(e) => setView(e)}>
          {allowTab(ENTITY_PAGE_SIMPLE_VIEW) && (
            <TabPane tab="Table view" key={ENTITY_PAGE_SIMPLE_VIEW}>
              <TableView
                addItem={addItem}
                handleSaveClick={handleSaveClick}
                removeEntityData={removeEntityData}
                showEditor={showEditor}
                entityInfo={entityInfo}
                setEntityToEdit={setEntityToEdit}
                setShowEditor={setShowEditor}
                entityToEdit={entityToEdit}
                loading={loading}
                entitiesList={entitiesList}
                entityDataList={entityDataList}
                showInvite={showInvite}
                handleShowDrawer={handleShowDrawer}
                handleShowRules={handleShowRules}
                setSearchParams={setSearchParams}
                setSortParams={setSortParams}
              />
            </TabPane>
          )}
          {allowTab(ENTITY_PAGE_TREE_VIEW) && (
            <TabPane tab="Tree view" key={ENTITY_PAGE_TREE_VIEW}>
              <TreeView
                addItem={addItem}
                handleSaveClick={handleSaveClick}
                removeEntityData={removeEntityData}
                selectedEntity={selectedEntity}
                entityInfo={entityInfo}
                getApiIdField={getApiIdField}
                saveEntityLoading={saveEntityLoading}
                setSelectedEntityId={setSelectedEntityId}
                selectedEntityId={selectedEntityId}
                entityToEdit={entityToEdit}
                entityDataList={entityDataList}
                showInvite={showInvite}
                handleShowDrawer={handleShowDrawer}
                extraLoading={saveEntityLoading || removeEntityLoading}
              />
            </TabPane>
          )}
        </Tabs>

        {showManageProperties && (
          <PropertiesManager
            visible={showManageProperties}
            handleShow={handleShow}
            entityType={type}
            apiIdField={getApiIdField()}
          />
        )}
        {showManageRules && (
          <RulesManager
            entityType={type}
            entity={entityToEdit}
            visible={showManageRules}
            handleShow={setShowManageRules}
          />
        )}
        {view === ENTITY_PAGE_TREE_VIEW && !getApiIdField() && (
          <Empty>To work with tree view, please configure api id field</Empty>
        )}
      </Spin>
    </div>
  );
};

EntitiesPage.propTypes = {
  client: PropTypes.object,
  type: PropTypes.string,
};

EntitiesPage.defaultProps = {
  client: null,
  type: null,
};

export default memo(EntitiesPage);
