import { Drawer } from 'antd';
import * as _ from 'lodash';
import React, { useContext, useEffect } from 'react';

import TaxonomyRules from '@marketreach/pages/taxonomy/rules/index';
import { RulesManagerContext } from '@marketreach/providers/RulesManagerProvider';

const RulesManager = ({ entityType, entity, handleShow, visible }) => {
  const { rulesManager, setRulesManager } = useContext(RulesManagerContext);

  useEffect(() => {
    if (
      visible &&
      entity &&
      (rulesManager.type !== entityType ||
        !_.isEqual(rulesManager.entity, entity))
    ) {
      setRulesManager({
        ...rulesManager,
        type: entityType,
        entityId: entity._id,
        entity,
      });
    }
  }, [rulesManager, setRulesManager, entityType, entity, visible]);

  return (
    <Drawer
      visible={visible}
      width={1100}
      title={`Manage rules for ${entityType} "${entity?.name || entity._id}"`}
      onClose={() => {
        handleShow(false);
        setRulesManager({
          type: null,
          entityId: null,
          entity: null,
        });
      }}
    >
      <TaxonomyRules />
    </Drawer>
  );
};

export default RulesManager;
