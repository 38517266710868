import { useMutation, useQuery } from '@apollo/client';
import { Input, Tree, message, Spin } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import {
  ADD_ASSOCIATITON_CATEGORY,
  ATTRIBUTE_QUERY_NAME,
  REMOVE_ASSOCIATITON_CATEGORY,
} from '@marketreach/services/apollo/attributes';
import {
  CATEGORIES,
  CATEGORY_QUERY_NAME,
} from '@marketreach/services/apollo/categories';
import {
  GET_SETTING,
  GET_SETTING_QUERY,
  SET_SETTING,
} from '@marketreach/services/apollo/user';
import { getEntityTreeData } from '@marketreach/utils/categories';

import './styles.scss';

const { Search } = Input;

const TaxonomyCategoriesTree = (props) => {
  const { preview, onPreviewCategoryChange } = props;

  const { selected: client } = useClientsState();
  const { context, setContext } = useContext(TaxonomyContext);
  const { selectedAttribute, categories, attributes } = context;

  const [loading, setLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);

  // Build key for get/set expanded keys of tree
  const expandedKeysKey = `taxonomy_categories_tree_expanded_keys`;

  const {
    loading: loadingCategories,
    data: categoryRes,
    refetch: refetchCategories,
  } = useQuery(CATEGORIES, {
    variables: { clientCode: client?.apiId },
  });

  // Load expanded keys data
  const { data: expandedKeysData, loading: expandedKeysLoading } = useQuery(
    GET_SETTING,
    {
      variables: {
        key: expandedKeysKey,
        clientCode: client?.apiId,
      },
    }
  );

  useEffect(() => {
    if (client) {
      refetchCategories();
    }
  }, [client, refetchCategories]);

  useEffect(() => {
    const categoryData = categoryRes ? categoryRes?.categories?.data : [];

    const categoriesList = categoryData || [];

    if (!_.isEqual(context.categories, categoriesList)) {
      setContext({ ...context, categories: categoriesList });
    }
  }, [categoryRes, context, setContext]);

  const checkedCategories = selectedAttribute?.associations?.categories || [];
  const [previewCheckedCategories, setPreviewCheckedCategories] = useState({
    checked: [],
    halfChecked: [],
  });

  const [addAssociationCategory, { loading: addAssociationCategoryLoading }] =
    useMutation(ADD_ASSOCIATITON_CATEGORY);
  const [
    removeAssociationCategory,
    { loading: removeAssociationCategoryLoading },
  ] = useMutation(REMOVE_ASSOCIATITON_CATEGORY);
  const [setSetting, { loading: setExpandedKeysLoading }] =
    useMutation(SET_SETTING);

  const treeData = getEntityTreeData(
    categories.filter((item) => {
      if (preview) {
        return checkedCategories.indexOf(item._id) !== -1;
      } else {
        return true;
      }
    })
  );

  /**
   * If expanded keys exists - set it to the state
   */
  useEffect(() => {
    if (
      expandedKeysData?.getSetting?.data?.value &&
      expandedKeys !== expandedKeysData?.getSetting?.data?.value
    ) {
      setExpandedKeys(expandedKeysData?.getSetting?.data?.value);
    }
  }, [expandedKeysData]);

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const getChildCategories = (item) => {
    const categoriesList = [];
    item.children.forEach((sub) => {
      categoriesList.push(sub.key);
      if (sub.children.length > 0) {
        categoriesList.push(...getChildCategories(sub));
      }
    });

    return categoriesList;
  };

  const getChildsAttributes = (id) => {
    const childs = [];
    for (const attr of attributes) {
      if (attr?.core?.parentId === id) {
        childs.push(attr._id);
        const subChilds = getChildsAttributes(attr._id);
        if (subChilds.length > 0) {
          childs.push(...subChilds);
        }
      }
    }

    return childs;
  };

  const needCheckAllNested = (node) => {
    if (node.children.length === 0) return false;
    for (const item of node.children) {
      if (preview) {
        if (
          [
            ...previewCheckedCategories['checked'],
            ...previewCheckedCategories['halfChecked'],
          ].indexOf(item.key) !== -1
        ) {
          return false;
        }
      } else {
        if (checkedCategories.indexOf(item.key) !== -1) {
          return false;
        }
      }
    }
    return true;
  };

  const onCheckInPreviewMode = (checkedKeys, info) => {
    setPreviewCheckedCategories(checkedKeys);
    onPreviewCategoryChange([
      ...checkedKeys['checked'],
      ...checkedKeys['halfChecked'],
    ]);
    setLoading(false);
  };

  const onCheck = (checkedKeys, info) => {
    if (selectedAttribute) {
      setLoading(true);

      const attributesList = [
        ...new Set([
          selectedAttribute?._id,
          ...getChildsAttributes(selectedAttribute?._id),
        ]),
      ];

      let isChecked = info?.checked || false;
      if (!isChecked && needCheckAllNested(info?.node)) {
        isChecked = true;
        checkedKeys['checked'].push(info?.node.key);
        checkedKeys['checked'].push(...getChildCategories(info?.node));
      }

      if (isChecked) {
        if (preview) {
          return onCheckInPreviewMode(checkedKeys, info);
        }

        addAssociationCategory({
          variables: {
            clientCode: client?.apiId,
            attributeIds: attributesList,
            categoryIds: [
              ...checkedKeys['checked'],
              ...checkedKeys['halfChecked'],
            ],
          },
          refetchQueries: [CATEGORY_QUERY_NAME, ATTRIBUTE_QUERY_NAME],
          awaitRefetchQueries: true,
        })
          .then(() => {
            message.info('Updated successfully');
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        const childs = getChildCategories(info.node);
        const toRemove = [info.node.key];
        toRemove.push(...childs);

        const keysToLeave = [
          ...checkedKeys['checked'],
          ...checkedKeys['halfChecked'],
        ].filter((item) => {
          return toRemove.indexOf(item) === -1;
        });

        if (preview) {
          return onCheckInPreviewMode(
            { checked: keysToLeave, halfChecked: [] },
            info
          );
        }

        removeAssociationCategory({
          variables: {
            clientCode: client?.apiId,
            attributeIds: attributesList,
            categoryIds: [...keysToLeave],
          },
          refetchQueries: [CATEGORY_QUERY_NAME, ATTRIBUTE_QUERY_NAME],
          awaitRefetchQueries: true,
        })
          .then(() => {
            refetchCategories();
            message.info('Updated successfully');
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  useEffect(() => {
    console.log(
      JSON.stringify({
        loadingCategories,
        addAssociationCategoryLoading,
        removeAssociationCategoryLoading,
      })
    );
    setLoading(
      loadingCategories ||
        addAssociationCategoryLoading ||
        removeAssociationCategoryLoading ||
        false
    );
  }, [
    loadingCategories,
    addAssociationCategoryLoading,
    removeAssociationCategoryLoading,
  ]);

  const onExpand = async (data) => {
    /**
     * Store expanded keys into user settings
     */
    await setSetting({
      variables: {
        key: expandedKeysKey,
        value: data,
        clientCode: client.apiId,
      },
      refetchQueries: [GET_SETTING_QUERY],
    });
  };

  return (
    <div className="categories-content-tree">
      <Spin
        spinning={
          loadingCategories || setExpandedKeysLoading || expandedKeysLoading
        }
      >
        <div className="categories-content-tree-title">
          {selectedAttribute?.name}
        </div>
        <Search
          placeholder="input search text"
          onSearch={(value) => console.log(value)}
          style={{ width: 200 }}
        />
        <Spin spinning={loading}>
          {!loading && (
            <Tree
              className="categories-content-tree-content"
              checkable
              expandedKeys={expandedKeys}
              defaultSelectedKeys={[]}
              defaultCheckedKeys={[]}
              checkStrictly
              checkedKeys={
                preview ? previewCheckedCategories : checkedCategories
              }
              treeData={treeData}
              onSelect={onSelect}
              onCheck={onCheck}
              onExpand={onExpand}
              titleRender={(category) => {
                const skus = categories
                  .filter(
                    (cat) =>
                      cat._id === category.key ||
                      cat.core?.parentId === category.key
                  )
                  .map((cat) => cat.skus)
                  .flat();

                const attributesSkus = attributes
                  .filter(
                    (attr) =>
                      attr._id === selectedAttribute._id ||
                      attr.core?.parentId === selectedAttribute._id
                  )
                  .map((attr) => attr.skus)
                  .flat();

                return `${category.title} (${
                  _.intersection(skus, attributesSkus)?.length || 0
                })`;
              }}
            />
          )}
        </Spin>
      </Spin>
    </div>
  );
};

TaxonomyCategoriesTree.propTypes = {
  preview: PropTypes.bool,
  onPreviewCategoryChange: PropTypes.func,
};

TaxonomyCategoriesTree.defaultProps = {
  preview: false,
  onPreviewCategoryChange: () => {},
};

export default memo(TaxonomyCategoriesTree);
