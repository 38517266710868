import { Form, Radio, Select, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const { Text } = Typography;

const input = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, label, list_options } = settings;
  return (
    <Form.Item
      name={key}
      rules={rules()}
      label={label}
      initialValue={preDefinedVal?.toString()}
      hidden={hide ?? false}
      hasFeedback
    >
      <Radio.Group
        buttonStyle={'solid'}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        defaultValue={preDefinedVal?.toString()}
      >
        {list_options?.map((item) => (
          <Radio.Button value={item.value?.toString()}>
            {item.icon && <img className={'radioButtonIcon'} src={item.icon} />}
            {item.label?.toString()}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

input.propTypes = {
  props: PropTypes.object,
};

input.defaultProps = {
  props: {},
};

export default input;
