import { Form } from 'antd';
import PropTypes from 'prop-types';

import PropertiesTemplateUpload from '@marketreach/pages/taxonomy/properties/PropertiesTemplateUpload';

const input = (props) => {
  const {
    preDefinedVal,
    hide,
    readOnly,
    rules,
    settings,
    client,
    onChange,
    validation,
  } = props;
  const { key, label } = settings;

  return (
    <Form.Item
      name={key}
      rules={rules()}
      label={label}
      initialValue={preDefinedVal}
      hidden={hide ?? false}
      hasFeedback
      getValueFromEvent={(val) => {
        return val;
      }}
    >
      <PropertiesTemplateUpload
        readOnly={readOnly}
        imageUrl={preDefinedVal}
        client={client}
        onChange={onChange}
        validation={validation}
      />
    </Form.Item>
  );
};

input.propTypes = {
  props: PropTypes.object,
};

input.defaultProps = {
  props: {},
};

export default input;
