import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Modal } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import CustomProTable from '@marketreach/components/protable';
import ManagePropertiesControl from '@marketreach/pages/entities/components/ManagePropertiesControl';
import EntityGridView from '@marketreach/pages/share/entity/EntityGridView';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { ENTITY_DATA_QUERY } from '@marketreach/services/apollo/entity';
import {
  ENTITY_TYPE_ATTRIBUTE,
  ENTITY_TYPE_CATEGORY,
  sortByOrder,
} from '@marketreach/utils/common';
import { getSectionColumns } from '@marketreach/utils/products';

/**
 * Display custom entities in table view
 * Each row is clickable
 * On click opened editor of current custom entity
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const TableView = (props) => {
  const {
    addItem,
    handleSaveClick,
    removeEntityData,
    entityDataList,
    showEditor,
    entityInfo,
    setEntityToEdit,
    setShowEditor,
    entityToEdit,
    loading,
    entitiesList,
    handleShowDrawer,
    handleShowRules,
    showInvite,
    setSearchParams,
    setSortParams,
  } = props;

  const { selected: client } = useClientsState();
  const { type } = useParams();

  const sections = ('sections' in client ? client.sections[type] || [] : [])
    .slice()
    .sort(sortByOrder);

  // States

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount] = useState(0);
  const [pageSize, setPageSize] = useState(500);

  // Queries

  // Load entities

  // Load list of possible entities

  // Effects

  // Handlers

  /**
   * Open edit drawer on row click in simple mode
   * @param row
   */
  const handleRowClick = (row) => {
    setEntityToEdit(row);
    setShowEditor(true);
  };

  const handleSearch = (params) => {
    setSearchParams(params);
  };

  const handleSortChange = (sortField, direction) => {
    setSortParams({
      field: sortField,
      direction,
    });
  };

  const handleDelete = (row, e) => {
    e.preventDefault();
    e.stopPropagation();

    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you really want to delete that item?',
      okText: 'Yes',
      cancelText: 'No',

      onOk: async () => {
        await removeEntityData({
          variables: {
            id: row?._id,
            clientCode: client?.apiId,
            type,
          },
          refetchQueries: [ENTITY_DATA_QUERY],
        });
      },
    });
  };

  const handlePageChange = (page, size) => {
    setPageNumber(page);
    setPageSize(size);
  };

  // Prepare variables

  const columns = getSectionColumns(sections);

  columns.push({
    title: 'Operation',
    dataIndex: 'operation',
    sorter: false,
    width: 10,
    render: (text, row) => [
      <Button
        key="edit"
        type="text"
        shape="circle"
        onClick={() => handleRowClick(row)}
      >
        <EditOutlined />
      </Button>,
      <Button
        key="delete"
        type="text"
        shape="circle"
        onClick={(e) => handleDelete(row, e)}
      >
        <DeleteOutlined />
      </Button>,
    ],
  });

  const toolbar = () => {
    return (
      <div>
        <Button type="primary" onClick={() => addItem(true)}>
          <PlusOutlined /> Add item
        </Button>
        <ManagePropertiesControl
          showInvite={showInvite}
          handleShowDrawer={handleShowDrawer}
        />
      </div>
    );
  };

  const title = () => {
    return (
      <div className="product-detail-header">
        <div className="product-detail-header-title">
          <strong>
            {_.isEmpty(entityToEdit)
              ? `Add ${entityInfo?.label}`
              : `Edit ${entityInfo?.label}`}
          </strong>
        </div>
        <div>
          <Button type="link" onClick={() => handleShowDrawer(true)}>
            Manage properties
          </Button>
          {[ENTITY_TYPE_CATEGORY, ENTITY_TYPE_ATTRIBUTE].includes(type) && (
            <Button type="link" onClick={() => handleShowRules(true)}>
              Manage rules
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <CustomProTable
        className="products-table"
        data={entityDataList}
        columns={columns}
        loading={loading}
        toolbar={toolbar}
        pagination={{
          current: pageNumber,
          total: totalCount,
          pageSize,
          showSizeChanger: true,
          onChange: (page, size) => {
            handlePageChange(page, size);
          },
          // showTotal: false,
          showTotal: (total, range) => (
            <div>{`Showing ${range[0]}-${range[1]} of ${total} total items`}</div>
          ),
        }}
        searchable
        handleSearch={handleSearch}
        handleSortChange={handleSortChange}
        handleRowClick={handleRowClick}
      />
      {showEditor && (
        <EntityGridView
          visible={showEditor}
          title={title()}
          entity={entityToEdit}
          type={type}
          handleShowGridView={addItem}
          handleSaveClick={handleSaveClick}
          entitiesList={entitiesList}
        />
      )}
    </>
  );
};

TableView.propTypes = {
  addItem: PropTypes.func,
  handleSaveClick: PropTypes.func,
  removeEntityData: PropTypes.func,
  setEntityToEdit: PropTypes.func,
  setShowEditor: PropTypes.func,
  handleShowDrawer: PropTypes.func,
  handleShowRules: PropTypes.func,
  setSearchParams: PropTypes.func,
  setSortParams: PropTypes.func,
  entityDataList: PropTypes.arrayOf(PropTypes.any),
  entitiesList: PropTypes.arrayOf(PropTypes.any),
  showEditor: PropTypes.bool,
  loading: PropTypes.bool,
  showInvite: PropTypes.bool,
  entityInfo: PropTypes.objectOf(PropTypes.any),
  entityToEdit: PropTypes.objectOf(PropTypes.any),
};

TableView.defaultProps = {
  addItem: () => null,
  handleSaveClick: () => null,
  removeEntityData: () => null,
  setEntityToEdit: () => null,
  setShowEditor: () => null,
  handleShowDrawer: () => null,
  handleShowRules: () => null,
  setSearchParams: () => null,
  setSortParams: () => null,
  entityDataList: [],
  entitiesList: [],
  showEditor: false,
  loading: false,
  showInvite: false,
  entityInfo: {},
  entityToEdit: {},
};

export default TableView;
