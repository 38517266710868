import { Button, Popover, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const { Text } = Typography;

/**
 * Display "Manage properties" button with popover
 * Popover used to display to user message, that api key is yet not set
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const ManagePropertiesControl = (props) => {
  const { showInvite, handleShowDrawer } = props;
  return (
    <Popover
      placement="leftTop"
      title={<Text type="warning">Add property</Text>}
      content={'Please add first property'}
      visible={showInvite}
    >
      <Button type="link" onClick={() => handleShowDrawer(true)}>
        Manage properties
      </Button>
    </Popover>
  );
};

ManagePropertiesControl.propTypes = {
  showInvite: PropTypes.bool,
  handleShowDrawer: PropTypes.func,
};

ManagePropertiesControl.defaultProps = {
  showInvite: false,
  handleShowDrawer: () => null,
};

export default ManagePropertiesControl;
