import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

const input = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, label, list_options } = settings;
  return (
    <Form.Item
      name={key}
      rules={rules()}
      label={label}
      initialValue={preDefinedVal?.toString()}
      hidden={hide ?? false}
      hasFeedback
    >
      <Select
        disabled={disabled}
        readOnly={readOnly}
        placeholder="Please select"
        defaultValue={preDefinedVal?.toString()}
        style={{ width: '100%' }}
        onChange={onChange}
      >
        {list_options?.map((item) => (
          <Select.Option key={item.value?.toString()}>
            {item.label?.toString()}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

input.propTypes = {
  props: PropTypes.object,
};

input.defaultProps = {
  props: {},
};

export default input;
