import { Modal, Form, Input, message, Spin, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import './styles.scss';

const { Text } = Typography;

const EntityModal = (props) => {
  const { title, mode, entityId, open, handleOk, handleCancel, childs } = props;

  console.log('EntityModal: ', props);

  const [form] = Form.useForm();

  const [categoryInProcess] = useState(false);

  const input = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (open && input.current) {
      setTimeout(() => {
        input.current?.focus();
      });
    }
  }, [open, input]);

  const onOk = () => {
    const formValue = form.getFieldsValue();

    if (
      (mode === 'bulk_create' || mode === 'root_create') &&
      !(formValue?.names?.trim()?.length > 0)
    ) {
      message.error('Please input entity name');

      return;
    }

    if (entityId && mode === 'delete') {
      handleOk(entityId);
    }

    if (entityId && (mode === 'bulk_create' || mode === 'root_create')) {
      handleOk(formValue?.names);
    }

    if (!entityId && mode === 'root_create') {
      handleOk(formValue?.names);
    }

    form.setFieldsValue({
      names: '',
    });
  };

  const onCancel = () => {
    form.setFieldsValue({
      name: '',
    });
    handleCancel();
  };

  const onFormLayoutChange = () => {};

  const handleKeyUp = (event) => {
    if (mode !== 'bulk_create' && mode !== 'root_create') {
      if (event.keyCode === 13) {
        onOk();
      }
    }
  };

  return (
    <Modal
      className="taxonomy-sidebar-category-modal"
      title={title}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      width={400}
    >
      <Spin spinning={categoryInProcess}>
        <Form
          ref={formRef}
          form={form}
          labelCol={{ span: 4 }}
          onValuesChange={onFormLayoutChange}
          onKeyUp={handleKeyUp}
        >
          {mode === 'delete' && (
            <>
              <div>Are sure want to delete this entity?</div>
              {childs.length > 0 && (
                <>
                  <br />
                  <Text type="danger">
                    <b>
                      Warning: This will also delete {childs?.length}{' '}
                      {childs?.length === 1 ? 'subcategory' : 'subcategories'}
                    </b>
                  </Text>
                </>
              )}
            </>
          )}
          {(mode === 'bulk_create' || mode === 'root_create') && (
            <>
              <Form.Item label="Name" name="names" required>
                <TextArea
                  ref={input}
                  placeholder="input names"
                  autoFocus
                  tabIndex={0}
                  autoSize={{ minRows: 1 }}
                />
              </Form.Item>
            </>
          )}
          {(mode === 'create' || mode === 'edit' || mode === 'root') && (
            <>
              <Form.Item label="Name" name="name" required>
                <Input
                  ref={input}
                  placeholder="input name"
                  autoFocus
                  tabIndex={0}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

EntityModal.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.string,
  entityId: PropTypes.string,
  open: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  childs: PropTypes.array,
};

EntityModal.defaultProps = {
  title: '',
  entityId: null,
  mode: '',
  open: false,
  handleOk: () => {},
  handleCancel: () => {},
  childs: [],
};

export default EntityModal;
