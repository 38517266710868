import { Select } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './styles.scss';

const { Option } = Select;

const CustomSelect = (props) => {
  const {
    className,
    value,
    defaultValue,
    items,
    placeholder,
    mode,
    handleChange,
  } = props;

  const onChange = (value) => {
    handleChange(value);
  };

  return (
    <Select
      mode={mode}
      className={classnames('custom-select', className)}
      showSearch
      value={value}
      defaultValue={defaultValue || items[0]?.value}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      dropdownStyle={style}
    >
      {items.map((item) => (
        <Option value={item.value} key={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

const style = {
  width: 'max-content',
  minWidth: '30%',
};

CustomSelect.propTypes = {
  mode: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  items: PropTypes.array,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func,
};

CustomSelect.defaultProps = {
  mode: undefined,
  value: '',
  defaultValue: '',
  items: [],
  placeholder: '',
  className: '',
  handleChange: () => {},
};

export default memo(CustomSelect);
