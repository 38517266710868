import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React, { memo, useState } from 'react';

import CustomLoader from '@marketreach/components/loader';
import './styles.scss';
import ActionGenerator, {
  ACTION_TYPE_RULE_RECALCULATION,
  ACTION_TYPE_PRODUCT_SCHEMA_REFRESH,
  ACTION_TYPE_RE_APPLY_TEMPLATES,
  ACTION_TYPE_RULE_RECALCULATION_TOTAL,
  ACTION_TYPE_PRODUCT_SCHEMA_REFRESH_TOTAL,
  ACTION_TYPE_FIX_DEAD_ASSOCIATIONS,
  ACTION_TYPE_FIX_DEAD_ASSOCIATIONS_TOTAL,
} from '@marketreach/pages/auth/settings/actions/ActionGenerator';
import {
  hasPermission,
  SETTINGS_USERS_MANAGE_PERMISSIONS,
} from '@marketreach/utils/permissioins';

const Actions = () => {
  const { user } = useAuth0();

  const [loading, setLoading] = useState(false);

  return (
    <Spin spinning={loading}>
      <div className="settings-content-rule">
        <CustomLoader loading={loading} />
        <div className="row-content">
          <ActionGenerator
            type={ACTION_TYPE_RULE_RECALCULATION}
            setLoading={setLoading}
          />
        </div>
        <br />
        <div className="row-content">
          <ActionGenerator
            type={ACTION_TYPE_PRODUCT_SCHEMA_REFRESH}
            setLoading={setLoading}
          />
        </div>
        <br />
        <div className="row-content">
          <ActionGenerator
            type={ACTION_TYPE_RE_APPLY_TEMPLATES}
            setLoading={setLoading}
          />
        </div>
        <br />
        <div className="row-content">
          <ActionGenerator
            type={ACTION_TYPE_FIX_DEAD_ASSOCIATIONS}
            setLoading={setLoading}
          />
        </div>
        {hasPermission(user, SETTINGS_USERS_MANAGE_PERMISSIONS) && (
          <>
            <br />
            <div className="row-content">
              <ActionGenerator
                type={ACTION_TYPE_RULE_RECALCULATION_TOTAL}
                setLoading={setLoading}
              />
            </div>
            <br />

            <div className="row-content">
              <ActionGenerator
                type={ACTION_TYPE_PRODUCT_SCHEMA_REFRESH_TOTAL}
                setLoading={setLoading}
              />
            </div>

            <br />
            <div className="row-content">
              <ActionGenerator
                type={ACTION_TYPE_FIX_DEAD_ASSOCIATIONS_TOTAL}
                setLoading={setLoading}
              />
            </div>
          </>
        )}
      </div>
    </Spin>
  );
};

Actions.propTypes = {};

Actions.defaultProps = {};

export default memo(Actions);
