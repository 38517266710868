import {
  CalculatorOutlined,
  DatabaseOutlined,
  BuildOutlined,
  LinkOutlined,
  ContactsOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';

import Action from '@marketreach/pages/auth/settings/actions/Action';
import {
  FIX_DEAD_ASSOCIATIONS,
  FIX_DEAD_ASSOCIATIONS_TOTAL,
} from '@marketreach/services/apollo/action';
import {
  APPLY_TEMPLATES,
  APPLY_TEMPLATES_FOR_ALL_CLIENTS,
  REFRESH_ALL_CLIENTS,
  REFRESH_CLIENT,
} from '@marketreach/services/apollo/clients';
import {
  REFRESH_PRODUCTS_SCHEMA,
  REFRESH_PRODUCTS_SCHEMA_FOR_ALL_CLIENTS,
} from '@marketreach/services/apollo/products';
import {
  RECALCULATE_RULES,
  TOTAL_RECALCULATE_RULES,
} from '@marketreach/services/apollo/rules';

export const ACTION_TYPE_RULE_RECALCULATION = 'rule:recalculation';
export const ACTION_TYPE_RULE_RECALCULATION_TOTAL = 'rule:recalculation:total';
export const ACTION_TYPE_PRODUCT_SCHEMA_REFRESH = 'product:schema:refresh';
export const ACTION_TYPE_PRODUCT_SCHEMA_REFRESH_TOTAL =
  'product:schema:refresh:total';
export const ACTION_TYPE_RE_APPLY_TEMPLATES = 'templates:apply:re';
export const ACTION_TYPE_RE_APPLY_TEMPLATES_TOTAL = 'templates:apply:re:total';
export const ACTION_TYPE_FIX_DEAD_ASSOCIATIONS = 'fix:dead:associations';
export const ACTION_TYPE_FIX_DEAD_ASSOCIATIONS_TOTAL =
  'fix:dead:associations:total';

export const ACTION_TYPE_CLIENT_REFRESH = 'client:refresh';
export const ACTION_TYPE_CLIENT_REFRESH_TOTAL = 'client:refresh:total';

const actionList = {
  [ACTION_TYPE_RULE_RECALCULATION]: {
    query: RECALCULATE_RULES,
    buttonLabel: (
      <>
        <CalculatorOutlined /> Recalculate Rules
      </>
    ),
    confirmationLabel: 'All Rules will be recalculated. Are you sure?',
    finishLabel: 'Rule recalculation has been added to task queue.',
  },
  [ACTION_TYPE_PRODUCT_SCHEMA_REFRESH]: {
    query: REFRESH_PRODUCTS_SCHEMA,
    buttonLabel: (
      <>
        <DatabaseOutlined /> Refresh product schema
      </>
    ),
    confirmationLabel: 'Product schema will be refreshed. Are you sure?',
    finishLabel: 'Product schema refresh request was added to task queue.',
  },
  [ACTION_TYPE_RE_APPLY_TEMPLATES]: {
    query: APPLY_TEMPLATES,
    buttonLabel: (
      <>
        <BuildOutlined /> Re-Apply Templates
      </>
    ),
    confirmationLabel: 'All templated values will be updated. Are you sure?',
    finishLabel: 'Templated value update was added to the task queue.',
  },
  [ACTION_TYPE_RE_APPLY_TEMPLATES_TOTAL]: {
    query: APPLY_TEMPLATES_FOR_ALL_CLIENTS,
    buttonLabel: (
      <>
        <BuildOutlined /> Re-Apply Templates Total
      </>
    ),
    confirmationLabel:
      'All templated values for all clients will be updated. Are you sure?',
    finishLabel:
      'Templated value update for all clients was added to the task queue.',
  },
  [ACTION_TYPE_RULE_RECALCULATION_TOTAL]: {
    query: TOTAL_RECALCULATE_RULES,
    buttonLabel: (
      <>
        <CalculatorOutlined /> Recalculate Rules For All clients
      </>
    ),
    confirmationLabel:
      'All rules for all clients will be recalculated. Are you sure?',
    finishLabel: 'Rule recalculation for all clients was added to task queue.',
  },
  [ACTION_TYPE_PRODUCT_SCHEMA_REFRESH_TOTAL]: {
    query: REFRESH_PRODUCTS_SCHEMA_FOR_ALL_CLIENTS,
    buttonLabel: (
      <>
        <DatabaseOutlined /> Refresh product schema for all clients
      </>
    ),
    confirmationLabel:
      'Product schema will be refreshed for all clients. Are you sure?',
    finishLabel:
      'Product schema refresh for all clients was added to the task queue.',
  },
  [ACTION_TYPE_FIX_DEAD_ASSOCIATIONS]: {
    query: FIX_DEAD_ASSOCIATIONS,
    buttonLabel: (
      <>
        <LinkOutlined /> Fix Dead Association
      </>
    ),
    confirmationLabel:
      'All associations will be reviewed for issues. Are you sure?',
    finishLabel:
      'System objects (categories, attributes, rules) were added to the task queue.',
  },
  [ACTION_TYPE_FIX_DEAD_ASSOCIATIONS_TOTAL]: {
    query: FIX_DEAD_ASSOCIATIONS_TOTAL,
    buttonLabel: (
      <>
        <LinkOutlined /> Fix Dead Association for all clients
      </>
    ),
    confirmationLabel:
      'Associations for all clients will be reviewed for issues. Are you sure?',
    finishLabel:
      'System objects for all clients (categories, attributes, rules) were added to the task queue.',
  },
  [ACTION_TYPE_CLIENT_REFRESH]: {
    query: REFRESH_CLIENT,
    buttonLabel: (
      <>
        <ContactsOutlined /> Refresh Client
      </>
    ),
    confirmationLabel:
      'This will executed: schema refresh, rule recalculation and templated value update. Are you sure?',
    finishLabel:
      'Added to task queue: schema refresh, rule recalculation, templated value update.',
  },
  [ACTION_TYPE_CLIENT_REFRESH_TOTAL]: {
    query: REFRESH_ALL_CLIENTS,
    buttonLabel: (
      <>
        <ContactsOutlined /> Refresh All Clients
      </>
    ),
    confirmationLabel:
      'This will executed for all clients: schema refresh, rule recalculation and templated value update. Are you sure?',
    finishLabel:
      'Added to task queue for all clients: schema refresh, rule recalculation, templated value update.',
  },
};

const ActionGenerator = (props) => {
  const { type, setLoading, buttonType } = props;

  return (
    <Action
      setLoading={setLoading}
      query={actionList[type].query}
      buttonLabel={actionList[type].buttonLabel}
      confirmationLabel={actionList[type].confirmationLabel}
      finishLabel={actionList[type].finishLabel}
      buttonType={buttonType}
    />
  );
};

ActionGenerator.propTypes = {
  type: PropTypes.string,
  setLoading: PropTypes.func,
  buttonType: PropTypes.string,
};

ActionGenerator.defaultProps = {
  type: null,
  setLoading: () => null,
  buttonType: null,
};

export default ActionGenerator;
