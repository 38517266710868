import { useMutation, useQuery } from '@apollo/client';
import { Input, Tree, message, Spin } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import {
  ATTRIBUTE_QUERY_NAME,
  ATTRIBUTES,
} from '@marketreach/services/apollo/attributes';
import {
  ADD_ASSOCIATITON_ATTRIBUTE,
  CATEGORY_QUERY_NAME,
  REMOVE_ASSOCIATITON_ATTRIBUTE,
} from '@marketreach/services/apollo/categories';
import {
  GET_SETTING,
  GET_SETTING_QUERY,
  SET_SETTING,
} from '@marketreach/services/apollo/user';
import { getAttributeTreeData } from '@marketreach/utils/categories';
import './styles.scss';

const { Search } = Input;

const TaxonomyAttributesTree = (props) => {
  const { preview, onPreviewAttributeChange } = props;

  const { selected: client } = useClientsState();
  const { context, setContext } = useContext(TaxonomyContext);
  const { selectedCategory, attributes } = context;

  const [loading, setLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);

  // Build key for get/set expanded keys of tree
  const expandedKeysKey = `taxonomy_attributes_tree_expanded_keys`;

  const {
    loading: loadingAttributes,
    data: attributeRes,
    refetch: refetchAttributes,
  } = useQuery(ATTRIBUTES, {
    variables: { clientCode: client?.apiId },
  });

  // Load expanded keys data
  const { data: expandedKeysData, loading: expandedKeysLoading } = useQuery(
    GET_SETTING,
    {
      variables: {
        key: expandedKeysKey,
        clientCode: client?.apiId,
      },
    }
  );

  useEffect(() => {
    if (client) {
      refetchAttributes();
    }
  }, [client, refetchAttributes]);

  useEffect(() => {
    const attributeData = attributeRes ? attributeRes?.attributes?.data : [];
    const attributesList = attributeData || [];

    if (!_.isEqual(context.attributes, attributesList)) {
      setContext({ ...context, attributes: attributesList });
    }
  }, [attributeRes, setContext]);

  const checkedAttributes = [
    ...new Set(selectedCategory?.associations?.attributes || []),
  ];
  const [previewCheckedAttributes, setPreviewCheckedAttributes] = useState({
    checked: [],
    halfChecked: [],
  });

  const [addAssociationAttribute, { loading: addAssociationAttributeLoading }] =
    useMutation(ADD_ASSOCIATITON_ATTRIBUTE);
  const [
    removeAssociationAttribute,
    { loading: removeAssociationAttributeLoading },
  ] = useMutation(REMOVE_ASSOCIATITON_ATTRIBUTE);
  const [setSetting, { loading: setExpandedKeysLoading }] =
    useMutation(SET_SETTING);

  const checkChildSelected = (attribute) => {
    const childs = attributes.filter(
      (item) => item?.core?.parentId === attribute._id
    );

    if (childs.length === 0) return false;

    for (const child of childs) {
      if (checkedAttributes.indexOf(child._id) !== -1) {
        return true;
      }

      if (checkChildSelected(child)) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    console.log(
      JSON.stringify({
        loadingAttributes,
        addAssociationAttributeLoading,
        removeAssociationAttributeLoading,
      })
    );
    setLoading(
      loadingAttributes ||
        addAssociationAttributeLoading ||
        removeAssociationAttributeLoading ||
        false
    );
  }, [
    loadingAttributes,
    addAssociationAttributeLoading,
    removeAssociationAttributeLoading,
  ]);

  const treeData = getAttributeTreeData(
    attributes.filter((item) => {
      if (preview) {
        return (
          checkedAttributes.indexOf(item._id) !== -1 || checkChildSelected(item)
        );
      } else {
        return true;
      }
    })
  );

  /**
   * If expanded keys exists - set it to the state
   */
  useEffect(() => {
    if (
      expandedKeysData?.getSetting?.data?.value &&
      expandedKeys !== expandedKeysData?.getSetting?.data?.value
    ) {
      setExpandedKeys(expandedKeysData?.getSetting?.data?.value);
    }
  }, [expandedKeysData]);

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const onCheckInPreviewMode = (checkedKeys, info) => {
    setPreviewCheckedAttributes(checkedKeys);
    onPreviewAttributeChange(checkedKeys);
  };

  const onCheck = (checkedKeys, info) => {
    if (selectedCategory) {
      if (preview) {
        return onCheckInPreviewMode(checkedKeys, info);
      }
      if (info?.checked) {
        addAssociationAttribute({
          variables: {
            clientCode: client?.apiId,
            _id: selectedCategory?._id,
            attributeIds: checkedKeys,
          },
          refetchQueries: [
            // ATTRIBUTES_QUERY_NAME,
            // CATEGORIES_QUERY_NAME,
            CATEGORY_QUERY_NAME,
            ATTRIBUTE_QUERY_NAME,
          ],
          awaitRefetchQueries: true,
        })
          .then(() => {
            message.info('Updated successfully');
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        removeAssociationAttribute({
          variables: {
            clientCode: client?.apiId,
            _id: selectedCategory?._id,
            attributeIds: checkedKeys,
          },
          refetchQueries: [
            // ATTRIBUTES_QUERY_NAME,
            // CATEGORIES_QUERY_NAME,
            CATEGORY_QUERY_NAME,
            ATTRIBUTE_QUERY_NAME,
          ],
          awaitRefetchQueries: true,
        })
          .then(() => {
            message.info('Updated successfully');
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  const onExpand = async (data) => {
    /**
     * Store expanded keys into user settings
     */
    await setSetting({
      variables: {
        key: expandedKeysKey,
        value: data,
        clientCode: client.apiId,
      },
      refetchQueries: [GET_SETTING_QUERY],
    });
  };

  return (
    <div className="attributes-content-tree">
      <Spin spinning={loading || setExpandedKeysLoading || expandedKeysLoading}>
        <div className="attributes-content-tree-title">
          {selectedCategory?.name}
        </div>
        <Search
          placeholder="input search text"
          onSearch={(value) => console.log(value)}
          style={{ width: 200 }}
        />
        {!loading && (
          <Tree
            className="attributes-content-tree-content"
            checkable
            expandedKeys={expandedKeys}
            defaultSelectedKeys={[]}
            defaultCheckedKeys={[]}
            checkedKeys={preview ? previewCheckedAttributes : checkedAttributes}
            treeData={treeData}
            onSelect={onSelect}
            onCheck={onCheck}
            onExpand={onExpand}
            titleRender={(attribute) => {
              if (attribute.children.length > 0) {
                return attribute.title;
              }
              const skus =
                attributes.find((attr) => attr._id === attribute.key)?.skus ||
                [];
              return `${attribute.title} (${
                _.intersection(
                  [...new Set(selectedCategory?.skus)],
                  [...new Set(skus.flat())]
                )?.length || 0
              })`;
            }}
          />
        )}
      </Spin>
    </div>
  );
};

TaxonomyAttributesTree.propTypes = {
  attributes: PropTypes.array,
  preview: PropTypes.bool,
  onPreviewAttributeChange: PropTypes.func,
};

TaxonomyAttributesTree.defaultProps = {
  attributes: [],
  preview: false,
  onPreviewAttributeChange: () => {},
};

export default memo(TaxonomyAttributesTree);
