import { Form, InputNumber } from 'antd';
import PropTypes from 'prop-types';

const input = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, label } = settings;
  return (
    <Form.Item
      name={key}
      rules={rules()}
      label={label}
      initialValue={parseInt(preDefinedVal)}
      hidden={hide ?? false}
      hasFeedback
    >
      <InputNumber
        disabled={disabled}
        readOnly={readOnly}
        type="number"
        precision={0}
        defaultValue={parseInt(preDefinedVal)}
        onChange={onChange}
      />
    </Form.Item>
  );
};

input.propTypes = {
  props: PropTypes.object,
};

input.defaultProps = {
  props: {},
};

export default input;
