import { useAuth0 } from '@auth0/auth0-react';
import { Layout } from 'antd';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import TopBar from '@marketreach/components/topbar';
import LoginForm from '@marketreach/pages/auth/login/index';
import ProfileForm from '@marketreach/pages/auth/profile';
import RegistrationForm from '@marketreach/pages/auth/register';
import SettingsPage from '@marketreach/pages/auth/settings';
import ClientsPage from '@marketreach/pages/clients';
import Dashboard from '@marketreach/pages/dashboard';
import EntitiesPage from '@marketreach/pages/entities';
import FeedsPage from '@marketreach/pages/feeds';
import ProductsPage from '@marketreach/pages/products';
import RulesPage from '@marketreach/pages/rules';
import Taxonomy from '@marketreach/pages/taxonomy';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import PrivateRoute from '@marketreach/routes/PrivateRoute';
import PublicRoute from '@marketreach/routes/PublicRoute';
import './App.less';
import {
  hasPermission,
  SETTINGS_LOCALES_MANAGE_PERMISSIONS,
} from '@marketreach/utils/permissioins';

const App = (props) => {
  const { user } = useAuth0();

  const { selected: client } = useClientsState();

  const pageProps = {
    ...props,
    user,
  };

  return (
    <div className="App">
      <TopBar />
      <Layout>
        <div className="page-content">
          <Switch>
            <Redirect exact from="/" to="/taxonomy" />
            <Redirect
              exact
              from="/settings"
              to={`/settings/${
                user && hasPermission(user, SETTINGS_LOCALES_MANAGE_PERMISSIONS)
                  ? 'clients'
                  : 'actions'
              }`}
            />
            <PublicRoute
              exact
              path="/login"
              component={LoginForm}
              props={pageProps}
            />
            <PublicRoute
              exact
              path="/callback"
              component={LoginForm}
              props={pageProps}
            />
            <PublicRoute
              exact
              path="/sign-up"
              component={RegistrationForm}
              props={pageProps}
            />
            <PrivateRoute
              exact
              path="/settings/:type"
              component={SettingsPage}
              props={pageProps}
            />
            {client && (
              <>
                <PrivateRoute
                  exact
                  path="/dashboard"
                  component={Dashboard}
                  props={pageProps}
                />
                <PrivateRoute
                  exact
                  path="/profile"
                  component={ProfileForm}
                  props={pageProps}
                />
                <PrivateRoute
                  exact
                  path="/taxonomy"
                  component={Taxonomy}
                  props={pageProps}
                />
                <PrivateRoute
                  exact
                  path="/products"
                  component={ProductsPage}
                  props={pageProps}
                />
                <PrivateRoute
                  exact
                  path="/feeds"
                  component={FeedsPage}
                  props={pageProps}
                />
                <PrivateRoute
                  exact
                  path="/rules"
                  component={RulesPage}
                  props={pageProps}
                />
                <PrivateRoute
                  exact
                  path="/clients"
                  component={ClientsPage}
                  props={pageProps}
                />
                <PrivateRoute
                  exact
                  path="/entities/:type"
                  component={EntitiesPage}
                  props={pageProps}
                />
              </>
            )}
          </Switch>
        </div>
      </Layout>
    </div>
  );
};

export default App;
