import { Progress, Typography } from 'antd';
import React, { memo } from 'react';

import CustomTabs from '@marketreach/components/tabs';

import PaymentStatusChart from './PaymentStatusChart';
import './styles.scss';

const { Text } = Typography;

const AllStoresStatus = () => {
  const tabs = [...Array(30).keys()].map((key) => (
    <div className="ant-row conversion-rate">
      <div className="ant-col-12 conversion-rate-content">
        <Text className="store-name">{`Store ${key}`}</Text>
        <Text className="conversion-rate-title">Conversion Rate</Text>
        <Text className="conversion-rate-value">{`${(key % 10) * 10}%`}</Text>
      </div>
      <Progress
        strokeLinecap="square"
        type="circle"
        percent={(key % 10) * 10}
        width={40}
        className="ant-col-12 conversion-rate-progress"
      />
    </div>
  ));
  const tabContents = [...Array(30).keys()].map(() => <PaymentStatusChart />);

  return (
    <div className="payment-status-content">
      <CustomTabs tabs={tabs} tabContents={tabContents} />
    </div>
  );
};

export default memo(AllStoresStatus);
