import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Dropdown,
  Layout,
  Menu,
  message,
  Modal,
  Spin,
  Tabs,
  Tree,
} from 'antd';
import Sider from 'antd/es/layout/Sider';
import PropTypes from 'prop-types';
import React, { memo, useState, useEffect } from 'react';

import CustomProTable from '@marketreach/components/protable';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import {
  ATTRIBUTES,
  ATTRIBUTES_QUERY_NAME,
} from '@marketreach/services/apollo/attributes';
import {
  CATEGORIES,
  CATEGORIES_QUERY_NAME,
} from '@marketreach/services/apollo/categories';
import { GET_PRODUCT_BY_PAGE_QUERY } from '@marketreach/services/apollo/products';
import {
  CREATE_RULE,
  DELETE_RULE,
  RULES,
  RULES_BY_PAGE_QUERY,
  RULES_QUERY,
} from '@marketreach/services/apollo/rules';
import {
  getAttributeTreeData,
  getEntityTreeData,
} from '@marketreach/utils/categories';

import RuleEdit from './components/rule-form/RuleForm';

import './Rules.styles.scss';
import {
  basisCell,
  criteriaChips,
  prepareRulesColumns,
} from '@marketreach/utils/rules';

const { TabPane } = Tabs;
const RulesPage = () => {
  const { selected: client } = useClientsState();
  const initRulesStat = {
    total: 0,
    include: 0,
    exclude: 0,
  };
  /* -------------------------------------------------------------------------- */
  /*                               RULE MUTATIONS                               */
  /* -------------------------------------------------------------------------- */
  const [createRule] = useMutation(CREATE_RULE);
  const [deleteRule] = useMutation(DELETE_RULE);

  /* -------------------------------------------------------------------------- */
  /*                              LOCAL COMP STATE                              */
  /* -------------------------------------------------------------------------- */
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [rulesStat, setRulesStat] = useState(initRulesStat);

  // filtering and sorting
  const [searchParams, setSearchParams] = useState({});
  const [sortParams, setSortParams] = useState({});

  /* -------------------------------------------------------------------------- */
  /*                             GET ALL RULES QUERY                            */
  /* -------------------------------------------------------------------------- */
  const {
    data,
    refetch: refetchRules,
    loading: rulesLoading,
  } = useQuery(RULES, {
    variables: { clientCode: client?.apiId, searchParams, sortParams },
    fetchPolicy: 'no-cache',
  });
  const rules = data?.rules?.data || [];

  useEffect(() => {
    if (data?.rules?.data && data.rules.data.length > 0) {
      const stat = data.rules.data.reduce((acc, row) => {
        acc.total++;
        if (row.basis === 'include') {
          acc.include++;
        } else {
          acc.exclude++;
        }
        return acc;
      }, initRulesStat);

      setRulesStat(stat);
    }
  }, [rules, setRulesStat]);

  /* -------------------------------------------------------------------------- */
  /*                             GET ALL CATEGORIES                             */
  /* -------------------------------------------------------------------------- */
  const { data: categoryRes, loading: categoriesLoading } = useQuery(
    CATEGORIES,
    {
      variables: { clientCode: client?.apiId },
    }
  );
  const categoryData = categoryRes ? categoryRes?.categories?.data : [];
  const categoryTree = getEntityTreeData(categoryData);

  /* -------------------------------------------------------------------------- */
  /*                             GET ALL ATTRIBUTES                             */
  /* -------------------------------------------------------------------------- */
  const { data: attributeRes, loading: attributesLoading } = useQuery(
    ATTRIBUTES,
    {
      variables: { clientCode: client?.apiId },
    }
  );
  const attributeData = attributeRes ? attributeRes?.attributes?.data : [];
  const attributeTree = getAttributeTreeData(attributeData);

  /* -------------------------------------------------------------------------- */
  /*                               LOCAL HANDLERS                               */
  /* -------------------------------------------------------------------------- */
  const handleSaveClick = (values) => {
    createRule({
      variables: {
        ...values,
        clientCode: client?.apiId,
        categoryIds: selectedCategories,
        attributeIds: selectedAttributes,
        selectedRuleId: selectedRule?._id,
      },
      refetchQueries: [
        GET_PRODUCT_BY_PAGE_QUERY,
        RULES_QUERY,
        RULES_BY_PAGE_QUERY,
        CATEGORIES_QUERY_NAME,
        ATTRIBUTES_QUERY_NAME,
      ],
      awaitRefetchQueries: true,
    }).then(() => {
      message.info('Rule created successfully');
      refetchRules();
      setIsEdit(false);
    });
  };

  const handleEditRule = (rule) => {
    setSelectedRule(rule);
    setSelectedCategories(rule?.associations?.categories);
    setSelectedAttributes(rule?.associations?.attributes);
    setIsEdit(true);
  };

  const handleDeleteRuleOk = () => {
    deleteRule({
      variables: {
        _id: selectedRule?._id,
        clientCode: client?.apiId,
        hardDelete: true,
      },
      refetchQueries: [
        GET_PRODUCT_BY_PAGE_QUERY,
        RULES_QUERY,
        RULES_BY_PAGE_QUERY,
      ],
    }).then(() => {
      setOpenConfirmModal(false);
      setSelectedRule(null);
      refetchRules();
    });
  };

  const handleDeleteRuleCancel = () => {
    setOpenConfirmModal(false);
    setSelectedRule(null);
  };

  const handleDeleteRule = (rule) => {
    setSelectedRule(rule);
    setOpenConfirmModal(true);
  };

  const handleCancelClick = () => {
    setIsEdit(false);
    setSelectedRule(null);
  };

  const onClickEdit = () => {
    setSelectedRule(null);
    setSelectedCategories([]);
    setSelectedAttributes([]);
    setIsEdit(true);
  };

  const columns = prepareRulesColumns(
    basisCell,
    criteriaChips,
    handleDeleteRule,
    handleEditRule
  );

  const menu = (
    <Menu>
      <Menu.Item key="1">Menu 1</Menu.Item>
      <Menu.Item key="2">Menu 2</Menu.Item>
    </Menu>
  );

  const toolbar = () => [
    <Button type="primary" onClick={onClickEdit}>
      <PlusOutlined /> Add New
    </Button>,
    <Dropdown overlay={menu} key="export">
      <Button type="link">
        Export <DownOutlined />
      </Button>
    </Dropdown>,
  ];

  const handleSearch = (params) => {
    setSearchParams(params);
  };

  const handleSortChange = (sortField, direction) => {
    setSortParams({
      field: sortField,
      direction,
    });
  };

  return (
    <div className="ant-pro-grid-content rules-content">
      <Layout className="rules-content-main">
        {!isEdit ? (
          <Spin spinning={rulesLoading}>
            {rules?.length > 0 && (
              <div className="products-result-banner">
                <div>
                  Rules: <strong>{rulesStat.total || 0}</strong>
                  &nbsp;( Include: <strong>{rulesStat.include || 0}</strong> /
                  Exclude: <strong>{rulesStat.exclude || 0}</strong> )
                </div>
              </div>
            )}
            <CustomProTable
              className="rules-content-main-table"
              data={rules}
              rowKey="_id"
              columns={columns}
              toolbar={toolbar}
              pagination={{ defaultPageSize: 20 }}
              selectable={false}
              searchable
              setSearchParams={setSearchParams}
              setSortParams={setSortParams}
              handleSearch={handleSearch}
              handleSortChange={handleSortChange}
            />
          </Spin>
        ) : (
          <Layout>
            <Sider width={350} className="side-bar-content">
              <Tabs centered>
                <TabPane tab={'Categories'} key={'categoryTree'}>
                  <Spin spinning={categoriesLoading}>
                    {!categoriesLoading && (
                      <Tree
                        className="categories-content-tree-content"
                        checkable
                        defaultExpandedKeys={[]}
                        defaultSelectedKeys={[]}
                        defaultCheckedKeys={[]}
                        checkedKeys={selectedCategories}
                        treeData={categoryTree}
                        // onSelect={onSelect}
                        onCheck={(item) => {
                          setSelectedCategories(item);
                        }}
                        titleRender={(category) => `${category.title}`}
                      />
                    )}
                  </Spin>
                </TabPane>
                <TabPane tab={'Attributes'} key={'attributeTree'}>
                  <Spin spinning={attributesLoading}>
                    {!attributesLoading && (
                      <Tree
                        className="categories-content-tree-content"
                        checkable
                        defaultExpandedKeys={[]}
                        defaultSelectedKeys={[]}
                        defaultCheckedKeys={[]}
                        checkedKeys={selectedAttributes}
                        treeData={attributeTree}
                        // onSelect={onSelect}
                        onCheck={(item) => {
                          setSelectedAttributes(item);
                        }}
                        titleRender={(attribute) => `${attribute.title}`}
                      />
                    )}
                  </Spin>
                </TabPane>
              </Tabs>
            </Sider>
            <Layout>
              <RuleEdit
                rule={selectedRule}
                client={client}
                handleSaveClick={handleSaveClick}
                handleCancelClick={handleCancelClick}
              />
            </Layout>
          </Layout>
        )}
      </Layout>
      {openConfirmModal && (
        <Modal
          className="rule-delete-modal"
          title="Delete rule"
          visible={openConfirmModal}
          onOk={handleDeleteRuleOk}
          onCancel={handleDeleteRuleCancel}
          width={400}
        >
          <div>Are sure want to delete this rule?</div>
        </Modal>
      )}
    </div>
  );
};

RulesPage.propTypes = {
  client: PropTypes.object,
};

RulesPage.defaultProps = {
  client: null,
};

export default memo(RulesPage);
