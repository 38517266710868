import PropTypes from 'prop-types';
import React, { useState, createContext } from 'react';

const initialValues = {
  selectedType: 'category',
  selectedCategoryId: null,
  selectedAttributeId: null,
  selectedCategory: null,
  selectedAttribute: null,
  attributes: [],
  categories: [],
  associations: [],
};

export const TaxonomyContext = createContext(initialValues);

const TaxonomyProvider = (props) => {
  const { children } = props;

  const [context, setContext] = useState(initialValues);

  return (
    <TaxonomyContext.Provider value={{ context, setContext }}>
      {children}
    </TaxonomyContext.Provider>
  );
};

TaxonomyProvider.propTypes = {
  children: PropTypes.node,
};

TaxonomyProvider.defaultProps = {
  children: undefined,
};

export default TaxonomyProvider;
