import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import EntitySelect from '@marketreach/pages/taxonomy/properties/extra/EntitySelect';

const input = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, relationType } = settings;

  return (
    <>
      <Form.Item
        name={key}
        rules={rules()}
        initialValue={preDefinedVal}
        hidden={hide ?? false}
        hasFeedbacke
      >
        <EntitySelect
          type={settings?.entity}
          disabled={disabled}
          readOnly={readOnly}
          defaultValue={preDefinedVal}
          onChange={onChange}
          multiple={relationType === 'one_to_many'}
          labelField={settings?.entityLabelField}
          labelFieldType={settings?.entityLabelFieldType}
        />
      </Form.Item>
    </>
  );
};

input.propTypes = {
  props: PropTypes.object,
};

input.defaultProps = {
  props: {},
};

export default input;
