import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Spin, Switch, Typography } from 'antd';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { ATTRIBUTES } from '@marketreach/services/apollo/attributes';

import ProductsTable from '../../share/products/ProductsTable';

import './styles.scss';

const { Text } = Typography;

const TaxonomyAttributesPreview = (props) => {
  const { onPreviewChange, previewFilters, rawAttributes } = props;

  const { selected: client } = useClientsState();
  const { context } = useContext(TaxonomyContext);
  const { selectedCategory } = context;

  const [previewAttributes, setPreviewAttributes] = useState([]);
  const [skus, setSkus] = useState([]);

  const { data: previewAttributesInfo, loading: previewAttributesLoading } =
    useQuery(ATTRIBUTES, {
      variables: {
        clientCode: client?.apiId,
        fullMode: true,
        filterParams: {
          _id: {
            $in: rawAttributes
              .map((item) => item._id)
              .filter((item) => {
                if (previewFilters.length > 0) {
                  return previewFilters.indexOf(item) !== -1;
                }
                return true;
              }),
          },
        },
      },
    });

  useEffect(() => {
    if (previewAttributesInfo?.attributes?.data?.length > 0) {
      setPreviewAttributes(previewAttributesInfo.attributes.data);

      const skusObject = previewAttributesInfo.attributes.data.reduce(
        (acc, item) => {
          acc[item.core.rootId] = acc[item.core.rootId] || [];
          acc[item.core.rootId].push(item.skus);
          return acc;
        },
        {}
      );

      const intersectedGroupSkus = Object.values(skusObject).map(
        (skusGroup) => {
          return _.intersection(skusGroup.flat(), selectedCategory.skus);
        }
      );

      const intersectedSkus = _.intersection(...intersectedGroupSkus);

      setSkus(intersectedSkus);
    } else {
      setSkus([]);
    }
  }, [previewAttributesInfo]);

  const [previewMode, setPreviewMode] = useState(true);
  const handleSwitchPreview = (checked) => {
    setPreviewMode(checked);
    onPreviewChange(checked);
  };

  const previewSwitch = (
    <div className="attributes-content-preview-switch">
      <Text className="attributes-content-preview-switch-label">
        Preview mode
      </Text>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        defaultChecked={previewMode}
        onChange={handleSwitchPreview}
      />
    </div>
  );

  return (
    <div className="attributes-content-preview">
      <Spin spinning={previewAttributesLoading}>
        <ProductsTable
          key={previewFilters}
          client={client}
          type="category"
          skuArray={skus}
          previewSwitch={previewSwitch}
          conditionExists={previewFilters.length > 0}
        />
      </Spin>
    </div>
  );
};

TaxonomyAttributesPreview.propTypes = {
  previewFilters: PropTypes.array,
  rawAttributes: PropTypes.array,
  onPreviewChange: PropTypes.func,
};

TaxonomyAttributesPreview.defaultProps = {
  previewFilters: [],
  rawAttributes: [],
  onPreviewChange: () => {},
};

export default memo(TaxonomyAttributesPreview);
