import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Spin } from 'antd';
import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { SINGLE_CLIENT } from '@marketreach/services/apollo/clients';

import RightMenu from './RightMenu';

import './styles.scss';
import {
  ENTITY_TYPE_ATTRIBUTE,
  ENTITY_TYPE_CATEGORY,
} from '@marketreach/utils/common';
const TopBar = () => {
  const { isAuthenticated } = useAuth0();
  const { selected } = useClientsState();

  const { loading, data: clientData } = useQuery(SINGLE_CLIENT, {
    variables: {
      clientCode: selected?.apiId,
    },
    skip: !selected?.apiId,
  });

  const hardcodedLabels = {
    [ENTITY_TYPE_CATEGORY]: {
      label: 'Categories',
    },
    [ENTITY_TYPE_ATTRIBUTE]: {
      label: 'Attributes',
    },
  };

  const client = clientData?.getClient?.data;

  const location = useLocation();

  const getSelectedKey = () => {
    return location.pathname;
  };

  const hardcodedItems = () => {
    return [ENTITY_TYPE_CATEGORY, ENTITY_TYPE_ATTRIBUTE].map((item) => (
      <Menu.Item key={`/entities/${item}`}>
        <Link to={`/entities/${item}`}>
          <span>{hardcodedLabels[item]?.label || item}</span>
        </Link>
      </Menu.Item>
    ));
  };

  const customEntities = () => {
    return client?.entities?.map((item) => (
      <Menu.Item key={`/entities/${item.slug}`}>
        <Link to={`/entities/${item.slug}`}>
          <span>{item.label_navigation || item.label}</span>
        </Link>
      </Menu.Item>
    ));
  };

  return (
    <nav className="menuBar">
      <div className="logo">MarketReach</div>

      <div className="leftMenu">
        <Spin spinning={loading}>
          {isAuthenticated && (
            <Menu mode="horizontal" defaultSelectedKeys={[getSelectedKey()]}>
              <Menu.Item key="/taxonomy">
                <Link to="/taxonomy">
                  <span>Taxonomy</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/products">
                <Link to="/products">
                  <span>Products</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/feeds">
                <Link to="/feeds">
                  <span>Feeds</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/rules">
                <Link to="/rules">
                  <span>Rules</span>
                </Link>
              </Menu.Item>
              {hardcodedItems()}
              {customEntities()}
            </Menu>
          )}
        </Spin>
      </div>

      <div className="menuCon">
        <div className="rightMenu">
          <RightMenu />
        </div>
      </div>
    </nav>
  );
};

export default memo(TopBar);
