import { useMutation, useQuery } from '@apollo/client';
import {
  Modal,
  Form,
  Input,
  message,
  Spin,
  Checkbox,
  Select,
  Button,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState, useContext } from 'react';

import AddRulesPopup from '@marketreach/components/rule/AddRulesPopup';
import ModalAutoGenerationFilter from '@marketreach/pages/taxonomy/sidebar/ModalAutoGenerationFilter';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import {
  CREATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  ATTRIBUTES_QUERY_NAME,
  BULK_CREATE_ATTRIBUTE,
  ATTRIBUTE_QUERY_NAME,
  ATTRIBUTES,
} from '@marketreach/services/apollo/attributes';
import { CATEGORY_QUERY_NAME } from '@marketreach/services/apollo/categories';
import { ENTITY_DATA_QUERY } from '@marketreach/services/apollo/entity';
import {
  CREATE_HISTORY,
  HISTORIES_BY_PAGE_QUERY_NAME,
  HISTORIES_QUERY_NAME,
} from '@marketreach/services/apollo/history';
import { GET_PRODUCT_KEYS } from '@marketreach/services/apollo/products';

import './styles.scss';

const { Option } = Select;
const { Text } = Typography;

const SidebarAttributeModal = (props) => {
  const {
    title,
    mode,
    attributeId,
    rootId,
    open,
    handleOk,
    handleCancel,
    childs,
  } = props;

  const { selected: client } = useClientsState();

  const { context } = useContext(TaxonomyContext);
  const { attributes } = context;

  const { data: baseFieldsData } = useQuery(GET_PRODUCT_KEYS, {
    variables: {
      clientCode: client.apiId,
    },
  });
  const [addAttribute] = useMutation(CREATE_ATTRIBUTE);
  const [massAddAttribute] = useMutation(BULK_CREATE_ATTRIBUTE);
  const [updateAttribute] = useMutation(UPDATE_ATTRIBUTE);
  const [deleteAttribute] = useMutation(DELETE_ATTRIBUTE);
  const [createHistory] = useMutation(CREATE_HISTORY);
  const [form] = Form.useForm();

  const [attributesInProcess, setAttributesInProcess] = useState(false);
  const [isAutoGenerated, setIsAutoGenerated] = useState(false);
  const [isTokenized, setIsTokenized] = useState(false);
  const [productField, setProductField] = useState([]);
  const [showRulesPopup, setShowRulesPopup] = useState(false);
  const [linkedRules, setLinkedRules] = useState([]);

  const initAutoGenerationState = {
    wordMatch: 'whole',
    caseSensitive: false,
    basis: 'include',
    key: 'name',
    match: 'any',
  };

  const [autoGenerationState, setAutoGenerationState] = useState(
    initAutoGenerationState
  );

  const attribute = attributes.find((a) => a._id === attributeId);

  const input = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (baseFieldsData?.getProductKeys?.data) {
      setProductField(baseFieldsData?.getProductKeys?.data);
    }
  }, [baseFieldsData]);

  useEffect(() => {
    if (open && input.current) {
      setTimeout(() => {
        input.current.focus();
      });
    }
  }, [open, input]);

  useEffect(() => {
    if (attribute && mode === 'edit') {
      form.setFieldsValue({
        name: attribute.name,
      });
    } else {
      form.setFieldsValue({
        name: '',
        names: '',
        autoGenerated: null,
        isTokenized: false,
        productField: null,
      });
      setIsAutoGenerated(false);
      setIsTokenized(false);
    }
  }, [attribute, form, mode]);

  const handleAutoGenerationChange = (param, value) => {
    setAutoGenerationState({
      ...autoGenerationState,
      [param]: value,
    });
  };

  const onOk = async () => {
    const formValue = form.getFieldsValue();

    if (
      !isAutoGenerated &&
      !formValue?.name &&
      mode !== 'delete' &&
      mode !== 'bulk_create' &&
      mode !== 'root'
    ) {
      message.error('Please input attribute name');

      return;
    }

    if (
      !isAutoGenerated &&
      !formValue?.names &&
      (mode === 'bulk_create' || mode === 'root')
    ) {
      message.error('Please input categories names');

      return;
    }

    if (!isAutoGenerated && !formValue?.names && mode === 'bulk_create') {
      message.error('Please input attributes names');

      return;
    }

    if (
      isAutoGenerated &&
      (!formValue?.productField || (!formValue?.names && !attributeId)) &&
      (mode === 'bulk_create' || mode === 'root')
    ) {
      message.error('Please select base field and enter name');
      return;
    }

    if ((!attributeId || mode === 'create') && mode !== 'root') {
      setAttributesInProcess(true);
      await addAttribute({
        variables: {
          ...formValue,
          ...autoGenerationState,
          type: 'attribute',
          layout: 'attribute',
          clientCode: client?.apiId,
          parentId: attributeId,
          rootId,
          isAutoGenerated,
          isTokenized,
        },
        refetchQueries: [
          ATTRIBUTES_QUERY_NAME,
          ATTRIBUTES,
          ENTITY_DATA_QUERY,
          CATEGORY_QUERY_NAME,
        ],
        awaitRefetchQueries: true,
      });

      await createHistory({
        variables: {
          clientCode: client?.apiId,
          action: attribute
            ? `Atribute ${formValue?.name} was added to the ${attribute?.name} group`
            : `Atribute group ${formValue?.name} was added`,
          account: 'Admin',
          ipAddress: '127.0.0.1',
          type: 'attribute',
          parentId: attribute?._id,
        },
        refetchQueries: [HISTORIES_QUERY_NAME, HISTORIES_BY_PAGE_QUERY_NAME],
        awaitRefetchQueries: true,
      });

      form.setFieldsValue({
        names: '',
        name: '',
        autoGenerated: null,
        productField: null,
      });

      setAttributesInProcess(false);
      setIsAutoGenerated(false);
      setIsTokenized(false);
      handleOk(attribute?._id);
      setAutoGenerationState(initAutoGenerationState);
    }

    if (
      ((attributeId || mode === 'bulk_create') &&
        mode !== 'delete' &&
        mode !== 'edit') ||
      (!attributeId && mode === 'root')
    ) {
      setAttributesInProcess(true);
      await massAddAttribute({
        variables: {
          ...formValue,
          ...autoGenerationState,
          type: 'attribute',
          layout: 'attribute',
          clientCode: client?.apiId,
          parentId: attributeId,
          rootId,
          isAutoGenerated,
          isTokenized,
          linkedRules,
        },
        refetchQueries: [ATTRIBUTES_QUERY_NAME],
        awaitRefetchQueries: true,
      });

      await createHistory({
        variables: {
          clientCode: client?.apiId,
          action: attribute
            ? `Atribute ${formValue?.name} was added to the ${attribute?.name} group`
            : `Atribute group ${formValue?.name} was added`,
          account: 'Admin',
          ipAddress: '127.0.0.1',
          type: 'attribute',
          parentId: attribute?._id,
        },
        refetchQueries: [HISTORIES_QUERY_NAME, HISTORIES_BY_PAGE_QUERY_NAME],
        awaitRefetchQueries: true,
      });
      form.setFieldsValue({
        names: '',
        name: '',
        autoGenerated: null,
        productField: null,
      });
      setAttributesInProcess(false);
      setIsAutoGenerated(false);
      setIsTokenized(false);
      handleOk(attribute?._id);
    }

    if (attributeId && mode === 'edit') {
      setAttributesInProcess(true);
      await updateAttribute({
        variables: {
          ...formValue,
          type: 'attribute',
          layout: 'attribute',
          clientCode: client?.apiId,
          _id: attributeId,
        },
        refetchQueries: [
          ATTRIBUTES_QUERY_NAME,
          ATTRIBUTE_QUERY_NAME,
          ENTITY_DATA_QUERY,
          CATEGORY_QUERY_NAME,
        ],
        awaitRefetchQueries: true,
      });

      await createHistory({
        variables: {
          clientCode: client?.apiId,
          action: `Attribute name ${attribute?.name} was changed to ${formValue?.name}`,
          account: 'Admin',
          ipAddress: '127.0.0.1',
          type: 'attribute',
          parentId: attribute?._id,
        },
        refetchQueries: [HISTORIES_QUERY_NAME, HISTORIES_BY_PAGE_QUERY_NAME],
        awaitRefetchQueries: true,
      });
      setAttributesInProcess(false);
      handleOk(attribute?._id);
    }

    if (attributeId && mode === 'delete') {
      if (attribute) {
        setAttributesInProcess(true);
        await deleteAttribute({
          variables: {
            clientCode: client?.apiId,
            _id: attributeId,
            associations: attribute.associations,
          },
          refetchQueries: [
            ATTRIBUTES_QUERY_NAME,
            ATTRIBUTE_QUERY_NAME,
            ENTITY_DATA_QUERY,
            CATEGORY_QUERY_NAME,
          ],
          awaitRefetchQueries: true,
        });

        await createHistory({
          variables: {
            clientCode: client?.apiId,
            action: `Attribute ${attribute?.name} was deleted`,
            account: 'Admin',
            ipAddress: '127.0.0.1',
            type: 'attribute',
            parentId: attribute?._id,
          },
          refetchQueries: [HISTORIES_QUERY_NAME, HISTORIES_BY_PAGE_QUERY_NAME],
          awaitRefetchQueries: true,
        });
        setAttributesInProcess(false);
        handleOk();
      }
    }
  };

  const onCancel = () => {
    form.setFieldsValue({
      name: '',
      names: '',
      autoGenerated: null,
      productField: null,
    });
    setIsAutoGenerated(false);
    setIsTokenized(false);
    setAutoGenerationState(initAutoGenerationState);
    setLinkedRules([]);
    handleCancel();
  };

  const handleKeyUp = (event) => {
    if (mode !== 'bulk_create' && mode !== 'root') {
      if (event.keyCode === 13) {
        onOk();
      }
    }
  };

  const handleAddRulesPopupOk = (rulesIds) => {
    setLinkedRules(rulesIds);
    setShowRulesPopup(false);
  };

  const mainInputLabel = () => {
    if (isAutoGenerated && !attributeId) {
      return 'Root category name';
    }

    return 'Name';
  };

  return (
    <Modal
      className="taxonomy-sidebar-category-modal"
      title={title}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      width={400}
    >
      <Spin spinning={attributesInProcess}>
        <Form form={form} labelCol={{ span: 4 }} onKeyUp={handleKeyUp}>
          {mode === 'delete' && (
            <>
              <div>Are you sure want to delete this attribute?</div>
              {childs.length > 0 && (
                <>
                  <br />
                  <Text type="danger">
                    <b>
                      Warning: This will also delete {childs?.length}{' '}
                      {childs?.length === 1
                        ? 'child attribute'
                        : 'child attributes'}
                    </b>
                  </Text>
                </>
              )}
            </>
          )}
          {(mode === 'create' || mode === 'edit') && (
            <>
              <Form.Item label="Name" name="name" required>
                <Input ref={input} placeholder="input name" autoFocus />
              </Form.Item>
            </>
          )}
          {(mode === 'bulk_create' || mode === 'root') && (
            <>
              <Form.Item
                label={mainInputLabel()}
                name="names"
                required
                className={'flex-name'}
              >
                <TextArea
                  ref={input}
                  placeholder="input names"
                  autoFocus
                  tabIndex={0}
                  autoSize={{ minRows: 1 }}
                />
              </Form.Item>

              <div>
                <Form.Item label="" name="autoGenerated">
                  <Checkbox
                    checked={isAutoGenerated}
                    onChange={() => setIsAutoGenerated(!isAutoGenerated)}
                  >
                    Auto Generated
                  </Checkbox>
                </Form.Item>
              </div>

              {isAutoGenerated && (
                <div>
                  <Form.Item label="" name="tokenized">
                    <Checkbox
                      checked={isTokenized}
                      onChange={() => setIsTokenized(!isTokenized)}
                    >
                      Tokenized rule
                    </Checkbox>
                  </Form.Item>
                  <Form.Item label="" name="productField" required>
                    <Select
                      required
                      showSearch
                      placeholder="Select a base field"
                      optionFilterProp="children"
                      filterOption={(inputProductField, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(inputProductField.toLowerCase()) >= 0
                      }
                    >
                      {_.sortBy(productField, ['key']).map((item) => (
                        <Option value={item.key}>{item.key}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <ModalAutoGenerationFilter
                    autoGenerationState={autoGenerationState}
                    handleAutoGenerationChange={handleAutoGenerationChange}
                  />
                  <Button
                    type={'primary'}
                    onClick={() => setShowRulesPopup(true)}
                  >
                    {linkedRules.length === 0
                      ? 'Add more rules'
                      : `Update rules linking (${linkedRules.length})`}
                  </Button>
                  {showRulesPopup && (
                    <AddRulesPopup
                      visible={showRulesPopup}
                      onOk={handleAddRulesPopupOk}
                      linkedRulesIds={linkedRules}
                      onCancel={() => setShowRulesPopup(false)}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

SidebarAttributeModal.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.string,
  attributeId: PropTypes.string,
  rootId: PropTypes.string,
  open: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  childs: PropTypes.arrayOf(PropTypes.any),
};

SidebarAttributeModal.defaultProps = {
  title: '',
  mode: '',
  attributeId: null,
  rootId: null,
  open: false,
  handleOk: () => {},
  handleCancel: () => {},
  childs: [],
};

export default memo(SidebarAttributeModal);
