import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PRODUCTS_BY_PAGE = gql`
  query GetProductsByPage(
    $clientCode: String!
    $filterParams: JSON
    $pageNumber: Int!
    $pageSize: Int!
    $rule: JSON
    $rules: [JSON]
    $productIds: [ID]
    $groupByProduct: Boolean
    $orderField: String
    $orderDirection: String
    $getAll: Boolean
  ) {
    getProductsByPage(
      clientCode: $clientCode
      filterParams: $filterParams
      pageNumber: $pageNumber
      pageSize: $pageSize
      rule: $rule
      rules: $rules
      productIds: $productIds
      groupByProduct: $groupByProduct
      orderField: $orderField
      orderDirection: $orderDirection
      getAll: $getAll
    ) {
      pageNumber
      totalCount
      pageSize
      productIds
      skus
      data
    }
  }
`;

export const GET_PRODUCT_BY_PAGE_QUERY = 'GetProductsByPage';

export const ALL_CRITERIA_BY_KEY = gql`
  query AllCriteriaByKey($clientCode: String!, $key: String!) {
    allCriteriaByKey(clientCode: $clientCode, key: $key) {
      key
      count
      values
    }
  }
`;

export const REFRESH_PRODUCTS_SCHEMA = gql`
  mutation RebuildProductSchema($clientCode: String!) {
    rebuildProductSchema(clientCode: $clientCode) {
      data
    }
  }
`;

export const REFRESH_PRODUCTS_SCHEMA_FOR_ALL_CLIENTS = gql`
  mutation RebuildProductSchemaForAllClients {
    rebuildProductSchemaForAllClients {
      data
    }
  }
`;

export const GET_PRODUCT_KEYS = gql`
  query GetProductKeys($clientCode: String!, $full: Boolean) {
    getProductKeys(clientCode: $clientCode, full: $full) {
      data
    }
  }
`;

export const GET_PRODUCT_KEYS_QUERY = 'GetProductKeys';

export const GET_PRODUCT_KEY_VALUES = gql`
  query GetProductKeyValues($clientCode: String!, $key: String!) {
    getProductKeyValues(clientCode: $clientCode, key: $key) {
      data
    }
  }
`;

export const GET_PRODUCT_KEY_VALUES_QUERY = 'GetProductKeyValues';
