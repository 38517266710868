import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Spin } from 'antd';
import Sider from 'antd/es/layout/Sider';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Tree from 'react-virtualized-tree';
import Renderers from 'react-virtualized-tree/es/renderers';

import { flattenNodes, treeData } from '@marketreach/utils/common';

import 'react-virtualized/styles.css';
import 'react-virtualized-tree/lib/main.css';
import 'material-icons/css/material-icons.css';

import '../styles.scss';

const NodeNameRenderer = ({ node: { name }, children }) => (
  <>
    {name}
    {children}
  </>
);

const { Deletable, Expandable, Favorite } = Renderers;

/**
 * Display custom entity tree inside sider
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const TreeSider = (props) => {
  const {
    collapsed,
    handleToggleClick,
    onClickSubmenu,
    type,
    loading,
    selectedEntityId,
    expandedKeys,
    onExpand,
    onSelect,
    entityDataList,
    getApiIdField,
    menu,
    allowAdd,
  } = props;

  const [state, setState] = useState({
    nodes: [],
    availableRenderers: [Expandable, Deletable, Favorite],
    selectedRenderers: [Expandable, NodeNameRenderer],
  });

  useEffect(() => {
    const treeDataValue = treeData(
      entityDataList,
      getApiIdField(),
      menu,
      expandedKeys,
      []
    );

    setState((prevState) => ({
      ...prevState,
      nodes: treeDataValue,
    }));
  }, [entityDataList, expandedKeys]);

  /**
   * Process tree state change
   * @param nodes
   */
  const handleChange = (nodes) => {
    setState((prevState) => ({ ...prevState, nodes }));
    const expandedKeysList = flattenNodes(nodes)
      .filter((item) => item.state.expanded === true)
      .map((item) => item.id);

    onExpand(expandedKeysList);
  };

  return (
    <Sider
      width={350}
      collapsedWidth={15}
      collapsible
      collapsed={collapsed}
      onCollapse={handleToggleClick}
      className="side-bar-content"
    >
      {collapsed ? (
        <div className="collapsed-sider-content" />
      ) : (
        <div className="taxonomy-sidebar-tree-content">
          {allowAdd && (
            <Button
              className="categories-tree-add-btn"
              type="dashed"
              onClick={() => onClickSubmenu('root_create')}
            >
              <PlusOutlined /> Add root {type}
            </Button>
          )}
          <Spin spinning={loading}>
            <div className="virtual-tree-wrapper">
              <Tree nodes={state.nodes} onChange={handleChange}>
                {({ style, node, ...rest }) => {
                  return (
                    <div style={style}>
                      <Expandable
                        node={node}
                        {...rest}
                        iconsClassNameMap={{
                          collapsed: 'mi mi-keyboard-arrow-right',
                          expanded: 'mi mi-keyboard-arrow-down',
                          lastChild: 'mi mi-insert-drive-file',
                        }}
                      >
                        <Dropdown overlay={menu(node.id)} trigger="contextMenu">
                          <Button
                            type="text"
                            onClick={() => onSelect(node.id)}
                            style={{
                              padding: 0,
                              transform: 'translateY(-7px)',
                            }}
                            className={
                              [selectedEntityId].includes(node.id)
                                ? 'selected'
                                : ''
                            }
                          >
                            {node.name}
                          </Button>
                        </Dropdown>
                      </Expandable>
                    </div>
                  );
                }}
              </Tree>
            </div>
          </Spin>
        </div>
      )}
    </Sider>
  );
};

TreeSider.propTypes = {
  collapsed: PropTypes.bool,
  handleToggleClick: PropTypes.func,
  onClickSubmenu: PropTypes.func,
  type: PropTypes.string,
  loading: PropTypes.bool,
  selectedEntityId: PropTypes.number,
  expandedKeys: PropTypes.arrayOf(PropTypes.any),
  onExpand: PropTypes.func,
  onSelect: PropTypes.func,
  entityDataList: PropTypes.arrayOf(PropTypes.any),
  getApiIdField: PropTypes.func,
  menu: PropTypes.any,
  allowAdd: PropTypes.bool,
};

TreeSider.defaultProps = {
  collapsed: false,
  handleToggleClick: () => null,
  onClickSubmenu: () => null,
  type: null,
  loading: false,
  selectedEntityId: null,
  expandedKeys: [],
  onExpand: () => null,
  onSelect: () => null,
  entityDataList: [],
  getApiIdField: () => null,
  menu: null,
  allowAdd: true,
};

export default TreeSider;
