import { useMutation } from '@apollo/client';
import { message, Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import RuleForm from '@marketreach/pages/rules/components/rule-form/RuleForm';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { CREATE_RULE, RULES_QUERY } from '@marketreach/services/apollo/rules';

/**
 * Popup to display rule edit/create form
 *
 * @param props.visible - flag to display popup
 * @param props.onOk - on button `ok` press handler
 * @param props.onCancel - on button `cancel` press handler
 * @return {JSX.Element}
 * @constructor
 */
const RuleFormPopup = (props) => {
  const { visible, onOk, onCancel } = props;
  const { selected: client } = useClientsState();

  // Queries
  const [createRule] = useMutation(CREATE_RULE);

  // Handlers
  const handleSaveClick = (values) => {
    createRule({
      variables: {
        ...values,
        clientCode: client?.apiId,
      },
      refetchQueries: [RULES_QUERY],
    })
      .then((response) => {
        message.info('Rule created successfully');
        onOk(response?.data?.createRule?.data || []);
      })
      .catch(() => {
        message.error('Error on saving rule ');
      });
  };

  return (
    <Modal title="Rule" centered visible={visible} width={'80%'} footer={null}>
      <RuleForm
        client={client}
        handleSaveClick={handleSaveClick}
        handleCancelClick={onCancel}
      />
    </Modal>
  );
};

RuleFormPopup.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

RuleFormPopup.defaultProps = {
  visible: false,
  onOk: () => null,
  onCancel: () => null,
};

export default RuleFormPopup;
