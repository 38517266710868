import PropTypes from 'prop-types';
import React, { memo } from 'react';
import MonacoEditor from 'react-monaco-editor';

import './styles.scss';

const MonacoEdit = (props) => {
  const { defaultValue } = props;

  const options = {
    selectOnLineNumbers: true,
  };

  return (
    <MonacoEditor
      width="100%"
      height="600"
      language="javascript"
      theme="vs-dark"
      options={options}
      defaultValue={defaultValue}
      onChange={(data) => props.onChange(data)}
    />
  );
};

MonacoEdit.propTypes = {
  defaultValue: PropTypes.any,
};

MonacoEdit.defaultProps = {
  defaultValue: null,
};

export default memo(MonacoEdit);
