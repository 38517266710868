export const AUTH_TOKEN = 'auth_token';

const getLocalStorage = (item) => {
  const stateStr = localStorage.getItem(item);

  if (stateStr) {
    return JSON.parse(stateStr);
  }

  return {
    attributeId: null,
    categoryId: null,
  };
};

export const setTaxonomyTreeState = (categoryId, attributeId) => {
  const stateStr = JSON.stringify({
    categoryId,
    attributeId,
  });
  localStorage.setItem('taxonomy_tree', stateStr);
};

export const getTaxonomyTreeState = () => {
  return getLocalStorage('taxonomy_tree');
};

export const setTaxonomyTreeExpandedState = (expandedKeys) => {
  const stateStr = JSON.stringify({
    expandedKeys,
  });
  localStorage.setItem('taxonomy_tree_expanded_keys', stateStr);
};

export const getTaxonomyTreeExpandedState = () => {
  return getLocalStorage('taxonomy_tree_expanded_keys');
};
