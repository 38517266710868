import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Typography } from 'antd';
import React, { memo, useState, useContext } from 'react';

import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';

import TaxonomyAttributesPreview from './AttributesPreview';
import TaxonomyAttributesTree from './AttributesTree';
import './styles.scss';

const { Text } = Typography;

const TaxonomyAttributes = () => {
  const { context } = useContext(TaxonomyContext);
  const { attributes } = context;

  const [previewMode, setPreviewMode] = useState(false);
  const [previewFilters, setPreviewFilters] = useState([]);

  return (
    <div className="ant-pro-grid-content attributes-content">
      <div className="attributes-content-tree-container">
        <TaxonomyAttributesTree
          attributes={attributes}
          preview={previewMode}
          onPreviewAttributeChange={setPreviewFilters}
        />
      </div>
      <div className="attributes-content-preview-container">
        <div className="attributes-content-preview">
          {!previewMode && (
            <div className="attributes-content-preview-switch">
              <Text className="attributes-content-preview-switch-label">
                Preview mode
              </Text>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={previewMode}
                onChange={setPreviewMode}
              />
            </div>
          )}
          {previewMode && (
            <TaxonomyAttributesPreview
              onPreviewChange={setPreviewMode}
              previewFilters={previewFilters}
              rawAttributes={attributes}
            />
          )}
        </div>
      </div>
    </div>
  );
};

TaxonomyAttributes.propTypes = {};

TaxonomyAttributes.defaultProps = {};

export default memo(TaxonomyAttributes);
