import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { SimpleImg } from 'react-simple-img';

import './styles.scss';

const CustomGallery = (props) => {
  const {
    selected,
    className,
    imageWidth,
    imageHeight,
    images,
    handleClickImage,
  } = props;

  // Handle click image
  const onClickImage = (key) => {
    handleClickImage(key);
  };

  return (
    <div className={classnames('custom-gallery', className)}>
      {images.map((image) => (
        <div key={image.key} onClick={() => onClickImage(image.key)}>
          <SimpleImg
            className={classnames(
              'custom-gallery-image',
              selected &&
                selected === image.key &&
                'custom-gallery-image-active'
            )}
            alt={image.key}
            imgStyle={{ padding: 5, objectFit: 'contain' }}
            width={imageWidth}
            height={imageHeight}
            placeholder="../../assets/logo.png"
            src={image.url}
          />
        </div>
      ))}
    </div>
  );
};

CustomGallery.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.any,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  images: PropTypes.array,
  handleClickImage: PropTypes.func,
};

CustomGallery.defaultProps = {
  className: '',
  selected: null,
  imageWidth: '200px',
  imageHeight: '200px',
  images: [],
  handleClickImage: () => {},
};

export default CustomGallery;
