import { useMutation } from '@apollo/client';
import { Button, Divider, Form, message, Spin } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropertiesManager from '@marketreach/pages/taxonomy/properties/PropertiesManager';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import {
  ATTRIBUTE_QUERY_NAME,
  ATTRIBUTES_QUERY_NAME,
  UPDATE_ATTRIBUTE,
} from '@marketreach/services/apollo/attributes';
import {
  CATEGORIES_QUERY_NAME,
  CATEGORY_QUERY_NAME,
  UPDATE_CATEGORY,
} from '@marketreach/services/apollo/categories';
import {
  ADD_SECTION,
  GET_SINGLE_CLIENT_QUERY,
  UPDATE_CLIENTS,
} from '@marketreach/services/apollo/clients';
import { CREATE_HISTORY } from '@marketreach/services/apollo/history';
import { capitalize, sortByOrder } from '@marketreach/utils/common';

import PropertyItem from './PropertyItem';

import './styles.scss';

const TaxonomyProperties = () => {
  const { selected: client } = useClientsState();
  const { context } = useContext(TaxonomyContext);
  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );

  const {
    selectedType: type,
    categories,
    attributes,
    selectedCategory,
    selectedAttribute,
  } = context;

  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const [updateAttribute] = useMutation(UPDATE_ATTRIBUTE);
  const [updateClients, { loading: loadSaveClients }] =
    useMutation(UPDATE_CLIENTS);
  const [addSection, { loading: loadAddSection }] = useMutation(ADD_SECTION);

  const [createHistory] = useMutation(CREATE_HISTORY);

  const [form] = Form.useForm();

  const formWasChanged = useRef(false);

  useEffect(() => {
    const handleClick = async (event) => {
      if (formWasChanged.current) {
        if (
          // eslint-disable-next-line no-restricted-globals
          !confirm('The form contains unsaved changes. Do you want to lose it?')
        ) {
          event.stopPropagation();
        }
      }
    };

    document
      .querySelector(
        '.ant-layout-sider-children, .leftMenu, .ant-tabs-nav-list'
      )
      ?.addEventListener('click', handleClick);

    return () => {
      document
        .querySelector(
          '.ant-layout-sider-children, .leftMenu, .ant-tabs-nav-list'
        )
        ?.removeEventListener('click', handleClick);
    };
  }, [document]);

  // const [propertiesActions, setPropertiesActions] = useState([]);
  // TODO refactor for rollout actions
  const propertiesActions = [];

  const sections = (client ? client?.sections[type] || [] : [])
    .slice()
    .sort(sortByOrder);
  const [openManage, setOpenManage] = useState(false);
  const handleShowDrawer = (show) => {
    setPropertyManager({
      ...propertyManager,
      type,
    });
    setOpenManage(show);
  };

  form.resetFields();
  form.setFieldsValue(
    type === 'category'
      ? selectedCategory?.properties ?? {}
      : selectedAttribute?.properties ?? {}
  );

  const usedValues = useMemo(() => {
    return (type === 'category' ? categories : attributes).reduce(
      (acc, item) => {
        const checkObject =
          type === 'category' ? selectedCategory : selectedAttribute;
        if (
          item &&
          checkObject &&
          item?.properties &&
          item._id !== checkObject._id
        ) {
          Object.keys(item.properties).forEach((key) => {
            acc[key] = acc[key] ?? [];
            if (item.properties[key] !== null) {
              acc[key].push(item.properties[key]);
            }
          });
        }
        return acc;
      },
      {}
    );
  }, [type, categories, attributes, selectedCategory, selectedAttribute]);

  const saveSections = (newSections) => {
    const clientSections = JSON.parse(JSON.stringify(client.sections));

    clientSections[type] = newSections;
    const newClient = {
      ...client,
      sections: clientSections,
    };

    updateClients({
      variables: { clients: [newClient], propertiesActions },
      refetchQueries: [
        GET_SINGLE_CLIENT_QUERY,
        ATTRIBUTE_QUERY_NAME,
        CATEGORY_QUERY_NAME,
      ],
      awaitRefetchQueries: true,
    })
      .then(() => {
        message.info('Updated successfully');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddProperty = (property) => {
    const sectionOrder = property.section.order;
    const sectionIndex = sections.findIndex(
      (section) => section.order === sectionOrder
    );

    const newSections = JSON.parse(JSON.stringify(sections));
    const newProperty = {
      ...property,
      order: newSections[sectionIndex].properties.length,
    };
    newSections[sectionIndex].properties = [
      ...newSections[sectionIndex].properties,
      newProperty,
    ];

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Property ${
          property?.settings?.label
        } was added in ${capitalize(type)}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type,
      },
    });

    saveSections(newSections);
  };

  useEffect(() => {
    if (propertyManager.toAdd && propertyManager.needAdd) {
      handleAddProperty(propertyManager.toAdd);
      setPropertyManager({
        ...propertyManager,
        needAdd: false,
        toAdd: null,
      });
    }
  }, [propertyManager.toAdd, propertyManager.needAdd]);

  const handleDeleteProperty = (sectionOrder, propertyOrder) => {
    const sectionIndex = sections.findIndex(
      (section) => section.order === sectionOrder
    );

    const newSections = JSON.parse(JSON.stringify(sections));
    const oldProperties = newSections[sectionIndex].properties;
    const oldProperty = oldProperties.find(
      (property) => property.order === propertyOrder
    );

    propertiesActions.push({
      action: 'delete',
      api_id: oldProperty?.settings?.key,
      type,
    });

    newSections[sectionIndex].properties = oldProperties
      .filter((property) => property.order !== propertyOrder)
      .sort(sortByOrder)
      .map((property, index) => ({
        ...property,
        order: index,
      }));

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Property ${
          oldProperty?.settings?.label
        } was deleted from ${capitalize(type)}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type,
      },
    });

    saveSections(newSections);

    setPropertyManager({
      ...propertyManager,
      toDelete: null,
    });
  };

  useEffect(() => {
    if (propertyManager.toDelete) {
      handleDeleteProperty(
        propertyManager.toDelete.sectionOrder,
        propertyManager.toDelete.propertyOrder
      );
    }
  }, [propertyManager.toDelete]);

  const getPropertyByKey = (key) => {
    for (const section of sections) {
      for (const property of section?.properties) {
        if (property?.settings?.key === key) {
          return property;
        }
      }
    }
  };

  const handleEditProperty = (newProperty) => {
    const sectionIndex = sections.findIndex(
      (section) => section.key === newProperty?.section?.key
    );

    const newSections = JSON.parse(JSON.stringify(sections));
    const newProperties = JSON.parse(
      JSON.stringify(newSections[sectionIndex].properties)
    );

    let oldProperty = getPropertyByKey(newProperty?.settings?.key);
    if (!oldProperty) {
      // Key was changed - try to get oldProperty by index
      if (
        sections[sectionIndex] &&
        sections[sectionIndex].properties[newProperty?.order]
      ) {
        oldProperty = sections[sectionIndex].properties[newProperty?.order];
      }
    }

    if (
      oldProperty &&
      oldProperty?.settings?.key !== newProperty?.settings?.key
    ) {
      propertiesActions.push({
        action: 'update',
        type,
        old_api_id: oldProperty?.settings?.key,
        new_api_id: newProperty?.settings?.key,
      });
    }

    if (
      oldProperty &&
      oldProperty?.section?.key !== newProperty?.section?.key
    ) {
      // section was changed - remove property from from previous section and add to new section
      const oldSectionIndex = sections.findIndex(
        (section) => section.key === oldProperty?.section?.key
      );

      const oldSectionProperties = sections[oldSectionIndex]?.properties || [];

      const propertyIndex = oldSectionProperties.findIndex(
        (property) => property.settings.key === oldProperty?.settings.key
      );
      newSections[oldSectionIndex].properties.splice(propertyIndex, 1);

      newProperty.order = newProperties.length;
      newProperties.push(newProperty);
    } else {
      let propertyIndex;
      if (oldProperty) {
        propertyIndex = newProperties.findIndex(
          (property) => property.settings.key === oldProperty?.settings?.key
        );
      } else {
        propertyIndex = newProperties.findIndex(
          (property) => property.order === oldProperty?.order
        );
      }

      newProperties[propertyIndex] = newProperty;
    }
    newSections[sectionIndex].properties = newProperties;

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Property ${
          oldProperty?.settings?.label
        } was edited in ${capitalize(type)}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type,
      },
    });

    console.log('final sections: ', newSections);

    saveSections(newSections);
  };

  useEffect(() => {
    if (propertyManager.toEdit && propertyManager.needSave) {
      handleEditProperty(propertyManager.toEdit);
      setPropertyManager({
        ...propertyManager,
        needSave: false,
        toEdit: null,
      });
    }
  }, [propertyManager.toEdit, propertyManager.needSave]);

  const [showProperties, setShowProperties] = useState(false);
  useEffect(() => {
    const val =
      type === 'category'
        ? !!context.selectedCategory
        : !!context.selectedAttribute;
    setShowProperties(val);
  }, [context.selectedCategory, context.selectedAttribute]);

  const getTitle = () => {
    if (!showProperties) {
      return type === 'category'
        ? 'No category selected'
        : 'No attribute selected';
    }
    return type === 'category'
      ? selectedCategory?.name
      : selectedAttribute?.name;
  };

  const [newProperties, setNewProperties] = useState(null);
  useEffect(() => {
    setNewProperties(null);
  }, [selectedAttribute, selectedCategory]);

  const handleChangeProperty = (sectionName, propertyKey, value) => {
    const oldEntity =
      type === 'category'
        ? JSON.parse(JSON.stringify(selectedCategory))
        : JSON.parse(JSON.stringify(selectedAttribute));
    const oldProperty = newProperties || oldEntity.properties || {};
    const newSection = oldProperty[sectionName] || {};

    const newProperty = {
      ...(newProperties || oldProperty),
      [sectionName]: {
        ...newSection,
        [propertyKey]: value,
      },
    };

    setNewProperties(newProperty);
  };

  const getDefaultValue = async (key: any) => {
    for (const section of sections) {
      const property = _.find(
        section.properties,
        (prop) => prop.settings.key === key
      );
      if (property) {
        return property?.settings?.defaultValue;
      }
    }
  };

  const filterValues = async (values: any) => {
    const retValue = {};
    for (const key of Object.keys(values)) {
      const val = values[key];
      retValue[key] = val;
      if (val === undefined || val === '' || val === null) {
        const defaultValue = await getDefaultValue(key);
        if (defaultValue) {
          retValue[key] = defaultValue;
        }
      }
    }

    return retValue;
  };

  const onFinish = async (values: any) => {
    values = await filterValues(values);
    if (type === 'category') {
      try {
        await updateCategory({
          variables: {
            properties: values,
            clientCode: client?.apiId,
            _id: selectedCategory._id,
          },
          refetchQueries: [CATEGORY_QUERY_NAME, CATEGORIES_QUERY_NAME],
          awaitRefetchQueries: true,
        });
        message.info('Updated successfully');
      } catch (e) {
        console.log('errror: ', e);
        console.error('errror: ', e);
      }
    } else {
      updateAttribute({
        variables: {
          properties: values,
          clientCode: client?.apiId,
          _id: selectedAttribute._id,
        },
        refetchQueries: [ATTRIBUTE_QUERY_NAME, ATTRIBUTES_QUERY_NAME],
      })
        .then(() => {
          message.info('Updated successfully');
        })
        .catch((e) => {
          console.log(e);
        });
    }

    await createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Saved property values in ${capitalize(type)}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type,
      },
    });
    formWasChanged.current = false;
  };

  const onChange = async (changes) => {
    if (changes === true) {
      formWasChanged.current = true;
    }
  };

  const handleShow = (value) => {
    setOpenManage(value);
  };

  return (
    <div className="ant-pro-grid-content properties-content">
      <div className="properties-content-title">{getTitle()}</div>
      {showProperties && (
        <Spin spinning={loadAddSection || loadSaveClients}>
          <Form
            layout={'vertical'}
            form={form}
            onFinish={onFinish}
            scrollToFirstError
          >
            {openManage && (
              <PropertiesManager
                visible={openManage}
                handleShow={handleShow}
                entityType={type}
              />
            )}
            <div className="properties-add-actions">
              <Form.Item>
                <Button
                  className="properties-add-actions-button"
                  type="primary"
                  htmlType="submit"
                  loading={false}
                  // onClick={handleSaveProperties}
                >
                  Save
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="properties-add-actions-button"
                  type="link"
                  onClick={() => handleShowDrawer(true)}
                >
                  Manage properties
                </Button>
              </Form.Item>
            </div>
            {(selectedCategory || selectedAttribute) &&
              sections.length > 0 &&
              sections.map((section, index) => (
                <div
                  key={`${
                    selectedCategory?._id || selectedAttribute?._id
                  }_section_${index}`}
                >
                  <Divider orientation="left">{section.name}</Divider>
                  <div className="properties-fields">
                    {section.properties &&
                      section.properties.length > 0 &&
                      section.properties
                        .slice()
                        .sort(sortByOrder)
                        .map((data, index) => (
                          <PropertyItem
                            entity={
                              type === 'category'
                                ? selectedCategory
                                : selectedAttribute
                            }
                            type={data?.propertyType?.title}
                            data={data}
                            disabled={data?.settings?.template?.length > 0}
                            key={`property_item_${index}`}
                            usedValues={usedValues}
                            onChange={onChange}
                            handleChangeValue={(value) => {
                              handleChangeProperty(
                                section.name,
                                data.settings.key,
                                value
                              );
                            }}
                          />
                        ))}
                  </div>
                </div>
              ))}
          </Form>
        </Spin>
      )}
    </div>
  );
};

TaxonomyProperties.propTypes = {};

TaxonomyProperties.defaultProps = {};

export default memo(TaxonomyProperties);
