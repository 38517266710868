import { MenuOutlined } from '@ant-design/icons';
import React from 'react';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

export const DragHandle = sortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'pointer',
      color: '#999',
    }}
  />
));

export const SortableItem = sortableElement((props) => <tr {...props} />);

export const SortableContainer = sortableContainer((props) => (
  <tbody {...props} />
));

export const DragColumns = (columns) => [
  {
    title: '',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  ...columns,
];

/**
 * Prepare data for customProTable display
 *
 * @param draggable
 * @param sortedData
 * @param data
 * @return {*}
 */
export const buildDataSource = (draggable, sortedData, data) => {
  return (draggable ? sortedData : data)?.map((r, index) => {
    return r?.key
      ? r
      : {
          ...r,
          key: index,
        };
  });
};
