import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Upload, Modal, Image, Button, Spin, message } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import prettyBytes from 'pretty-bytes';
import PropTypes from 'prop-types';
import React, { memo, useState, useEffect } from 'react';

import { SINGLE_FILE } from '@marketreach/services/apollo/file';
import { getFileUploadImage } from '@marketreach/utils/files';

import './styles.scss';

const PropertiesTemplateUpload = (props) => {
  const {
    imageUrl,
    readOnly,
    onChange: propsOnChange,
    client,
    validation,
  } = props;

  const [fileList, setFileList] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    if (imageUrl) {
      if (typeof imageUrl === 'string' || imageUrl instanceof String) {
        setFileList([{ thumbUrl: imageUrl }]);
      } else {
        if (_.isArray(imageUrl)) {
          setFileList(imageUrl);
        } else {
          setFileList([imageUrl]);
        }
      }
    }
  }, [imageUrl]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(fileList);
    }
  }, [fileList]);

  useEffect(() => {
    async function fetchToken() {
      return await getAccessTokenSilently();
    }
    fetchToken().then((r) => setAccessToken(r));
  }, [getAccessTokenSilently]);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(true);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewFile, setPreviewFile] = useState();
  const handleCancel = () => setPreviewVisible(false);

  const {
    data: fileDataResponse,
    loading: loadingFileData,
    refetch: refetchFileData,
  } = useQuery(SINGLE_FILE, {
    variables: {
      clientCode: client.apiId,
      id: previewFile?.id || ' ',
    },
  });

  const fileData = fileDataResponse?.file?.data || false;

  const handlePreview = async (previewFile) => {
    const file = previewFile;
    setPreviewFile(file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
    await refetchFileData({
      variables: {
        clientCode: client.apiId,
        id: previewFile?.id || ' ',
      },
    });
  };

  const checkFilesCountLimit = (showAlert = false) => {
    if (validation?.file_count_limit) {
      if (validation?.file_count_limit?.max) {
        if (fileList.length > validation?.file_count_limit?.max) {
          if (showAlert) {
            message.error(
              validation?.file_count_limit?.error ||
                `Max files count should be ${validation?.file_count_limit?.max}, now ${fileList.length}`
            );
          }
          return false;
        }
      }
      if (validation?.file_count_limit?.min) {
        if (fileList.length <= validation?.file_count_limit?.min) {
          if (showAlert) {
            message.error(
              validation?.file_count_limit?.error ||
                `Min files count should be ${validation?.file_count_limit?.min}, now ${fileList.length}`
            );
          }
          return false;
        }
      }
    }
    return true;
  };

  const checkFilesSizeLimit = (file, showAlert = false) => {
    if (validation?.file_size_limit) {
      if (validation?.file_size_limit?.max) {
        if (file.size > validation?.file_size_limit?.max) {
          if (showAlert) {
            message.error(
              validation?.file_size_limit?.error ||
                `Max file size should be ${prettyBytes(
                  validation?.file_size_limit?.max
                )}, now ${prettyBytes(file.size)}`
            );
          }
          return false;
        }
      }
      if (validation?.file_size_limit?.min) {
        if (file.size < validation?.file_size_limit?.min) {
          if (showAlert) {
            message.error(
              validation?.file_size_limit?.error ||
                `Min file size should be ${prettyBytes(
                  validation?.file_size_limit?.min
                )}, now ${prettyBytes(file.size)}`
            );
          }
          return false;
        }
      }
    }
    return true;
  };

  const handleChange = async ({ file, fileList }) => {
    if (file.status !== 'uploading') {
      const fileItems = await fileList
        .map((item) => {
          if (item.response) {
            propsOnChange(true);
            return {
              id: item.response._id,
              name: item.response.name,
              status: 'done',
              url: item.response.url,
              thumbUrl: item.response.url,
              type: item.response.mimeType,
            };
          }
          return item;
        })
        .filter((item) => Object.keys(item).length > 0)
        // filter files that not matched files sizes limits
        .filter((item) => checkFilesSizeLimit(item, false));

      // Remove file duplication
      return setFileList(_.uniqBy(fileItems, (item) => item.id));
    }
    // Remove file duplication
    return setFileList(_.uniqBy(fileList, (item) => item.id));
  };

  const handleRemove = async (file) => {
    // Prevent remove by files count limit rule
    if (!checkFilesCountLimit(true)) {
      return false;
    }
    propsOnChange(true);
    await axios.delete(`${process.env.REACT_APP_BASE_URI}/files`, {
      data: {
        id: file.id,
        clientCode: client?.apiId,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    setFileList(fileList.filter((item) => item.id !== file.id));
  };

  const beforeUpload = (file) => {
    // check sizes and counts limitation
    const checkResult =
      checkFilesSizeLimit(file, true) && checkFilesCountLimit(true);
    return checkResult;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const prepareFileUrls = (file) => {
    return {
      ...file,
      thumbUrl: getFileUploadImage(file),
    };
  };

  return (
    <div className="properties-template-upload">
      <Upload
        className={readOnly ? 'properties-template-upload-readonly' : ''}
        action={`${process.env.REACT_APP_BASE_URI}/files/upload`}
        listType="picture-card"
        fileList={fileList.map(prepareFileUrls)}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
        beforeUpload={beforeUpload}
        maxCount={validation?.file_count_limit?.max || 0}
        readOnly={readOnly}
        headers={{
          Authorization: `Bearer ${accessToken}`,
        }}
        data={{
          clientCode: client?.apiId,
        }}
      >
        {readOnly ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <Spin spinning={loadingFileData}>
          {fileData?.createdAt && (
            <p>
              <b>Upload date: </b>
              {fileData.createdAt}
            </p>
          )}
          {fileData?.type && (
            <p>
              <b>Mime type: </b>
              {fileData.type}
            </p>
          )}
          {fileData?.info?.size && (
            <p>
              <b>File size: </b>
              {prettyBytes(fileData?.info?.size)}
            </p>
          )}
        </Spin>
        {previewFile?.type && previewFile?.type.indexOf('image') !== -1 && (
          <img
            alt={previewTitle}
            style={{ width: '100%' }}
            src={previewImage}
          />
        )}
        {previewFile?.url && (
          <p>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              href={previewFile.url}
              target="_blank"
            >
              Download
            </Button>
          </p>
        )}
      </Modal>
    </div>
  );
};

PropertiesTemplateUpload.propTypes = {
  imageUrl: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

PropertiesTemplateUpload.defaultProps = {
  imageUrl: '',
  readOnly: false,
  onChange: () => {},
  validation: {},
};

export default memo(PropertiesTemplateUpload);
