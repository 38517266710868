import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const input = (props) => {
  const { preDefinedVal, hide, rules, settings, onChange, value } = props;
  const { key, label } = settings;

  return (
    <Form.Item
      name={key}
      rules={rules()}
      label={label}
      initialValue={preDefinedVal?.toString()}
      hidden={hide ?? false}
      hasFeedback
      shouldUpdate
    >
      <ReactQuill
        className="custom-rich-text-edit"
        theme="snow"
        defaultValue={value}
        onChange={(e) => {
          onChange(e);
        }}
      />
    </Form.Item>
  );
};

input.propTypes = {
  props: PropTypes.object,
};

input.defaultProps = {
  props: {},
};

export default input;
