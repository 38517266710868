import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import MonacoEdit from '@marketreach/components/monaco';

const input = (props) => {
  const { preDefinedVal, hide, rules, settings, onChange } = props;
  const { key, label } = settings;

  /**
   * Prepare default value
   *
   * @return {string|undefined}
   */
  const getDefaultValue = () => {
    if (preDefinedVal !== null) {
      switch (typeof preDefinedVal) {
        case 'object':
          return JSON.stringify(preDefinedVal);
        case 'undefined':
          return '';
        default:
          return preDefinedVal?.toString();
      }
    }

    return '';
  };

  // Run getDefaultValue once
  const preparedDefaultValue = getDefaultValue();

  return (
    <Form.Item
      name={key}
      rules={rules()}
      label={label}
      initialValue={preparedDefaultValue}
      hidden={hide ?? false}
      hasFeedback
      getValueFromEvent={(data) => {
        return data;
      }}
    >
      <MonacoEdit defaultValue={preparedDefaultValue} onChange={onChange} />
    </Form.Item>
  );
};

input.propTypes = {
  props: PropTypes.object,
};

input.defaultProps = {
  props: {},
};

export default input;
