import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Spin, Switch, Typography } from 'antd';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useState, useContext, useEffect } from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { CATEGORIES } from '@marketreach/services/apollo/categories';

import ProductsTable from '../../share/products/ProductsTable';

import './styles.scss';
const { Text } = Typography;

const TaxonomyCategoriesPreview = (props) => {
  const { onPreviewChange, previewFilters } = props;

  const { selected: client } = useClientsState();

  const { context } = useContext(TaxonomyContext);
  const {
    selectedAttribute,
    associations,
    categories: rawCategories,
  } = context;

  const [skus, setSkus] = useState([]);

  const { data: previewCategoriesInfo, loading: previewCategoriesLoading } =
    useQuery(CATEGORIES, {
      variables: {
        clientCode: client?.apiId,
        fullMode: true,
        filterParams: {
          _id: {
            $in: rawCategories
              .map((item) => item._id)
              .filter((item) => {
                if (previewFilters.length > 0) {
                  return previewFilters.indexOf(item) !== -1;
                }
                return true;
              }),
          },
        },
      },
    });

  useEffect(() => {
    if (previewCategoriesInfo?.categories?.data?.length > 0) {
      const skusObject = previewCategoriesInfo.categories.data.reduce(
        (acc, item) => {
          acc[item.core.rootId] = acc[item.core.rootId] || [];
          acc[item.core.rootId].push(item.skus);
          return acc;
        },
        {}
      );

      const intersectedGroupSkus = Object.values(skusObject).map(
        (skusGroup) => {
          return _.intersection(skusGroup.flat(), selectedAttribute.skus);
        }
      );

      const intersectedSkus = _.intersection(...intersectedGroupSkus);

      setSkus(intersectedSkus);
    } else {
      setSkus(selectedAttribute?.skus || []);
    }
  }, [previewCategoriesInfo]);

  const categories = associations?.categories || [];

  const [previewMode, setPreviewMode] = useState(true);
  const handleSwitchPreview = (checked) => {
    setPreviewMode(checked);
    onPreviewChange(checked);
  };

  const previewSwitch = (
    <div className="categories-content-preview-switch">
      <Text className="categories-content-preview-switch-label">
        Preview mode
      </Text>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        defaultChecked={previewMode}
        onChange={handleSwitchPreview}
      />
    </div>
  );

  return (
    <div className="categories-content-preview">
      <Spin spinning={previewCategoriesLoading}>
        <ProductsTable
          key={previewFilters}
          type="attribute"
          skuArray={skus}
          previewSwitch={previewSwitch}
          realSearch={categories.length > 0}
          conditionExists={previewFilters.length > 0}
        />
      </Spin>
    </div>
  );
};

TaxonomyCategoriesPreview.propTypes = {
  previewFilters: PropTypes.array,
  onPreviewChange: PropTypes.func,
};

TaxonomyCategoriesPreview.defaultProps = {
  previewFilters: [],
  onPreviewChange: () => {},
};

export default memo(TaxonomyCategoriesPreview);
