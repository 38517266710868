import PropTypes from 'prop-types';

import {
  PROPERTY_TYPE_CODE_EDIT,
  PROPERTY_TYPE_COLOR,
  PROPERTY_TYPE_DATE,
  PROPERTY_TYPE_DECIMAL,
  PROPERTY_TYPE_INTEGER,
  PROPERTY_TYPE_LIST,
  PROPERTY_TYPE_RICH_TEXT,
  PROPERTY_TYPE_TEXT,
  PROPERTY_TYPE_TOGGLE,
  PROPERTY_TYPE_UPLOAD,
  PROPERTY_TYPE_RELATION,
  PROPERTY_TYPE_RADIOBUTTON,
} from '@marketreach/pages/taxonomy/properties/PropertyItem';

import codeEdit from './codeEdit';
import color from './color';
import date from './date';
import decimal from './decimal';
import integer from './integer';
import list from './list';
import radioButton from './radioButton';
import relation from './relation';
import richText from './richText';
import text from './text';
import toggle from './toggle';
import upload from './upload';

const renderInput = (type, props) => {
  switch (type) {
    case PROPERTY_TYPE_TEXT:
      return text(props);
    case PROPERTY_TYPE_INTEGER:
      return integer(props);
    case PROPERTY_TYPE_DECIMAL:
      return decimal(props);
    case PROPERTY_TYPE_TOGGLE:
      return toggle(props);
    case PROPERTY_TYPE_LIST:
      return list(props);
    case PROPERTY_TYPE_DATE:
      return date(props);
    case PROPERTY_TYPE_COLOR:
      return color(props);
    case PROPERTY_TYPE_UPLOAD:
      return upload(props);
    case PROPERTY_TYPE_CODE_EDIT:
      return codeEdit(props);
    case PROPERTY_TYPE_RICH_TEXT:
      return richText(props);
    case PROPERTY_TYPE_RELATION:
      return relation(props);
    case PROPERTY_TYPE_RADIOBUTTON:
      return radioButton(props);
    default:
      return text(props);
  }
};

renderInput.propTypes = {
  type: PropTypes.string,
  props: PropTypes.object,
};

renderInput.defaultProps = {
  type: '',
  props: {},
};

export default renderInput;
