import { SettingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Avatar } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  CLIENTS_ACTIONS,
  useClientsState,
  useClientsDispatch,
} from '@marketreach/providers/ClientsProvider';

import {
  hasPermission,
  SETTINGS_VIEW_PERMISSIONS,
} from '../../utils/permissioins';
import CustomSelect from '../select';

const { Item } = Menu;

const RightMenu = ({ mode }) => {
  const { all: clients, selected: client } = useClientsState();
  const clientsDispatch = useClientsDispatch();

  const { isAuthenticated, user, loginWithRedirect } = useAuth0();

  useEffect(() => {}, [user, isAuthenticated]);

  const clientOptions =
    clients?.length > 0
      ? clients.map((client) => ({
          value: client._id,
          label: client.name,
        }))
      : [];

  const selectClient = useCallback(
    (clientID) => {
      const newClient = clients?.find((c) => c?._id === clientID);
      newClient &&
        clientsDispatch({
          type: CLIENTS_ACTIONS.setSelected,
          selected: newClient,
        });
    },
    [clients, clientsDispatch]
  );

  const getUserName = (name) => {
    if (!name) {
      return 'UN';
    }

    const splitArr = name.split(' ');
    let shortName = '';
    splitArr.forEach((w) => {
      shortName += w[0].toUpperCase();
    });

    return shortName;
  };

  const getBackgroundColor = (name) => {
    if (!name) {
      return '#444444';
    }

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    // eslint-disable-next-line no-bitwise
    const c = (hash & 0x00ffffff).toString(16).toUpperCase();

    return `#${'00000'.substring(0, 6 - c.length)}${c}`;
  };

  return (
    <>
      {isAuthenticated ? (
        <div className="loggedIn">
          <Link to="/profile" style={{ marginRight: 8 }}>
            <Avatar
              src={user.picture}
              style={{
                background: getBackgroundColor(user.name || user.email),
                verticalAlign: 'middle',
              }}
              size="default"
            >
              {getUserName(user.name || user.email)}
            </Avatar>
          </Link>
          <Link to="/profile">{user.name}</Link>
          <CustomSelect
            placeholder="Select client"
            className="client-select"
            value={client?._id}
            defaultValue={client?._id}
            items={clientOptions}
            handleChange={selectClient}
          />
          {hasPermission(user, SETTINGS_VIEW_PERMISSIONS) && (
            <Link to="/settings">
              <SettingOutlined />
            </Link>
          )}
        </div>
      ) : (
        <Menu mode={mode}>
          <Item key="mail">
            <Link onClick={() => loginWithRedirect()}>Sign in</Link>
          </Item>
        </Menu>
      )}
    </>
  );
};

RightMenu.propTypes = {
  mode: PropTypes.string,
};

RightMenu.defaultProps = {
  mode: 'horizontal',
};

export default memo(RightMenu);
