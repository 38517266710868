import { useQuery } from '@apollo/client';
import { Button, Dropdown, Menu, Spin, Typography } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import Tree from 'react-virtualized-tree';
import Renderers from 'react-virtualized-tree/es/renderers';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { CATEGORIES } from '@marketreach/services/apollo/categories';
import {
  flattenNodes,
  normalizeEntity,
  treeData,
} from '@marketreach/utils/common';

import 'react-virtualized/styles.css';
import 'react-virtualized-tree/lib/main.css';
import 'material-icons/css/material-icons.css';

import './styles.scss';

const NodeNameRenderer = ({ node: { name }, children }) => (
  <>
    {name}
    {children}
  </>
);

const { Deletable, Expandable, Favorite } = Renderers;

const SidebarCategoriesTree = (props) => {
  const {
    selectedKeys,
    expandedKeys,
    handleExpandedKeys,
    handleSelectedKeys,
    handleClickSubmenu,
  } = props;

  const { selected: client } = useClientsState();

  const { context, setContext } = useContext(TaxonomyContext);
  const { categories } = context;

  const [state, setState] = useState({
    nodes: [],
    availableRenderers: [Expandable, Deletable, Favorite],
    selectedRenderers: [Expandable, NodeNameRenderer],
  });

  const {
    loading: loadingCategories,
    data: categoryRes,
    refetch: refetchCategories,
  } = useQuery(CATEGORIES, {
    variables: { clientCode: client?.apiId },
  });

  useEffect(() => {
    if (client) {
      refetchCategories();
    }
  }, [client, refetchCategories]);

  useEffect(() => {
    const categoryData = categoryRes ? categoryRes?.categories?.data : [];

    const categoriesList = categoryData || [];

    if (!_.isEqual(context.categories, categoriesList)) {
      setContext({ ...context, categories: categoriesList });
    }
  }, [categoryRes, context, setContext]);

  const handleSelectCategory = (event) => {
    setContext({ ...context, selectedCategoryId: event.node.key });
  };

  const onClickSubmenu = (mode, id) => {
    handleClickSubmenu(mode, id);
  };

  const menu = (id) => (
    <Menu>
      <Menu.Item
        key="bulk_create"
        onClick={() => onClickSubmenu('bulk_create', id)}
      >
        Create New Categories
      </Menu.Item>
      <Menu.Item key="edit" onClick={() => onClickSubmenu('edit', id)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => onClickSubmenu('delete', id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const entityDataList = useMemo(
    () => normalizeEntity(categories),
    [categories]
  );

  useEffect(() => {
    const treeDataValue = treeData(
      entityDataList,
      'name',
      menu,
      expandedKeys,
      selectedKeys
    );

    setState((prevState) => ({
      ...prevState,
      nodes: treeDataValue,
    }));
  }, [entityDataList, expandedKeys, selectedKeys]);

  const onSelect = (selectedKeysList, info) => {
    handleSelectCategory(info);
    handleSelectedKeys(selectedKeysList);
  };

  const onExpand = (expandedKeysList) => {
    handleExpandedKeys(expandedKeysList);
  };

  /**
   * Process tree state change
   * @param nodes
   */
  const handleChange = (nodes) => {
    setState((prevState) => ({ ...prevState, nodes }));
    const expandedKeysList = flattenNodes(nodes)
      .filter((item) => item.state.expanded === true)
      .map((item) => item.id);

    onExpand(expandedKeysList);
  };

  /**
   * Handle node click
   * @param nodeId
   */
  const handleNodeSelect = (nodeId) => {
    onSelect([nodeId], { node: { key: nodeId } });
  };

  return (
    <div className="taxonomy-sidebar-tree-content">
      <Spin spinning={loadingCategories}>
        <div className="taxonomy-total-count">
          <Typography>{entityDataList?.length} Categories</Typography>
        </div>
        <div className="taxonomy-sidebar-virtual-tree-wrapper">
          <Tree nodes={state.nodes} onChange={handleChange}>
            {({ style, node, ...rest }) => (
              <div style={style}>
                <Expandable
                  node={node}
                  {...rest}
                  iconsClassNameMap={{
                    collapsed: 'mi mi-keyboard-arrow-right',
                    expanded: 'mi mi-keyboard-arrow-down',
                    lastChild: 'mi mi-insert-drive-file',
                  }}
                >
                  <Dropdown overlay={menu(node.id)} trigger="contextMenu">
                    <Button
                      type="text"
                      onClick={() => handleNodeSelect(node.id)}
                      style={{
                        padding: 0,
                        transform: 'translateY(-7px)',
                      }}
                      className={
                        selectedKeys.includes(node.id) ? 'selected' : ''
                      }
                    >
                      {node.name}
                    </Button>
                  </Dropdown>
                </Expandable>
              </div>
            )}
          </Tree>
        </div>
      </Spin>
    </div>
  );
};

SidebarCategoriesTree.propTypes = {
  selectedKeys: PropTypes.array,
  expandedKeys: PropTypes.array,
  handleSelectedKeys: PropTypes.func,
  handleExpandedKeys: PropTypes.func,
  handleClickSubmenu: PropTypes.func,
};

SidebarCategoriesTree.defaultProps = {
  selectedKeys: [],
  expandedKeys: [],
  handleSelectedKeys: () => {},
  handleExpandedKeys: () => {},
  handleClickSubmenu: () => {},
};

export default SidebarCategoriesTree;
