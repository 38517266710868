import { useQuery } from '@apollo/client';
import { Layout, List, Popover, Spin } from 'antd';
import _ from 'lodash';
import React, { memo, useState, useEffect } from 'react';

import CustomProTable from '@marketreach/components/protable';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { GET_PRODUCT_KEYS } from '@marketreach/services/apollo/products';

import './styles.scss';

const SchemaViewer = () => {
  const { selected: client } = useClientsState();

  const { loading, data } = useQuery(GET_PRODUCT_KEYS, {
    variables: {
      clientCode: client?.apiId,
    },
  });

  const columns = [
    { title: 'Key', dataIndex: 'key' },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text, row) => (
        <List
          size="small"
          dataSource={text}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      ),
    },
    {
      title: 'Raw',
      dataIndex: 'raw',
      render: (text, row) => {
        const field = _.cloneDeep(text);
        for (const i in field?.types) {
          if (field?.types[i].values) {
            const uniqueData = [...new Set(field.types[i].values)];
            const needAddDot = uniqueData.length > 5;
            field.types[i].values = uniqueData.slice(0, 5);
            if (needAddDot) {
              field.types[i].values.push('...');
            }
          }
          if (field.types[i].types) {
            field.types[i].types = 'Subtypes desc (truncated)';
          }
          if (field.types[i].lengths) {
            field.types[i].lengths = 'Subtypes lengths (truncated)';
          }
          if (field.types[i].fields) {
            field.types[i].fields = 'Subtypes fields (truncated)';
          }
        }
        return (
          <div>
            <pre>{JSON.stringify(field, null, 4)}</pre>
          </div>
        );
      },
    },
  ];

  return (
    <div className="ant-pro-grid-content settings-content-locale">
      <Layout className="api-access-main">
        <div>
          <h4>Schema Viewer</h4>
          <Spin spinning={loading}>
            <CustomProTable
              data={data?.getProductKeys?.data}
              columns={columns}
            />
          </Spin>
        </div>
      </Layout>
    </div>
  );
};

SchemaViewer.propTypes = {};

SchemaViewer.defaultProps = {};

export default memo(SchemaViewer);
