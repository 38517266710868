/* eslint-disable no-extend-native,no-restricted-syntax,prefer-rest-params */
import {
  DeleteOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Tag } from 'antd';
import Text from 'antd/es/typography/Text';
import React from 'react';

import { capitalize } from '@marketreach/utils/common';
import { sortTable } from '@marketreach/utils/products';

/**
 * Adding methods to native Set
 */
Set.prototype.union = function (setB) {
  const union = new Set(this);
  for (const elem of setB) {
    union.add(elem);
  }
  return union;
};

Set.prototype.intersection = function (setB) {
  const intersection = new Set();
  for (const elem of setB) {
    if (this.has(elem)) {
      intersection.add(elem);
    }
  }
  return intersection;
};

Set.prototype.difference = function (setB) {
  const difference = new Set(this);
  for (const elem of setB) {
    difference.delete(elem);
  }
  return difference;
};

const PRODUCTS_SET = {
  union: new Set(),
  includes: new Set(),
  excludes: new Set(),
};

export const AddSets = (newSets, type) => {
  PRODUCTS_SET[type] = PRODUCTS_SET[type].union(newSets);
};

export const DiffSets = () =>
  PRODUCTS_SET.includes.difference(PRODUCTS_SET.excludes);

export const getData = () => PRODUCTS_SET;

export const formatProductsData = () => {
  PRODUCTS_SET.union.clear();
};

export const formatDifference = () => {
  PRODUCTS_SET.includes.clear();
  PRODUCTS_SET.excludes.clear();
};

export const getUnion = (setA, setB) => setA.union(setB);

/**
 * Prepare display of rule basis
 * @param basis
 * @return {JSX.Element}
 */
export const basisCell = (basis) => {
  const color = basis === 'include' ? '#2cae2e' : '#ff1f2b';
  return (
    <div style={{ color }} className="rule-basis">
      {basis === 'include' ? <PlusCircleOutlined /> : <MinusCircleOutlined />}
      <Text
        className="rule-basis-title"
        style={{
          color,
          marginLeft: 8,
        }}
      >
        {capitalize(basis)}
      </Text>
    </div>
  );
};

/**
 * Wrap rules criteria as a tags
 * @param criteria
 * @return {JSX.Element}
 */
export const criteriaChips = (criteria) => (
  <>
    {criteria.length > 0
      ? criteria.map((c) => (
          <Tag color="#198be2" key={c?.replace(' ', '_')}>
            {c}
          </Tag>
        ))
      : []}
  </>
);

/**
 * Prepare data to show at rules table in column `Criteria`
 * @param row
 * @param criteriaChipsFunc
 * @return {string|null|*}
 */
export const prepareCriteriaRenderRow = (row, criteriaChipsFunc) => {
  if (row?.simpleMode) {
    return row?.criteriaTemplate
      ? row.criteriaTemplate
      : criteriaChipsFunc(row.criteria);
  }
  return row.advancedQuery;
};

/**
 * Prepare column configuration for rules table
 * @param basisCellFunc
 * @param criteriaChipsFunc
 * @param handleDeleteRuleFunc
 * @param handleEditRuleFunc
 * @param allowEdit
 * @return {({dataIndex: string, title: string}|{dataIndex: string, title: string, render: (function(*, *):
 *   *)}|{dataIndex: string, title: string}|{dataIndex: string, title: string}|{dataIndex: string, title: string,
 *   render: (function(*, *))})[]}
 */
export const prepareRulesColumns = (
  basisCellFunc,
  criteriaChipsFunc,
  handleDeleteRuleFunc,
  handleEditRuleFunc,
  allowEdit = true
) =>
  [
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Basis',
      dataIndex: 'basis',
      render: (text, row) => basisCellFunc(row.basis),
    },
    {
      title: 'Match',
      dataIndex: 'match',
    },
    {
      title: 'Word',
      dataIndex: 'word',
    },
    {
      title: 'Case sensitive',
      dataIndex: 'case_sensitive',
      // eslint-disable-next-line camelcase
      render: (text, row) => <span>{(!!row?.case_sensitive).toString()}</span>,
    },
    {
      title: 'Key',
      dataIndex: 'key',
      render: (text, row) => (
        <span>{row?.simpleMode ? row.key : 'advanced mode'}</span>
      ),
    },
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      render: (text, row) => prepareCriteriaRenderRow(row, criteriaChipsFunc),
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      render: (text, row) => (
        <span>{row?.associations?.categories?.length || 0}</span>
      ),
    },
    {
      title: 'Attributes',
      dataIndex: 'attributes',
      render: (text, row) => (
        <span>{row?.associations?.attributes?.length || 0}</span>
      ),
    },
    {
      title: 'Product IDs',
      dataIndex: 'associatedProductIds',
      render: (text, row) => <span>{row?.productIds?.length || 0}</span>,
    },
    {
      title: 'SKUs',
      dataIndex: 'skus',
      render: (text, row) => <span>{row?.skus?.length || 0}</span>,
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, row) => {
        const buttons = [
          <Button
            key="delete"
            type="text"
            shape="circle"
            onClick={() => handleDeleteRuleFunc(row)}
          >
            <DeleteOutlined />
          </Button>,
        ];
        if (allowEdit) {
          buttons.push(
            <Button
              key="edit"
              type="text"
              shape="circle"
              onClick={() => handleEditRuleFunc(row)}
            >
              <EditOutlined />
            </Button>
          );
        }

        return buttons;
      },
    },
  ].map((item) => {
    item.sorter = true;
    item.key = item.dataIndex;
    item.sorter = (a, b) => {
      if (item.dataIndex === 'categories' || item.dataIndex === 'attributes') {
        return sortTable(
          prepareSafeAssociations(a)[item.dataIndex]?.length || 0,
          prepareSafeAssociations(b)[item.dataIndex]?.length || 0
        );
      }

      if (item.dataIndex === 'associatedProductIds') {
        return sortTable(
          a?.productIds?.length || 0,
          b?.productIds?.length || 0
        );
      }

      if (item.dataIndex === 'skus') {
        return sortTable(a?.skus?.length || 0, b?.skus?.length || 0);
      }
      return sortTable(a[item.dataIndex] ?? '', b[item.dataIndex] ?? '');
    };

    return item;
  });

/**
 * Prepare associations object
 * Fill all missed data by empty values
 *
 * @param item
 * @return {*|{attributes: [], categories: []}}
 */
export const prepareSafeAssociations = (item) => {
  const associations = item?.associations || {
    categories: [],
    attributes: [],
  };
  associations.categories = associations.categories || [];
  associations.attributes = associations.attributes || [];
  return associations;
};
