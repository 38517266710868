import * as _ from 'lodash';
import React, { useEffect } from 'react';
import slugify from 'slugify';

import { getFileUploadImage } from '@marketreach/utils/files';

const sortByOrder = (a, b) => {
  const fieldItemA = a.order || 0;
  const fieldItemB = b.order || 0;
  return fieldItemA - fieldItemB;
};

const sortByField = (field) => (a, b) => {
  const fieldItemA = a[field]?.toUpperCase();
  const fieldItemB = b[field]?.toUpperCase();
  let comparison = 0;
  if (fieldItemA > fieldItemB) {
    comparison = 1;
  } else if (fieldItemA < fieldItemB) {
    comparison = -1;
  }
  return comparison;
};

const capitalize = (str) => {
  if (typeof str !== 'string' || !str) {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

const makeRandomId = (length) => {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const buildSlug = (str) => {
  // you can read the doc here - https://github.com/simov/slugify
  return slugify(str.replace(/^https?:\/\//, ''), {
    locale: 'en',
    lower: true,
    replacement: '_',
    remove: /[*+~.()'"!:@\\/<>?]/g,
  });
};

const useBeforeUnload = ({ when, message }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [when, message]);
};

const normalizeEntity = (data, simpleView = false) => {
  return data.map((item) => {
    const insideProp = {};

    if (item?.properties) {
      for (const section of Object.keys(item.properties)) {
        for (const key of Object.keys(item.properties[section])) {
          insideProp[key] = item?.properties[section][key];
        }
      }
    }
    for (const prop of Object.keys(item)) {
      if (prop !== 'properties' && prop !== 'relatedFieldsData') {
        insideProp[prop] = item[prop];
      }
    }

    const preparedData = {
      ...insideProp,
      _id: item._id,
      parentId: item?.core?.parentId,
      core: item?.core,
    };
    if (item['relatedFieldsData'] && simpleView) {
      for (const field in item['relatedFieldsData']) {
        preparedData[`${field}_keys`] = preparedData[field];
        preparedData[field] = item['relatedFieldsData'][field].map(
          (fieldData) => {
            const data = [];
            if (!_.isArray(fieldData)) {
              data.push(fieldData);
            } else {
              data.push(...fieldData);
            }

            return data.map((dataItem) => {
              if (_.isObject(dataItem)) {
                const imageUrl = getFileUploadImage(dataItem);
                return (
                  <p>
                    <img src={imageUrl} className={'tableImage'} />
                  </p>
                );
              }
              return <p>{dataItem.toString()}</p>;
            });
          }
        );
      }
    }
    return preparedData;
  });
};

/**
 * Build data for display as a tree
 * As label should be used field that marked as apiId
 *
 * @param entityDataList
 * @param apiIdField
 * @param menu
 * @param expanded
 * @param selected
 * @return {[]}
 */
const treeData = (
  entityDataList,
  apiIdField,
  menu,
  expanded = [],
  selected
) => {
  const tree = [];
  if (entityDataList && entityDataList.length > 0) {
    for (const entityInfo of entityDataList?.filter(
      (item) => item.parentId === undefined || item.parentId === null
    )) {
      const treeItem = {
        id: entityInfo._id,
        name: entityInfo[apiIdField],
        state: {
          expanded: expanded.includes(entityInfo._id),
          favorite: false,
          deletable: false,
        },
      };
      const childs = getSubTree(
        entityDataList,
        entityInfo._id,
        apiIdField,
        menu,
        expanded,
        selected
      );
      if (childs) {
        treeItem.children = childs;
      }
      tree.push(treeItem);
    }
  }
  return tree;
};

const getSubTree = (
  entityDataList,
  parentId,
  apiIdField,
  menu,
  expanded,
  selected
) => {
  const tree = [];
  if (entityDataList && entityDataList.length > 0) {
    for (const entityInfo of entityDataList?.filter(
      (item) => item?.core?.parentId && item.core.parentId === parentId
    )) {
      const treeItem = {
        name: entityInfo[apiIdField],
        id: entityInfo._id,
        state: {
          expanded: expanded.includes(entityInfo._id),
          favorite: false,
          deletable: false,
        },
      };
      const childs = getSubTree(
        entityDataList,
        entityInfo._id,
        apiIdField,
        menu,
        expanded,
        selected
      );
      if (childs) {
        treeItem.children = childs;
      }
      tree.push(treeItem);
    }
  }
  return tree;
};

/**
 * Get root id for entity tree structure
 *
 * @param {string} id
 * @param {array} entityList
 * @return {*}
 */
const getRootId = (id, entityList) => {
  const item = entityList.find((entity) => entity._id === id);
  if (item.core.parentId === null) {
    return item._id;
  }
  return getRootId(item.core.parentId, entityList);
};

const ENTITY_TYPE_ATTRIBUTE = 'attribute';
const ENTITY_TYPE_CATEGORY = 'category';
const ENTITY_TYPE_PRODUCT = 'product';

/**
 * Get flat nodes of virutalized tree
 *
 * @param nodes
 * @return {[]}
 */
const flattenNodes = (nodes) => {
  const nodesArr = [];
  for (const node of nodes) {
    nodesArr.push(node);
    if (node.children && node.children.length > 0) {
      nodesArr.push(...flattenNodes(node.children));
    }
  }
  return nodesArr;
};

export {
  sortByOrder,
  sortByField,
  capitalize,
  makeRandomId,
  buildSlug,
  useBeforeUnload,
  normalizeEntity,
  treeData,
  getRootId,
  flattenNodes,
  ENTITY_TYPE_ATTRIBUTE,
  ENTITY_TYPE_CATEGORY,
  ENTITY_TYPE_PRODUCT,
};
