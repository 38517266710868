import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

const { TextArea } = Input;

const input = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, label } = settings;

  return (
    <Form.Item
      name={key}
      rules={rules()}
      label={label}
      initialValue={preDefinedVal?.toString()}
      hidden={hide ?? false}
      hasFeedback
    >
      <TextArea
        autoSize={{ minRows: 1 }}
        onChange={onChange}
        defaultValue={preDefinedVal?.toString()}
        readOnly={readOnly}
        disabled={disabled}
      />
    </Form.Item>
  );
};

input.propTypes = {
  props: PropTypes.object,
};

input.defaultProps = {
  props: {},
};

export default input;
