import { gql } from '@apollo/client';

export const ENTITY_DATA = gql`
  query entityData(
    $clientCode: String!
    $type: String!
    $page: Int!
    $pageSize: Int!
    $searchParams: JSON
    $sortParams: JSON
  ) {
    entityData(
      clientCode: $clientCode
      type: $type
      page: $page
      pageSize: $pageSize
      searchParams: $searchParams
      sortParams: $sortParams
    ) {
      data
      totalCount
    }
  }
`;

export const ENTITY_DATA_QUERY = 'entityData';

export const SAVE_ENTITY_DATA = gql`
  mutation saveEntityData(
    $id: ID
    $parentId: ID
    $clientCode: String!
    $type: String!
    $data: JSON!
  ) {
    saveEntityData(
      _id: $id
      parentId: $parentId
      clientCode: $clientCode
      type: $type
      data: $data
    ) {
      success
      error
      data
    }
  }
`;

export const REMOVE_ENTITY_DATA = gql`
  mutation removeEntityData($id: ID, $clientCode: String!, $type: String!) {
    removeEntityData(_id: $id, clientCode: $clientCode, type: $type) {
      success
      error
      data
    }
  }
`;

export const ENTITIES_LIST = gql`
  query listOfEntities($clientCode: String!) {
    listOfEntities(clientCode: $clientCode) {
      data
      totalCount
    }
  }
`;

export const BULK_ENTITY_CREATE = gql`
  mutation bulkEntityCreate(
    $parentId: ID
    $rootId: ID
    $clientCode: String!
    $type: String!
    $keys: String!
  ) {
    bulkEntityCreate(
      parentId: $parentId
      rootId: $rootId
      clientCode: $clientCode
      type: $type
      keys: $keys
    ) {
      success
      error
      data
    }
  }
`;
