import { useQuery } from '@apollo/client';
import { Empty, Spin } from 'antd';
import React, { memo, useContext } from 'react';

import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { RULES } from '@marketreach/services/apollo/rules';

import ProductsTable from '../../share/products/ProductsTable';

import './styles.scss';

const TaxonomyProducts = () => {
  const { selected: client } = useClientsState();
  const { context } = useContext(TaxonomyContext);
  const { selectedCategory, selectedAttribute, selectedType: type } = context;

  const getTitle = () =>
    type === 'category' ? selectedCategory?.name : selectedAttribute?.name;

  const filterField = `associations.${
    type === 'category' ? 'categories' : 'attributes'
  }`;

  const { data: ruleRes, loading } = useQuery(RULES, {
    variables: {
      clientCode: client?.apiId,
      filterParams: {
        [filterField]: {
          $in: [
            type === 'category'
              ? selectedCategory?._id
              : selectedAttribute?._id,
          ],
        },
      },
    },
  });

  const ruleData = ruleRes ? ruleRes?.rules?.data : [];
  const rules = ruleData || [];

  const skus = [];

  return (
    <div className="ant-pro-grid-content taxonomy-products-content">
      <Spin spinning={loading}>
        <div className="taxonomy-products-content-title">{getTitle()}</div>
        {!loading && rules.length > 0 && (
          <ProductsTable
            type={type}
            skuArray={skus}
            rules={rules}
            showManagement={false}
          />
        )}
        {!loading && rules.length === 0 && <Empty />}
      </Spin>
    </div>
  );
};

TaxonomyProducts.propTypes = {};

TaxonomyProducts.defaultProps = {};

export default memo(TaxonomyProducts);
