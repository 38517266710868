import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import HCExporting from 'highcharts/modules/exporting';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

HCExporting(Highcharts);

const PaymentStatusChart = (props) => {
  const { options } = props;

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

PaymentStatusChart.propTypes = {
  options: PropTypes.object,
};

PaymentStatusChart.defaultProps = {
  options: {
    chart: {
      height: 400,
      marginRight: 80,
      zoomType: 'x',
    },
    title: {
      text: '',
    },
    navigator: {
      enabled: true,
      xAxis: {
        labels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: true,
    },
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: true,
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      lineWidth: 1,
    },
    series: [
      {
        name: 'Traffic',
        data: [
          [1491116400000, 29.9],
          [1491202800000, 71.5],
          [1491202800000, 106.4],
          [1491375600000, 129.2],
          [1491462000000, 144.0],
          [1491548400000, 176.0],
          [1491634800000, 135.6],
          [1491721200000, 148.5],
          [1491807600000, 216.4],
          [1491894000000, 194.1],
        ],
      },
      {
        name: 'Payment',
        data: [
          [1491116400000, 144.0],
          [1491202800000, 176.0],
          [1491202800000, 135.6],
          [1491375600000, 148.5],
          [1491462000000, 216.4],
          [1491548400000, 194.1],
          [1491634800000, 95.6],
          [1491721200000, 54.4],
          [1491807600000, 29.9],
          [1491894000000, 71.5],
        ],
      },
    ],
  },
};

export default memo(PaymentStatusChart);
