import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
} from '@apollo/client';
import { Auth0Provider, Auth0Context } from '@auth0/auth0-react';
import { ConfigProvider } from 'antd';
import enAntd from 'antd/lib/locale-provider/en_US';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// import emitter from 'utils/events/index';

import PropertyManagerProvider from '@marketreach/providers/PropertyManagerProvider';
import RulesManagerProvider from '@marketreach/providers/RulesManagerProvider';
import TaxonomyProvider from '@marketreach/providers/TaxonomyProvider';

import App from './App';
import { ClientsProvider } from './providers/ClientsProvider';
import UserProvider from './providers/UserProvider';
import * as serviceWorker from './serviceWorker';

import './index.less';

const { REACT_APP_APOLLO_URI } = process.env;

const buildApolloClient = (auth0Client) => {
  const authLink = new ApolloLink(async (operation, forward) => {
    let token = '';
    if (auth0Client.isAuthenticated) {
      try {
        token = await auth0Client.getAccessTokenSilently();
      } catch (e) {
        if (e.error === 'login_required') {
          auth0Client.loginWithRedirect();
        }
        if (e.error === 'consent_required') {
          auth0Client.loginWithRedirect();
        }
        throw e;
      }
    }
    console.log('41 user: ', auth0Client.user);
    console.log('token: ', token);

    const authHeaders = token ? { authorization: `Bearer ${token}` } : {};

    operation.setContext(({ headers }: Record<string, any>) => ({
      headers: {
        ...authHeaders,
        ...headers,
      },
    }));
    return forward(operation);
  });

  const link = from([
    authLink,
    new HttpLink({
      uri: REACT_APP_APOLLO_URI,
    }),
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
  return client;
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={`${window.location.origin}/callback`}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    scope="profile read:current_user update:current_user_metadata"
  >
    <Auth0Context.Consumer>
      {(auth0Client) => {
        return (
          <ApolloProvider client={buildApolloClient(auth0Client)}>
            <Router>
              <ConfigProvider locale={enAntd}>
                <UserProvider>
                  <ClientsProvider>
                    <TaxonomyProvider>
                      <PropertyManagerProvider>
                        <RulesManagerProvider>
                          <App />
                        </RulesManagerProvider>
                      </PropertyManagerProvider>
                    </TaxonomyProvider>
                  </ClientsProvider>
                </UserProvider>
              </ConfigProvider>
            </Router>
          </ApolloProvider>
        );
      }}
    </Auth0Context.Consumer>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about services workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.NODE_ENV === 'development') {
  console.warn('PROCESS ENV VALUES*******');
  console.log(process.env);
}
