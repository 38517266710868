import { useMutation, useQuery } from '@apollo/client';
import { Empty, Layout, Spin } from 'antd';
import React, { memo, useEffect, useState, useContext } from 'react';

import CustomTabs from '@marketreach/components/tabs';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { ATTRIBUTE } from '@marketreach/services/apollo/attributes';
import {
  CATEGORY,
  UPDATE_CATEGORY,
} from '@marketreach/services/apollo/categories';
import {
  getTaxonomyTreeState,
  setTaxonomyTreeState,
} from '@marketreach/services/localstorage';

import TaxonomyAttributes from './attributes';
import TaxonomyCategories from './categories';
import TaxonomyHistory from './history';
import TaxonomyProducts from './products';
import TaxonomyProperties from './properties';
import TaxonomyRules from './rules';
import TaxonomySidebar from './sidebar';
import './styles.scss';

const { Sider } = Layout;

const Taxonomy = () => {
  const { selected: client } = useClientsState();

  const [{ loading: updateCategoryLoading }] = useMutation(UPDATE_CATEGORY);

  const { context, setContext } = useContext(TaxonomyContext);

  const { loading: loadingSelectedCategory, data: selectedCategoryData } =
    useQuery(CATEGORY, {
      variables: {
        clientCode: client?.apiId,
        id: context.selectedCategoryId,
      },
      fetchPolicy: 'network-only',
      skip: !context.selectedCategoryId,
    });

  const { loading: loadingSelectedAttribute, data: selectedAttributeData } =
    useQuery(ATTRIBUTE, {
      variables: {
        clientCode: client?.apiId,
        id: context.selectedAttributeId,
      },
      fetchPolicy: 'network-only',
      skip: !context.selectedAttributeId,
    });

  useEffect(() => {
    if (context.selectedCategory !== selectedCategoryData?.category?.data) {
      setContext({
        ...context,
        selectedCategory: selectedCategoryData?.category?.data,
      });
    }
  }, [selectedCategoryData]);

  useEffect(() => {
    if (context.selectedAttribute !== selectedAttributeData?.attribute?.data) {
      setContext({
        ...context,
        selectedAttribute: selectedAttributeData?.attribute?.data,
      });
    }
  }, [selectedAttributeData]);

  useEffect(() => {
    setTaxonomyTreeState(
      context.selectedCategoryId,
      context.selectedAttributeId
    );
  }, [context.selectedAttributeId, context.selectedAttributeId]);

  useEffect(() => {
    const treeState = getTaxonomyTreeState();
    setContext({
      ...context,
      selectedCategoryId: treeState?.categoryId,
      selectedAttributeId: treeState?.attributeId,
    });
  }, [getTaxonomyTreeState]);

  let [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = (key) => {
    setActiveTab(key);
  };

  const propertiesTab = () => {
    return context.selectedAttribute || context.selectedCategory ? (
      <TaxonomyProperties />
    ) : (
      <TaxonomyHistory />
    );
  };

  const rulesTab = () => (
    <TaxonomyRules key={`TaxonomyRules_${context.selectedType}`} />
  );

  const categoriesTab = () => {
    return context.selectedType === 'category' ? (
      <TaxonomyAttributes />
    ) : (
      <TaxonomyCategories />
    );
  };

  const productsTab = () => <TaxonomyProducts />;

  const historyTab = () => {
    return <TaxonomyHistory />;
  };

  const getTabContent = () => {
    let tabIndex = activeTab;
    if (
      context.selectedType === 'attribute' &&
      context.selectedAttribute &&
      context.selectedAttribute.core.parentId === null &&
      activeTab > 0
    )
      tabIndex++;

    switch (tabIndex) {
      case 0:
        return propertiesTab();
      case 1:
        return rulesTab();
      case 2:
        return categoriesTab();
      case 3:
        return productsTab();
      case 4:
        return historyTab();
      default:
        return null;
    }
  };

  // const [tabs, setTabs] = useState(tabs());
  const tabs = () => {
    if (context.selectedAttribute || context.selectedCategory) {
      const tabsList = ['Properties'];
      if (context.selectedType === 'category') {
        tabsList.push('Rules');
        tabsList.push('Attributes');
      } else {
        if (context.selectedAttribute?.core.parentId !== null) {
          tabsList.push('Rules');
        }
        tabsList.push('Categories');
      }

      tabsList.push(...['Products', 'History']);
      return tabsList;
    }

    return ['History'];
  };

  const [tabContent, setTabContent] = useState();
  useEffect(() => {
    const content = getTabContent();
    if (tabContent !== content) {
      setTabContent(content);
    }
  }, [
    activeTab,
    context.selectedAttribute,
    context.selectedCategory,
    context.selectedType,
  ]);

  const [collapsed, setCollapsed] = useState(false);
  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  const buildShowData = () =>
    context.selectedType === 'category'
      ? !!context.selectedCategory
      : !!context.selectedAttribute;

  const [showData, setShowData] = useState(false);

  useEffect(() => {
    setShowData(buildShowData());
  }, [
    context.selectedType,
    context.selectedCategory,
    context.selectedAttribute,
  ]);

  const content = () => {
    if (showData) {
      return (
        <CustomTabs
          className="taxonomy-tabs"
          tabs={tabs()}
          activeTab={activeTab}
          tabContent={tabContent}
          handleChange={handleChangeTab}
        />
      );
    }

    if (
      (context.selectedType === 'category' && !context.selectedCategory) ||
      (context.selectedType === 'attribute' && !context.selectedAttribute)
    ) {
      return (
        <Empty
          description={`Please select ${context.selectedType} at sidebar`}
        />
      );
    }

    return (
      <Spin spinning>
        <h2>Loading...</h2>
      </Spin>
    );
  };

  return (
    <div className="ant-pro-grid-content taxonomy-content">
      <Spin
        spinning={
          updateCategoryLoading ||
          loadingSelectedCategory ||
          loadingSelectedAttribute
        }
      >
        <Layout>
          <Sider
            width={350}
            collapsedWidth={15}
            collapsible
            collapsed={collapsed}
            onCollapse={handleToggleClick}
            className="side-bar-content"
          >
            {collapsed ? (
              <div className="collapsed-sider-content" />
            ) : (
              <TaxonomySidebar />
            )}
          </Sider>
          <Layout>{content()}</Layout>
        </Layout>
      </Spin>
    </div>
  );
};

Taxonomy.propTypes = {};

Taxonomy.defaultProps = {};

export default memo(Taxonomy);
