import PropTypes from 'prop-types';
import React, { useState, createContext } from 'react';

const initialValues = {
  type: null,
  entityId: null,
  entity: null,
};

export const RulesManagerContext = createContext(initialValues);

const RulesManagerProvider = (props) => {
  const { children } = props;

  const [rulesManager, setRulesManager] = useState(initialValues);

  return (
    <RulesManagerContext.Provider value={{ rulesManager, setRulesManager }}>
      {children}
    </RulesManagerContext.Provider>
  );
};

RulesManagerProvider.propTypes = {
  children: PropTypes.node,
};

RulesManagerProvider.defaultProps = {
  children: null,
};

export default RulesManagerProvider;
