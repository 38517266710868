import { sortByField } from '../common';

const makeArrayObject = (array, idName) => {
  const list = array || [];
  list.forEach((item, index) => {
    if (item[idName] === '') {
      list[index][idName] = null;
    }
  });
  return list;
};

const getSubTree = (list, parentId, type, originNode, newPid) => {
  const subTree = [];
  const association = [];

  const treeArray = list.slice().sort(sortByField('name'));
  const sublist = treeArray.filter((item) => item[type] === parentId);

  if (sublist.length > 0) {
    sublist.forEach((item) => {
      let subNode = null;
      let expandFlag = false;
      if (originNode && originNode.length) {
        subNode = originNode.find((nodeItem) => nodeItem.item._id === item._id);
      }

      if (newPid !== undefined && item._id === newPid) {
        expandFlag = true;
      } else {
        expandFlag = subNode ? subNode.expanded : false;
      }

      if (item._id) {
        association.push({
          title: item.name,
          key: item._id,
          appear: item.appear || [],
          children: getSubTree(treeArray, item._id, type).association,
        });
        subTree.push({
          title: item.name,
          editable: false,
          expanded: expandFlag,
          item,
          children: getSubTree(
            treeArray,
            item._id,
            type,
            subNode ? subNode.children : null,
            newPid
          ).subTree,
        });
      }
    });
  }

  return {
    subTree,
    association,
  };
};

export const getCategoryTree = (categories, originNode = [], newPid) => {
  const parentId = null;
  const list = makeArrayObject(categories, 'parent_id');
  return getSubTree(list, parentId, 'parent_id', originNode, newPid);
};

export const getAttributeTree = (attributes, originNode = [], newGid) => {
  const groupId = null;
  const list = makeArrayObject(attributes, 'group_id');

  return getSubTree(list, groupId, 'group_id', originNode, newGid);
};

export const getEntitySubTreeData = (entities, parentId) => {
  const association = [];
  const treeArray = entities.slice().sort(sortByField('name'));
  const sublist = parentId
    ? treeArray.filter((item) => item?.core?.parentId === parentId)
    : treeArray.filter((item) => !item?.core?.parentId);

  if (sublist.length > 0) {
    sublist.forEach((item) => {
      if (item._id) {
        association.push({
          title: item.name,
          key: item._id,
          appear: item.appear || [],
          children: getEntitySubTreeData(treeArray, item._id),
        });
      }
    });
  }

  return association;
};

export const getEntityTreeData = (entities) => {
  const parentId = null;

  if (entities) {
    return getEntitySubTreeData(entities, parentId);
  }

  return [];
};

export const getAttributeSubTreeData = (attributes, parentId) => {
  const association = [];
  const treeArray = attributes.slice().sort(sortByField('name'));
  const sublist = parentId
    ? treeArray.filter((item) => item?.core?.parentId === parentId)
    : treeArray.filter((item) => !item?.core?.parentId);

  if (sublist.length > 0) {
    sublist.forEach((item) => {
      if (item._id) {
        association.push({
          title: item.name,
          key: item._id,
          appear: item.appear || [],
          children: getAttributeSubTreeData(treeArray, item._id),
        });
      }
    });
  }

  return association;
};

export const getAttributeTreeData = (attributes) => {
  const parentId = null;

  if (attributes) {
    return getAttributeSubTreeData(attributes, parentId);
  }

  return [];
};

export const getChildItems = (item) => {
  const entities = [];
  item?.children.forEach((sub) => {
    entities.push(sub.key);
    if (sub.children.length > 0) {
      entities.push(...getChildItems(sub));
    }
  });

  return entities;
};

export const getTreeItem = (key, tree) => {
  for (const item of tree) {
    if (item.key === key) {
      return item;
    }
    if (item?.children?.length > 0) {
      const result = getTreeItem(key, item.children);
      if (result) {
        return result;
      }
    }
  }
};
