import { DatePicker, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const dateFormat = 'MM/DD/YYYY HH:mm:ss';

const MKDatePicker = ({ value, defaultValue, ...props }) => {
  return (
    <DatePicker
      // value={value || null}
      {...props}
      format={dateFormat}
      defaultValue={defaultValue}
      showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
    />
  );
};

const input = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, label } = settings;

  return (
    <Form.Item
      name={key}
      normalize={(val) => {
        return val?.format(dateFormat);
      }}
      initialValue={
        moment(preDefinedVal, dateFormat).isValid()
          ? moment(preDefinedVal, dateFormat)
          : null
      }
      rules={rules()}
      label={label}
      hidden={hide ?? false}
      hasFeedback
      disabled={disabled}
    >
      <MKDatePicker
        readOnly={readOnly}
        onChange={(e) => {
          onChange(e?.format(dateFormat));
        }}
        defaultValue={preDefinedVal ? moment(preDefinedVal) : null}
      />
    </Form.Item>
  );
};

input.propTypes = {
  props: PropTypes.object,
};

input.defaultProps = {
  props: {},
};

export default input;
