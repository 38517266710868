import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import { PROPERTY_TYPE_UPLOAD } from '@marketreach/pages/taxonomy/properties/PropertyItem';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { ENTITY_DATA } from '@marketreach/services/apollo/entity';
import { getFileUploadImage } from '@marketreach/utils/files';

import './styles.scss';

const { Option } = Select;

const EntitySelect = (props) => {
  const {
    type,
    disabled,
    readOnly,
    onChange,
    defaultValue,
    multiple,
    labelField,
    labelFieldType,
  } = props;

  const { selected: client } = useClientsState();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5000);

  const [searchParams, setSearchParams] = useState({});
  const [sortParams, setSortParams] = useState({});

  const [entityList, setEntityList] = useState([]);
  const [entityInfo, setEntityInfo] = useState({});

  const { loading, data } = useQuery(ENTITY_DATA, {
    variables: {
      clientCode: client?.apiId,
      type,
      page: pageNumber,
      pageSize,
      searchParams,
      sortParams,
    },
  });

  const filterData = (inputValue) => {
    setSearchParams({
      name: inputValue,
      label: inputValue,
      [entityInfo?.slug]: inputValue,
    });
  };

  useEffect(() => {
    if (client.entities.length > 0) {
      const entityData = client?.entities.find((item) => item.slug === type);
      if (entityData) {
        setEntityInfo(entityData);
      }
    }
  }, [client]);

  useEffect(() => {
    const entityDataList = data?.entityData?.data
      .map((item) => {
        const insideProp = {};
        if (item?.properties) {
          for (const section of Object.keys(item.properties)) {
            for (const key of Object.keys(item.properties[section])) {
              insideProp[key] = item?.properties[section][key];
            }
          }
        }
        for (const prop of Object.keys(item)) {
          if (prop !== 'properties') {
            insideProp[prop] = item[prop];
          }
        }

        return { ...insideProp, _id: item._id, parentId: item?.core?.parentId };
      })
      .map((item) => {
        return {
          key: item._id || item[labelField],
          label: item[labelField],
          data: item,
        };
      });

    setEntityList(entityDataList);
  }, [data]);

  const displayLabel = (item) => {
    switch (labelFieldType) {
      case PROPERTY_TYPE_UPLOAD:
        if (item.label) {
          const urls = [];
          if (_.isArray(item.label)) {
            urls.push(...item.label.map((file) => getFileUploadImage(file)));
          } else {
            urls.push(getFileUploadImage(item.label));
          }
          return urls.map((url) => (
            <img src={url} className={'dropdownImage'} />
          ));
        }
        break;
      default:
        return item.label?.toString();
        break;
    }
  };

  return (
    <Select
      disabled={disabled}
      readOnly={readOnly}
      placeholder="Please select"
      defaultValue={
        _.isArray(defaultValue) ? defaultValue : defaultValue?.toString()
      }
      style={{ width: '100%' }}
      onChange={onChange}
      loading={loading}
      showSearch
      onSearch={filterData}
      filterOption={false}
      mode={multiple ? 'multiple' : 'default'}
    >
      {entityList?.map((item) => {
        return <Option key={item.key?.toString()}>{displayLabel(item)}</Option>;
      })}
    </Select>
  );
};

EntitySelect.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  labelField: PropTypes.string,
  labelFieldType: PropTypes.string,
};

EntitySelect.defaultProps = {
  type: null,
  disabled: false,
  readOnly: false,
  defaultValue: null,
  onChange: () => {},
  multiple: false,
  labelField: null,
  labelFieldType: null,
};

export default memo(EntitySelect);
