import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Dropdown, Input, Menu } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import CustomProTable from '@marketreach/components/protable';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { HISTORIES_BY_PAGE } from '@marketreach/services/apollo/history';

import './styles.scss';
const { Search } = Input;

const TaxonomyHistory = (props) => {
  const { selected: client } = useClientsState();
  const { context, setContext } = useContext(TaxonomyContext);

  const getHistoryFilterParams = () => {
    const parentId =
      context.selectedType === 'category'
        ? context.selectedCategoryId
        : context.selectedAttributeId;

    if (
      (context.selectedType === 'category' && !context.selectedCategoryId) ||
      (context.selectedType === 'attribute' && !context.selectedAttributeId)
    ) {
      return { type: context.selectedType };
    }

    return {
      type: context.selectedType,
      parentId,
    };
  };

  const [historyCurrentPage, setHistoryCurrentPage] = useState(1);
  const [historyRowsPerPage, setHistoryRowsPerPage] = useState(50);
  const { data: historyRes, refetch: refetchHistories } = useQuery(
    HISTORIES_BY_PAGE,
    {
      variables: {
        clientCode: client?.apiId,
        pageNumber: historyCurrentPage,
        pageSize: historyRowsPerPage,
        filterParams: getHistoryFilterParams(),
      },
    }
  );
  const handlePageChange = (page, size) => {
    setHistoryCurrentPage(page);
    setHistoryRowsPerPage(size);
    refetchHistories();
  };

  const histories = historyRes ? historyRes?.getHistoriesByPage?.data : [];
  const totalCount = historyRes ? historyRes.getHistoriesByPage.totalCount : 0;
  const pageSize = historyRes
    ? historyRes.getHistoriesByPage.pageSize
    : historyRowsPerPage;
  const pageNumber = historyRes
    ? historyRes.getHistoriesByPage.pageNumber
    : historyCurrentPage;

  useEffect(() => {
    if (client) {
      refetchHistories();
    }
  }, [
    client,
    context.selectedType,
    context.selectedCategoryId,
    context.selectedAttributeId,
    refetchHistories,
  ]);

  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      render: (createdAt) => (
        <span>{moment(createdAt).format('MM/DD/YYYY, hh:mm:ss A')}</span>
      ),
    },
    {
      title: 'Account',
      dataIndex: 'account',
    },
    {
      title: 'IP Address',
      dataIndex: 'ipAddress',
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd item
      </Menu.Item>
    </Menu>
  );

  const toolbar = () => [
    <Search
      key="search"
      placeholder="input search text"
      style={{ width: 200 }}
    />,
    <Dropdown overlay={menu} key="export">
      <Button type="link">
        Export <DownOutlined />
      </Button>
    </Dropdown>,
  ];

  return (
    <div className="ant-pro-grid-content history-content">
      <CustomProTable
        className="history-content-table"
        columns={columns}
        data={histories}
        toolbar={toolbar}
        pagination={{
          current: pageNumber,
          total: totalCount,
          pageSize,
          onChange: (page, size) => {
            handlePageChange(page, size);
          },
        }}
      />
    </div>
  );
};

TaxonomyHistory.propTypes = {};

TaxonomyHistory.defaultProps = {};

export default memo(TaxonomyHistory);
