import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const FilterDropDown = (props) => {
  const {
    searchInput,
    confirm,
    dataIndex,
    selectedKeys,
    setSelectedKeys,
    onColumnSearch,
    onColumnSearchReset,
    clearFilters,
  } = props;

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => onColumnSearch(selectedKeys, confirm, dataIndex)}
        style={{
          width: 188,
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => onColumnSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => onColumnSearchReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  );
};

FilterDropDown.propTypes = {
  searchInput: PropTypes.objectOf(PropTypes.any),
  confirm: PropTypes.objectOf(PropTypes.any),
  dataIndex: PropTypes.number,
  selectedKeys: PropTypes.arrayOf(PropTypes.any),
  setSelectedKeys: PropTypes.func,
  onColumnSearch: PropTypes.func,
  onColumnSearchReset: PropTypes.func,
  clearFilters: PropTypes.func,
};

FilterDropDown.defaultProps = {
  searchInput: null,
  confirm: null,
  dataIndex: null,
  selectedKeys: [],
  setSelectedKeys: () => null,
  onColumnSearch: () => null,
  onColumnSearchReset: () => null,
  clearFilters: () => null,
};

export default FilterDropDown;
