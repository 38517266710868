import { useMutation, useQuery } from '@apollo/client';
import { Empty, Layout, Menu, Spin, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ManagePropertiesControl from '@marketreach/pages/entities/components/ManagePropertiesControl';
import TreeSider from '@marketreach/pages/entities/components/TreeSider';
import EntityModal from '@marketreach/pages/entities/EntityModal';
import EntityGridView from '@marketreach/pages/share/entity/EntityGridView';
import { useClientsState } from '@marketreach/providers/ClientsProvider';
import {
  BULK_ENTITY_CREATE,
  ENTITIES_LIST,
  ENTITY_DATA_QUERY,
} from '@marketreach/services/apollo/entity';
import {
  GET_SETTING,
  GET_SETTING_QUERY,
  SET_SETTING,
} from '@marketreach/services/apollo/user';
import { getRootId } from '@marketreach/utils/common';

const TreeView = (props) => {
  const {
    addItem,
    handleSaveClick,
    removeEntityData,
    entityDataList,
    selectedEntity,
    entityInfo,
    getApiIdField,
    saveEntityLoading,
    setSelectedEntityId,
    selectedEntityId,
    entityToEdit,
    showInvite,
    handleShowDrawer,
    extraLoading,
  } = props;

  const { selected: client } = useClientsState();
  const { type } = useParams();

  // Build key for get/set expanded keys of tree
  const expandedKeysKey = `${type}_tree_view_expanded_keys`;

  // State

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [openSubModal, setOpenSubModal] = useState(false);
  const [subMode, setSubMode] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [contextMenuEntityId, setContextMenuEntityId] = useState(null);

  // Queries

  // Load expanded keys data
  const { data: expandedKeysData, loading: expandedKeysLoading } = useQuery(
    GET_SETTING,
    {
      variables: {
        key: expandedKeysKey,
        clientCode: client?.apiId,
      },
    }
  );

  const [bulkEntityCreate, { loading: bulkEntityLoading }] =
    useMutation(BULK_ENTITY_CREATE);

  // Effects

  /**
   * If expanded keys exists - set it to the state
   */
  useEffect(() => {
    if (
      expandedKeysData?.getSetting?.data?.value &&
      expandedKeys !== expandedKeysData?.getSetting?.data?.value
    ) {
      setExpandedKeys(expandedKeysData?.getSetting?.data?.value);
    }
  }, [expandedKeysData]);

  const [setSetting, { loading: setExpandedKeysLoading }] =
    useMutation(SET_SETTING);

  // Handlers

  const onExpand = async (data) => {
    /**
     * Store expanded keys into user settings
     */
    await setSetting({
      variables: {
        key: expandedKeysKey,
        value: data,
        clientCode: client.apiId,
      },
      refetchQueries: [GET_SETTING_QUERY],
    });
  };

  /**
   * Process modal response in tree mode
   * @param data
   */
  const handleModalOk = (data) => {
    switch (subMode) {
      case 'delete':
        removeEntityData({
          variables: {
            id: contextMenuEntityId,
            clientCode: client?.apiId,
            type,
          },
          refetchQueries: [ENTITY_DATA_QUERY, ENTITIES_LIST],
          awaitRefetchQueries: true,
        }).then(() => {
          if (contextMenuEntityId === selectedEntityId) {
            setSelectedEntityId(null);
          }
          setContextMenuEntityId(null);
        });
        break;
      case 'root_create':
        bulkEntityCreate({
          variables: {
            clientCode: client?.apiId,
            type,
            keys: data,
          },
          refetchQueries: [ENTITY_DATA_QUERY, ENTITIES_LIST],
          awaitRefetchQueries: true,
        });
        break;
      case 'bulk_create':
        if (contextMenuEntityId) {
          const parentId = contextMenuEntityId
            ? contextMenuEntityId
            : entityToEdit?._id;

          const rootId = getRootId(parentId, entityDataList);

          bulkEntityCreate({
            variables: {
              parentId,
              rootId,
              clientCode: client?.apiId,
              type,
              // keys: { [getApiIdField()]: data },
              keys: data,
            },
            refetchQueries: [ENTITY_DATA_QUERY, ENTITIES_LIST],
            awaitRefetchQueries: true,
          }).then(() => setContextMenuEntityId(null));
        } else {
          bulkEntityCreate({
            variables: {
              clientCode: client?.apiId,
              type,
              keys: data,
            },
            refetchQueries: [ENTITY_DATA_QUERY, ENTITIES_LIST],
            awaitRefetchQueries: true,
          });
        }
        break;
      default:
        break;
    }
    setOpenSubModal(false);
  };

  const handleModalCancel = () => {
    setOpenSubModal(false);
  };

  const onSelect = (key) => {
    setSelectedEntityId(key);
  };

  const onClickSubmenu = (subType, id) => {
    setOpenSubModal(true);
    setSubMode(subType);
    setContextMenuEntityId(id);
  };

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  const menu = (id) => (
    <Menu>
      <Menu.Item
        key="bulk_create"
        onClick={() => onClickSubmenu('bulk_create', id)}
      >
        Create New {type}
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => onClickSubmenu('delete', id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="ant-pro-grid-content">
      <Spin spinning={saveEntityLoading || bulkEntityLoading}>
        <Layout>
          <TreeSider
            collapsed={collapsed}
            handleToggleClick={handleToggleClick}
            onClickSubmenu={onClickSubmenu}
            type={type}
            loading={expandedKeysLoading || setExpandedKeysLoading}
            selectedEntityId={selectedEntityId}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            onSelect={onSelect}
            entityDataList={entityDataList}
            getApiIdField={getApiIdField}
            menu={menu}
            allowAdd={!showInvite}
          />
          <Layout>
            <Row justify="end">
              <Col span={4}>
                <ManagePropertiesControl
                  showInvite={showInvite}
                  handleShowDrawer={handleShowDrawer}
                />
              </Col>
            </Row>
            {(!selectedEntity || Object.keys(selectedEntity).length === 0) && (
              <Empty>Select entity at sidebar</Empty>
            )}
            {selectedEntity && Object.keys(selectedEntity).length > 0 && (
              <>
                <div className="ant-pro-grid-content entity-content">
                  <EntityGridView
                    key={selectedEntity._id}
                    visible
                    title={`Edit ${entityInfo?.label}`}
                    entity={selectedEntity}
                    type={type}
                    handleShowGridView={addItem}
                    handleSaveClick={handleSaveClick}
                    drawer={false}
                  />
                </div>
              </>
            )}
            <EntityModal
              title={`Create new ${entityInfo?.label}`}
              mode={subMode}
              open={openSubModal}
              entityId={contextMenuEntityId}
              handleOk={handleModalOk}
              handleCancel={handleModalCancel}
            />
          </Layout>
        </Layout>
      </Spin>
    </div>
  );
};

TreeView.propTypes = {
  addItem: PropTypes.func,
  handleSaveClick: PropTypes.func,
  removeEntityData: PropTypes.func,
  getApiIdField: PropTypes.func,
  setSelectedEntityId: PropTypes.func,
  entityDataList: PropTypes.arrayOf(PropTypes.any),
  selectedEntity: PropTypes.objectOf(PropTypes.any),
  entityInfo: PropTypes.objectOf(PropTypes.any),
  entityToEdit: PropTypes.objectOf(PropTypes.any),
  saveEntityLoading: PropTypes.bool,
  selectedEntityId: PropTypes.number,
  handleShowDrawer: PropTypes.func,
  showInvite: PropTypes.bool,
  extraLoading: PropTypes.bool,
};

TreeView.defaultProps = {
  addItem: () => null,
  handleSaveClick: () => null,
  removeEntityData: () => null,
  getApiIdField: () => null,
  setSelectedEntityId: () => null,
  entityDataList: [],
  selectedEntity: {},
  entityInfo: {},
  entityToEdit: {},
  saveEntityLoading: false,
  selectedEntityId: null,
  handleShowDrawer: () => null,
  showInvite: false,
  extraLoading: false,
};

export default TreeView;
