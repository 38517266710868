import {
  PlusOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
} from '@ant-design/icons';
import { Button, Radio } from 'antd';
import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';

import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import {
  setTaxonomyTreeExpandedState,
  getTaxonomyTreeExpandedState,
} from '@marketreach/services/localstorage';
import {
  getEntityTreeData,
  getChildItems,
  getTreeItem,
} from '@marketreach/utils/categories';
import { getRootId } from '@marketreach/utils/common';

import SidebarAttributeModal from './AttributeModal';
import SidebarAttributesTree from './AttributesTree';
import SidebarCategoriesTree from './CategoriesTree';
import SidebarCategoryModal from './CategoryModal';

import './styles.scss';

const TaxonomySidebar = (props) => {
  const { context, setContext } = useContext(TaxonomyContext);

  const {
    selectedAttributeId,
    selectedCategoryId,
    attributes,
    categories,
    selectedType,
  } = context;

  const [expandedKeys, setExpandedKeys] = useState([
    getTaxonomyTreeExpandedState().expandedKeys,
  ]);
  const [bulkExpand, setBulkExpand] = useState(null);
  const addKeyToExpand = useCallback(
    (keyID) => {
      if (!keyID) return;
      const newExpandedKeys = [...(expandedKeys || []), keyID];
      setExpandedKeys(newExpandedKeys);
      setTaxonomyTreeExpandedState(newExpandedKeys);
    },
    [expandedKeys]
  );
  const onChangeState = (e) => {
    setContext({
      ...context,
      selectedType: e.target.value,
    });
  };
  const handleExpandedKeys = (keys) => {
    setExpandedKeys(keys);
    setBulkExpand(null);
    setTaxonomyTreeExpandedState(keys);
  };

  useEffect(() => {
    const expandedState = getTaxonomyTreeExpandedState();
    setExpandedKeys(expandedState?.expandedKeys);
  }, []);

  const [selectedKeys, setSelectedKeys] = useState([]);
  useEffect(() => {
    if (selectedAttributeId || selectedCategoryId) {
      setSelectedKeys(
        selectedType === 'category'
          ? [selectedCategoryId]
          : [selectedAttributeId]
      );
    }
  }, [selectedType, selectedAttributeId, selectedCategoryId]);

  const handleChangeBulkExpand = (e) => {
    setBulkExpand(e.target.value);
    if (e.target.value === 'expandAll') {
      const keys =
        selectedType === 'category'
          ? categories.map((c) => c._id)
          : attributes.map((c) => c._id);
      handleExpandedKeys(keys);
    } else {
      handleExpandedKeys([]);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [openSubModal, setOpenSubModal] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [attributeId, setAttributeId] = useState(null);
  const [entityRootId, setEntityRootId] = useState(null);
  const [subMode, setSubMode] = useState(null);
  const handleOk = (keyID = null) => {
    if (keyID) addKeyToExpand(keyID);
    setOpenModal(false);
    setOpenSubModal(false);
  };
  const handleCancel = () => {
    setOpenModal(false);
    setOpenSubModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleClickSubmenu = (mode, id) => {
    const rootId = getRootId(
      id,
      selectedType === 'category' ? categories : attributes
    );

    setEntityRootId(rootId);

    if (selectedType === 'category') {
      setCategoryId(id);
    } else {
      setAttributeId(id);
    }
    setOpenSubModal(true);
    setSubMode(mode);
  };

  const treeData = getEntityTreeData(
    selectedType === 'category' ? categories : attributes
  );

  const getSubModalTitle = () => {
    switch (subMode) {
      case 'create':
        return selectedType === 'category'
          ? 'Create category'
          : 'Create attribute';
      case 'edit':
        return selectedType === 'category' ? 'Edit category' : 'Edit attribute';
      case 'delete':
        return selectedType === 'category'
          ? `Delete category '${
              categories.find((item) => item._id === categoryId)?.name
            }'`
          : `Delete attribute '${
              attributes.find((item) => item._id === attributeId)?.name
            }'`;
      case 'bulk_create':
        return selectedType === 'category'
          ? 'Create New Categories'
          : 'Create New Attributes';
      default:
        return null;
    }
  };

  return (
    <div className="ant-pro-grid-content taxonomy-sidebar">
      <Radio.Group
        className="radio-group-container"
        value={selectedType}
        onChange={onChangeState}
      >
        <Radio.Button className="radio-group-button" value="category">
          Categories
        </Radio.Button>
        <Radio.Button className="radio-group-button" value="attribute">
          Attributes
        </Radio.Button>
      </Radio.Group>
      <Button
        className="categories-tree-add-btn"
        type="dashed"
        onClick={handleOpenModal}
      >
        <PlusOutlined />{' '}
        {selectedType === 'category'
          ? 'Add Root Category'
          : 'Add Attribute Group'}
      </Button>
      <Radio.Group
        className="radio-group-container radio-group-container"
        value={bulkExpand}
        onChange={handleChangeBulkExpand}
      >
        <Radio.Button
          className="radio-group-button radio-group-button-no-border"
          value="expandAll"
        >
          <PlusSquareOutlined /> Expand All
        </Radio.Button>
        <Radio.Button
          className="radio-group-button radio-group-button-no-border"
          value="collapseAll"
        >
          <MinusSquareOutlined /> Collapse All
        </Radio.Button>
      </Radio.Group>
      {selectedType === 'category' && (
        <SidebarCategoriesTree
          selectedKeys={selectedKeys}
          expandedKeys={expandedKeys}
          handleExpandedKeys={handleExpandedKeys}
          handleClickSubmenu={handleClickSubmenu}
        />
      )}
      {selectedType === 'attribute' && (
        <SidebarAttributesTree
          selectedKeys={selectedKeys}
          expandedKeys={expandedKeys}
          handleExpandedKeys={handleExpandedKeys}
          handleClickSubmenu={handleClickSubmenu}
        />
      )}
      {selectedType === 'category' ? (
        <>
          <SidebarCategoryModal
            title="Add Root Category"
            mode="root"
            open={openModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
          <SidebarCategoryModal
            title={getSubModalTitle()}
            mode={subMode}
            open={openSubModal}
            childs={getChildItems(getTreeItem(categoryId, treeData))}
            categoryId={categoryId}
            rootId={entityRootId}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
        </>
      ) : (
        <>
          <SidebarAttributeModal
            title="Add Attribute Group"
            mode="root"
            open={openModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
          <SidebarAttributeModal
            title={getSubModalTitle()}
            mode={subMode}
            open={openSubModal}
            childs={getChildItems(getTreeItem(attributeId, treeData))}
            attributeId={attributeId}
            rootId={entityRootId}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
        </>
      )}
    </div>
  );
};

TaxonomySidebar.propTypes = {};

TaxonomySidebar.defaultProps = {};

export default memo(TaxonomySidebar);
