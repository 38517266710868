import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Switch } from 'antd';
import PropTypes from 'prop-types';

const input = (props) => {
  const {
    preDefinedVal,
    hide,
    readOnly,
    rules,
    settings,
    defaultValue,
    onChange,
    disabled,
  } = props;
  const { key, label } = settings;
  return (
    <Form.Item
      name={key}
      rules={rules()}
      label={label}
      initialValue={preDefinedVal?.toString()?.toLowerCase()?.trim() === 'true'}
      hidden={hide ?? false}
      hasFeedback
    >
      <Switch
        disabled={disabled}
        readOnly={readOnly}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={readOnly ? defaultValue : undefined}
        defaultChecked={
          preDefinedVal?.toString()?.toLowerCase()?.trim() === 'true'
        }
        onChange={onChange}
      />
    </Form.Item>
  );
};

input.propTypes = {
  props: PropTypes.object,
};

input.defaultProps = {
  props: {},
};

export default input;
