// Get validations array with property type
import {
  PROPERTY_TYPE_CODE_EDIT,
  PROPERTY_TYPE_DECIMAL,
  PROPERTY_TYPE_INTEGER,
  PROPERTY_TYPE_LIST,
  PROPERTY_TYPE_RELATION,
  PROPERTY_TYPE_RICH_TEXT,
  PROPERTY_TYPE_TEXT,
  PROPERTY_TYPE_UPLOAD,
} from '@marketreach/pages/taxonomy/properties/PropertyItem';

const getPropertyValidations = (type) => {
  switch (type) {
    case PROPERTY_TYPE_RICH_TEXT:
    case PROPERTY_TYPE_CODE_EDIT:
      return ['required'];
    case PROPERTY_TYPE_UPLOAD:
      return ['required', 'file_count_limit', 'file_size_limit'];
    case 'Color':
    case 'Date and Time':
    case PROPERTY_TYPE_LIST:
    case 'Toggle':
      return ['required'];
    case PROPERTY_TYPE_INTEGER:
    case PROPERTY_TYPE_DECIMAL:
      return ['required', 'unique', 'range'];
    case PROPERTY_TYPE_TEXT:
      return [
        'required',
        'unique',
        'limit_characters',
        'match_pattern',
        'restrict_pattern',
      ];
    case PROPERTY_TYPE_RELATION:
      return ['required'];
    default:
      return [];
  }
};

export { getPropertyValidations };
