import { Button, Checkbox, Input, Spin, Typography } from 'antd';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';

import ListOptions from '@marketreach/pages/taxonomy/properties/manage/options/ListOptions';
import { useClientsState } from '@marketreach/providers/ClientsProvider';

import one_to_many from '../../../../assets/relation/one_to_many.svg';
import one_to_one from '../../../../assets/relation/one_to_one.svg';
import PropertyItem, {
  PROPERTY_TYPE_LIST,
  PROPERTY_TYPE_RADIOBUTTON,
  PROPERTY_TYPE_RELATION,
} from '../PropertyItem';

import './styles.scss';

const { TextArea } = Input;
const { Text } = Typography;

const PropertySettings = (props) => {
  const {
    type,
    state: propertyState,
    error,
    handleChangeState,
    data,
    entityType,
    apiIdField,
  } = props;

  const property = _.cloneDeep(propertyState);

  const { selected: client } = useClientsState();

  const input = useRef(null);

  const [allowDefaultValue, setAllowDefaultValue] = useState(
    property?.settings?.allowDefaultValue !== undefined
      ? property?.settings?.allowDefaultValue
      : typeof property?.settings?.defaultValue !== 'undefined'
  );

  const [entityFields, setEntityFields] = useState([]);

  const getEntityFields = (entity) => {
    if (client.sections[entity]) {
      const fields = client.sections[entity].map((item) =>
        item.properties.map((ent) => ({
          value: ent.settings.key,
          label: `${ent.settings.label} (${ent.settings.key})`,
          type: ent.propertyType.title,
        }))
      );

      return _.sortBy(fields.flat(), 'label');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      input?.current?.focus();
    });
  }, [input]);

  useEffect(() => {
    setEntityFields(
      getEntityFields(property?.settings?.entity)?.filter(
        (item) => item.type !== PROPERTY_TYPE_RELATION
      )
    );
    handleChangeState('settings', 'entityLabelField', null);
  }, [property?.settings?.entity]);

  useEffect(() => {
    handleChangeState('settings', 'defaultValue', null, null);
  }, [property?.settings?.entityLabelField]);

  const relationType = [
    {
      value: 'one_to_one',
      label: 'One to one',
      icon: one_to_one,
    },
    {
      value: 'one_to_many',
      label: 'One to many',
      icon: one_to_many,
    },
    // {
    //   value: 'many_to_one',
    //   label: 'Many to one',
    //   icon: one_to_many,
    // },
    // {
    //   value: 'many_to_many',
    //   label: 'Many to many',
    //   icon: many_to_many,
    // },
  ];

  if (type?.title === PROPERTY_TYPE_RELATION) {
    data.settings = data?.settings || {};
    data.settings.relationType = data.settings.relationType || 'one_to_one';
    data.settings.list_options = relationType;

    property.settings = property?.settings || {};

    property.settings.relationType =
      property.settings?.relationType || 'one_to_one';
  }

  const allowDefaultValueButton = (
    <Button
      type="primary"
      onClick={() => {
        setAllowDefaultValue(!allowDefaultValue);
        handleChangeState('settings', 'allowDefaultValue', !allowDefaultValue);
      }}
    >
      {!allowDefaultValue && <>Allow default value</>}
      {allowDefaultValue && <>Disable default value</>}
    </Button>
  );

  /**
   * Prepare event value to store in database
   *
   * @param e
   * @return {string|*}
   */
  const prepareValue = (e) => {
    if (e?.target) {
      return e?.target.value;
    }
    if (_.isObject(e) || _.isArray(e)) {
      return e.toString();
    }
    return e;
  };

  return (
    <Spin spinning={!property}>
      <Typography className="taxonomy-add-property-modal-label">
        Label
      </Typography>
      <Typography>
        Name of property displayed within the content editor
      </Typography>
      {error?.label && <Text type="danger">This field is required.</Text>}
      <Input
        placeholder="Label"
        value={property?.settings?.label}
        onChange={(e) => handleChangeState('settings', 'label', e.target.value)}
        autoFocus
        ref={input}
      />
      <Typography className="taxonomy-add-property-modal-label">
        API ID
      </Typography>
      <Typography>
        Friendly ID(slug) used to access the property via API
      </Typography>
      {error?.key && (
        <Text type="danger">
          {error?.key?.description ||
            'This field is required and should be unique.'}
        </Text>
      )}
      <Input
        placeholder="Key"
        value={property?.settings?.key}
        onChange={(e) => handleChangeState('settings', 'key', e.target.value)}
      />
      {type?.title !== PROPERTY_TYPE_RELATION && (
        <>
          {allowDefaultValueButton}
          <Typography className="taxonomy-add-property-modal-label">
            Default value (optional)
          </Typography>
          <PropertyItem
            showLabel={false}
            showDescription={false}
            key={property?.settings?.key}
            type={type?.title}
            defaultValue={property?.settings?.defaultValue}
            disabled={!allowDefaultValue}
            data={{
              ...data,
              settings: {
                ...property?.settings,
                label: false,
              },
            }}
            onChange={(e) => {
              handleChangeState('settings', 'defaultValue', prepareValue(e));
            }}
          />
        </>
      )}
      <Typography className="taxonomy-add-property-modal-label">
        Description (optional)
      </Typography>
      <Typography>Displays a text hint for this field</Typography>
      <TextArea
        rows={4}
        value={property?.settings?.description}
        onChange={(e) =>
          handleChangeState('settings', 'description', e.target.value)
        }
      />
      {type?.title === PROPERTY_TYPE_RELATION && (
        <>
          <Typography className="taxonomy-add-property-modal-label">
            Related entity
          </Typography>
          <PropertyItem
            key={property.settings.entity || 'entity'}
            type={PROPERTY_TYPE_LIST}
            defaultValue={property?.settings?.entity}
            data={{
              ...data,
              settings: {
                ...data?.settings,
                list_options: data.entitiesList?.filter(
                  (item) => item.value !== entityType
                ),
              },
            }}
            onChange={(e) => {
              handleChangeState(
                'settings',
                'entity',
                e?.target ? e?.target.value : e?.toString()
              );
            }}
          />
          {property?.settings?.entity && (
            <>
              <Typography className="taxonomy-add-property-modal-label">
                Label field
              </Typography>
              <PropertyItem
                key={`entityLabelField${property?.settings?.entity}${property?.settings?.entityLabelField}`}
                type={PROPERTY_TYPE_LIST}
                defaultValue={property?.settings?.entityLabelField}
                data={{
                  ...data,
                  settings: {
                    ...data?.settings,
                    list_options: entityFields,
                  },
                }}
                onChange={(e) => {
                  // prepare value
                  const entityLabelField = e?.target
                    ? e?.target.value
                    : e?.toString();

                  // get field type
                  const fieldType = entityFields.find(
                    (item) => item.value === entityLabelField
                  )?.type;

                  // set entity label field
                  handleChangeState(
                    'settings',
                    'entityLabelField',
                    entityLabelField,
                    fieldType
                  );
                }}
              />
              <Typography className="taxonomy-add-property-modal-label">
                Relation type
              </Typography>
              <PropertyItem
                key={'relationType'}
                type={PROPERTY_TYPE_RADIOBUTTON}
                defaultValue={property?.settings?.relationType || 'one_to_one'}
                data={{
                  ...data,
                  settings: {
                    ...data?.settings,
                    list_options: relationType,
                  },
                }}
                onChange={(e) => {
                  handleChangeState(
                    'settings',
                    'relationType',
                    e?.target ? e?.target.value : e?.toString()
                  );
                }}
              />

              <Checkbox
                className="taxonomy-add-property-modal-item"
                checked={property?.settings?.cascadeRelation}
                onChange={(e) =>
                  handleChangeState(
                    'settings',
                    'cascadeRelation',
                    e?.target ? e?.target.checked : e?.toString()
                  )
                }
              >
                <Typography className="taxonomy-add-property-modal-item-title">
                  Cascade relation
                </Typography>
                <Typography>
                  On removing this record - all related records will be also
                  removed
                </Typography>
              </Checkbox>

              {property?.settings?.entity &&
                property?.settings?.entityLabelField &&
                property?.settings?.relationType && (
                  <>
                    <Typography className="taxonomy-add-property-modal-label">
                      Default value (optional)
                    </Typography>
                    {allowDefaultValueButton}

                    <PropertyItem
                      key={`${property?.settings?.entity}.${property?.settings?.entityLabelField}`}
                      showDescription={false}
                      showLabel={false}
                      type={type?.title}
                      defaultValue={
                        _.isArray(property?.settings?.defaultValue)
                          ? property?.settings?.defaultValue
                          : property?.settings?.defaultValue?.split(',')
                      }
                      disabled={!allowDefaultValue}
                      data={{
                        ...property,
                        validation: () => {},
                      }}
                      onChange={(e) => {
                        handleChangeState(
                          'settings',
                          'defaultValue',
                          e?.target ? e?.target.value.split(',') : e
                        );
                      }}
                    />
                  </>
                )}
            </>
          )}
        </>
      )}
      {type?.title === PROPERTY_TYPE_LIST && (
        <ListOptions
          handleChangeState={handleChangeState}
          settings={property?.settings}
        />
      )}
      <Typography className="taxonomy-add-property-modal-label">
        Field options
      </Typography>
      <Checkbox
        className="taxonomy-add-property-modal-item"
        checked={property?.settings?.fieldOptions?.localizeField}
        onChange={(e) =>
          handleChangeState('settings', 'fieldOptions', {
            allowMultipleValues:
              property?.settings?.fieldOptions?.allowMultipleValues,
            localizeField: e.target.checked,
          })
        }
      >
        <Typography className="taxonomy-add-property-modal-item-title">
          Localise field
        </Typography>
        <Typography>Enables translations for this field</Typography>
      </Checkbox>
      <Checkbox
        className="taxonomy-add-property-modal-item"
        checked={property?.settings?.fieldOptions?.isApiId}
        disabled={apiIdField !== null && apiIdField !== property?.settings?.key}
        onChange={(e) =>
          handleChangeState('settings', 'fieldOptions', {
            allowMultipleValues: false,
            isApiId: e.target.checked,
          })
        }
      >
        <Typography className="taxonomy-add-property-modal-item-title">
          Is API ID
        </Typography>
        <Typography>This field will be used as API ID</Typography>
      </Checkbox>
    </Spin>
  );
};

PropertySettings.propTypes = {
  state: PropTypes.object,
  type: PropTypes.object,
  error: PropTypes.object,
  data: PropTypes.object,
  handleChangeState: PropTypes.func,
  entityType: PropTypes.string,
  apiIdField: PropTypes.string,
};

PropertySettings.defaultProps = {
  state: null,
  type: null,
  error: null,
  data: null,
  handleChangeState: () => {},
  entityType: null,
  apiIdField: null,
};

export default memo(PropertySettings);
