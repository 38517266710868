import PropTypes from 'prop-types';
import React from 'react';

import { SortableItem } from '@marketreach/components/protable/helpers';

const DraggableBodyRow = ({ className, ...restProps }) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <SortableItem index={restProps['data-row-key'] ?? 0} {...restProps} />
);
DraggableBodyRow.propTypes = {
  className: PropTypes.string,
};
DraggableBodyRow.defaultProps = {
  className: null,
};

export { DraggableBodyRow };
